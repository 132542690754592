import { Box, Button } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { authSliceLogoutThunk } from "../../redux/slices/authSlice";
import {
  filterCompaniesSelectorGetExportData,
  filterCompaniesSelectorGetExportDataLoading,
  filterCompaniesSliceClearExportData,
  filterCompaniesSliceExportDataThunk,
} from "../../redux/slices/filterCompaniesSlice";
import { DataGridPaginationStyled } from "../ui/DataGridPaginationStyled";

export const CompanyFilterTableNavigation = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const exportDataLoading = useSelector(
    filterCompaniesSelectorGetExportDataLoading
  );
  const exportData = useSelector(filterCompaniesSelectorGetExportData);

  const handleExportDataFilter = () => {
    dispatch(filterCompaniesSliceExportDataThunk())
      .unwrap()
      .catch((err) => {
        if (err?.code === 401) {
          setTimeout(() => {
            dispatch(authSliceLogoutThunk()).finally(() => {
              history.replace("/");
            });
          }, 3000);
        }
      });
  };

  useEffect(() => {
    if (exportData) {
      const link = document.createElement("a");
      link.href = exportData;
      link.setAttribute(
        "download",
        `fapro_filter-${new Date().toLocaleDateString("es-ES", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })}.xlsx`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(filterCompaniesSliceClearExportData());
    }

    return () => dispatch(filterCompaniesSliceClearExportData());
  }, [exportData, dispatch]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "nowrap",
        width: "100%",
        px: 2,
        py: 1,
        bgcolor: "background.terciary",
      }}
    >
      <Button
        type="button"
        onClick={handleExportDataFilter}
        variant="contained"
        color="primary"
        size="small"
        disabled={exportDataLoading}
      >
        Exportar
      </Button>
      <DataGridPaginationStyled {...props} />
    </Box>
  );
};
