import { Box, Button, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { authSliceLogoutThunk } from "../../redux/slices/authSlice";
import { ModalStyled } from "../ui/ModalStyled";
import {
  downloadSelectorGetData,
  downloadSelectorGetRowSelected,
  downloadSliceEnableThunk,
  downloadSliceGetDataThunk,
  downloadSliceResetRowSelected,
} from "../../redux/slices/downloadSlice";

export const DownloadModalEnableRecords = ({ show, onShow }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const rowSelected = useSelector(downloadSelectorGetRowSelected);
  const { companies } = useSelector(downloadSelectorGetData);

  const handleSubmit = () => {
    setLoading(true);
    const currentCompanies = companies.filter((val) =>
      rowSelected.includes(val.id)
    );
    dispatch(downloadSliceEnableThunk(currentCompanies))
      .unwrap()
      .then(() => {
        dispatch(downloadSliceResetRowSelected());
        dispatch(downloadSliceGetDataThunk());
        onShow();
      })
      .catch((err) => {
        if (err?.code === 401) {
          setTimeout(() => {
            dispatch(authSliceLogoutThunk()).finally(() => {
              history.replace("/");
            });
          }, 3000);
        } else {
          onShow();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ModalStyled
      show={show}
      onShow={() => onShow()}
      title={"Habilitar Empresas"}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 650,
          bgcolor: "background.terciary",
          boxShadow: 24,
          p: 4,
          pt: 3,
          borderRadius: 2,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
        className="sidenav-scroll"
      >
        <Typography gutterBottom textAlign={"justify"} color="text.primary" >
          La habilitación de la descarga se realizará de forma inmediata.
          <br />
        </Typography>
        <Typography gutterBottom textAlign={"justify"} variant="bold">
          ¿Está seguro de que desea habilitar la opción de descarga de datos de
          empresas?
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 2,
            width: "100%",
            mt: 3,
          }}
        >
          <Button
            color="error"
            type="button"
            disabled={loading}
            onClick={() => onShow()}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            onClick={handleSubmit}
          >
            Habilitar empresas
          </Button>
        </Box>
      </Box>
    </ModalStyled>
  );
};
