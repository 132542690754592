import { useState } from "react";
import rutUtils from "@becual/rututils";

const InitialState = () => {
  // const [login, setLogin] = useState({email:'', password:''});
  const [register, setRegister] = useState({
    email: "",
    password: "",
    name: "",
    lastName: "",
    phone: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [link, setLink] = useState({ rut: "", password: "", name: "" });
  const [linkCertificate, setLinkCertificate] = useState({
    rut: "",
    password: "",
    name: "",
    certificate: "",
  });
  const [aForm, setAForm] = useState({
    email: "",
    phone: "",
    web: "",
    image: "",
    mainColor: "#fff",
    secondColor: "#fff",
  });
  const [collapse, setCollapse] = useState(false);
  const [reset, setReset] = useState({ email: "", message: "", type: "" });
  const [newPass, setNewPass] = useState({ password: "", confirm: "" });
  const [accounts, setAccounts] = useState({
    email: "",
    name: "",
    lastName: "",
    password: "",
  });

  //FORM COMPONENT
  const plainOptions = [
    "Rut y clave",
    "Certificado Digital",
    "Carga Masiva",
    "API",
  ];
  const defaultCheckedList = ["Rut y clave", "Certificado Digital"];
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  //ACCOUNT COMPONENT
  const AccountOptions = [
    "Reporte Fapro",
    "Vincular Empresas (RUT Y Clave)",
    "Vincular empresas (Certificado Digital)",
    "Carpeta Tributaria",
    "Filtro de Empresas",
    "Vaciador de IVA",
    "Crear Cuentas",
    "Gestion de Mails",
    "Gestion de Descargas",
  ];
  const defaultAccountCheckedList = ["Reporte Fapro", "Filtro de Empresas"];
  const [accountList, setAccountList] = useState(defaultAccountCheckedList);
  const [indeterminateAccount, setIndeterminateAccount] = useState(true);
  const [checkAllAccount, setCheckAllAccount] = useState(false);

  /*
    const plainOptions = ['Rut y clave', 'Certificado Digital', 'Carga Masiva', 'API'];
    const defaultCheckedList = ['Rut y clave', 'Certificado Digital'];
    const [checkedList, setCheckedList] = useState(defaultCheckedList);
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);
    */

  const resetMessages = () => {
    setError(false);
    setSuccess(false);
  };

  const onChangeAccountList = (list) => {
    setAccountList(list);
    setIndeterminateAccount(
      !!list.length && list.length < AccountOptions.length
    );
    setCheckAll(list.length === AccountOptions.length);
  };

  const onCheckAllAccountChange = (e) => {
    setAccountList(e.target.checked ? AccountOptions : []);
    setIndeterminateAccount(false);
    setCheckAllAccount(e.target.checked);
  };

  const onChangeCheckList = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const handleAccountChange = (event) => {
    setAccounts((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleNewPassChange = (event) => {
    setNewPass((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleFormChange = (event) => {
    setAForm((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleFormImage = (obj) => {
    setAForm((prevState) => {
      return {
        ...prevState,
        image: obj,
      };
    });
  };

  const handleRegisterChange = (event) => {
    setRegister((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleLinkChange = (event) => {
    setLink((prevState) => {
      return {
        ...prevState,
        [event.target.name]:
          event.target.name === "rut"
            ? rutUtils.formatoRut(event.target.value)
            : event.target.value,
      };
    });
  };

  const handleLinkCerChange = ({ name, value }) => {
    setLinkCertificate((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleLoading = (arg) => {
    setLoading(arg);
  };

  const handleError = (arg) => {
    setError(arg);
  };

  const handleSuccess = (arg) => {
    setSuccess(arg);
  };

  const handleFormRequest = (arg) => {
    setAForm((prevState) => {
      return {
        ...prevState,
        email: arg.email ? arg.email : "",
        phone: arg.phone_number ? arg.phone_number : "",
        web: arg.web_site ? arg.web_site : "",
        image: arg.url_img ? arg.url_img : "",
        mainColor: arg.first_color ? arg.first_color : "#fff",
        secondColor: arg.second_color ? arg.second_color : "#fff",
      };
    });
  };

  return {
    loading,
    register,
    success,
    handleRegisterChange,
    error,
    handleLoading,
    handleError,
    handleLinkChange,
    handleSuccess,
    link,
    handleLinkCerChange,
    linkCertificate,
    setLinkCertificate,
    checkedList,
    indeterminate,
    checkAll,
    onChangeCheckList,
    onCheckAllChange,
    plainOptions,
    defaultCheckedList,
    aForm,
    handleFormChange,
    handleFormRequest,
    collapse,
    setCollapse,
    handleFormImage,
    resetMessages,
    setLink,
    reset,
    setReset,
    setRegister,
    handleNewPassChange,
    newPass,
    handleAccountChange,
    accounts,
    accountList,
    onChangeAccountList,
    onCheckAllAccountChange,
    checkAllAccount,
    AccountOptions,
    indeterminateAccount,
  };
};

export default InitialState;
