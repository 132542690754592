import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  filterCompaniesSelectorCompanies,
  filterCompaniesSelectorCompaniesStatus,
  filterCompaniesSelectorGetEditCompanieMessage,
  filterCompaniesSelectorGetExportDataMessage,
  filterCompaniesSelectorGetImportDataMessage,
  filterCompaniesSelectorGetMessageError,
  filterCompaniesSliceClearEditCompanieMessage,
  filterCompaniesSliceClearExportDataMessage,
  filterCompaniesSliceClearImportDataMessage,
  filterCompaniesSliceClearMessageError,
  filterCompaniesSliceGetDataThunk,
} from "../../redux/slices/filterCompaniesSlice";
import { Box, Divider, IconButton } from "@mui/material";
import CompanyDataActions from "../../components/companyFilter/CompanyDataActions";
import { authSliceLogoutThunk } from "../../redux/slices/authSlice";
import { useHistory } from "react-router-dom";
import { DataGridStyled } from "../../components/ui/DataGridStyled";
import { CompanyFilterTableNavigation } from "../../components/companyFilter/CompanyFilterTableNavigation";
import { CompanyFilterModalEdit } from "../../components/companyFilter/CompanyFilterModalEdit";
import { SnackbarStyled } from "../../components/ui/SnackbarStyled";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  selectCustomFieldsStatus,
  selectCustomFields,
  getCustomFieldsThunk,
  selectMessageError,
} from "../../redux/slices/customFieldsSlice";

const Container = () => {
  const dispatch = useDispatch();

  const companiesData = useSelector(filterCompaniesSelectorCompanies);
  const companiesDataStatus = useSelector(
    filterCompaniesSelectorCompaniesStatus
  );
  const exportDataMessage = useSelector(
    filterCompaniesSelectorGetExportDataMessage
  );
  const importDataMessage = useSelector(
    filterCompaniesSelectorGetImportDataMessage
  );
  const editCompanieMessage = useSelector(
    filterCompaniesSelectorGetEditCompanieMessage
  );
  const customFields = useSelector(selectCustomFields);
  const customFieldsStatus = useSelector(selectCustomFieldsStatus);
  const customFieldMessage = useSelector(selectMessageError);
  const messageError = useSelector(filterCompaniesSelectorGetMessageError);
  const [exportNotification, setExportNotification] = useState(false);
  const [importNotification, setImportNotification] = useState(false);
  const [editCompanieNotification, setEditCompanieNotification] =
    useState(false);
  const [getCompaniesNotification, setGetCompaniesNotification] =
    useState(false);
  const [customFieldNotification, setCustomFieldNotification] = useState(false);
  const [loadingGetData, setLoadingGetData] = useState(false);

  const handleCloseExportNotification = () => {
    setExportNotification(false);
    dispatch(filterCompaniesSliceClearExportDataMessage());
  };

  const handleCloseImportNotification = () => {
    setImportNotification(false);
    dispatch(filterCompaniesSliceClearImportDataMessage());
  };

  const handleCloseEditCompanieNotification = () => {
    setEditCompanieNotification(false);
    dispatch(filterCompaniesSliceClearEditCompanieMessage());
  };

  const handleCloseGetCompaniesNotification = () => {
    setGetCompaniesNotification(false);
    dispatch(filterCompaniesSliceClearMessageError());
  };

  const handleCloseCustomFieldNotification = () => {
    setCustomFieldNotification(false);
  };

  const history = useHistory();
  const [companyData, setCompanyData] = useState(null);
  const [modal, setModal] = useState(false);

  const handleOnModal = () => {
    if (modal) {
      setCompanyData(null);
    }
    setModal(!modal);
  };

  const handleOnSetDataCompany = (data) => {
    setCompanyData({
      ...data,
      mails1: data.mails[0] ? data.mails[0] : "",
      mails2: data.mails[1] ? data.mails[1] : "",
      group: !!data.group ? data.group.split(",") : [],
      branch: !!data.branch ? data.branch.split(",") : [],
      executive: !!data.executive ? data.executive.split(",") : [],
    });
    handleOnModal();
  };

  useEffect(() => {
    const promise = dispatch(filterCompaniesSliceGetDataThunk());

    promise
      .unwrap()
      .then((result) => {
        if (result && result.companies.length > 0 && customFieldsStatus === "idle") {
            dispatch(getCustomFieldsThunk());
        }
      })
      .catch((err) => {
        if (err?.code === 401) {
          setTimeout(() => {
            dispatch(authSliceLogoutThunk()).finally(() => {
              history.replace("/");
            });
          }, 3000);
        }
      });

    return () => {
      promise.abort();
    };
  }, [dispatch, history, customFieldsStatus]);

  useEffect(() => {
    if (importDataMessage && importDataMessage?.message) {
      setImportNotification(true);
      if (importDataMessage.type === "success") {
        setLoadingGetData(true);
        dispatch(filterCompaniesSliceGetDataThunk())
          .unwrap()
          .finally(() => {
            setLoadingGetData(false);
          });
      }
    } else {
      setImportNotification(false);
    }
  }, [importDataMessage, dispatch]);

  useEffect(() => {
    if (messageError && messageError?.message) {
      setGetCompaniesNotification(true);
    } else {
      setGetCompaniesNotification(false);
    }
  }, [messageError]);

  useEffect(() => {
    if (exportDataMessage && exportDataMessage?.message) {
      setExportNotification(true);
    } else {
      setExportNotification(false);
    }
  }, [exportDataMessage]);

  useEffect(() => {
    if (editCompanieMessage && editCompanieMessage?.message) {
      setEditCompanieNotification(true);
    } else {
      setEditCompanieNotification(false);
    }
  }, [editCompanieMessage]);

  useEffect(() => {
    if (customFieldMessage && customFieldMessage?.message) {
      setCustomFieldNotification(true);
    } else {
      setCustomFieldNotification(false);
    }
  }, [customFieldMessage]);

  useEffect(() => {
    dispatch(filterCompaniesSliceClearImportDataMessage());
    dispatch(filterCompaniesSliceClearMessageError());
    dispatch(filterCompaniesSliceClearExportDataMessage());
    dispatch(filterCompaniesSliceClearEditCompanieMessage());
    return () => {
      dispatch(filterCompaniesSliceClearImportDataMessage());
      dispatch(filterCompaniesSliceClearMessageError());
      dispatch(filterCompaniesSliceClearExportDataMessage());
      dispatch(filterCompaniesSliceClearEditCompanieMessage());
    };
  }, [dispatch]);

  const columns = [
    { field: "id", headerName: "Identificador", width: 70 },
    { field: "business_name", headerName: "Empresa", flex: 1, minWidth: 150 },
    { field: "rut", headerName: "RUT", flex: 1, minWidth: 120 },
    { field: "group", headerName: "Grupo", flex: 1 },
    { field: "branch", headerName: "Sucursal", flex: 1 },
    { field: "executive", headerName: "Ejecutivo", flex: 1 },
    {
      field: "mails",
      headerName: "Correo 1°",
      flex: 1,
      valueGetter: ({ row }) => (row.mails[0] ? row.mails[0] : ""),
    },
    {
      field: "has_taxfolder",
      headerName: "Correo 2°",
      flex: 1,
      valueGetter: ({ row }) => (row.mails[1] ? row.mails[1] : ""),
    },
  ];

  if (customFields && customFields.length > 0) {
    columns.push(
      ...customFields.map((field) => ({
        field: field.field_name,
        headerName: field.field_name,
        flex: 1,
        filterable: true,
        valueGetter: ({ row }) => {
          const customField = row.custom_field?.find(
            (cf) => cf.field_name === field.field_name
          );
          if (!customField) return "";

          if (field.field_type === "checkbox") {
            return customField.field_value === "1" ||
              customField.field_value === "on"
              ? "✓"
              : "";
          }

          return customField.field_value;
        },
      }))
    );
  }

  columns.push({
    field: "connected",
    headerName: "Acción",
    minWidth: 85,
    flex: 1,
    renderCell: ({ row }) => (
      <IconButton
        color="secondary"
        size="small"
        onClick={() => handleOnSetDataCompany(row)}
        key={row.id}
      >
        <EditOutlinedIcon />
      </IconButton>
    ),
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
      bgcolor={"transparent"}
    >
      <Box sx={{ bgcolor: "background.secondary" }}>
        <DataGridStyled
          loading={loadingGetData || companiesDataStatus === "loading"}
          sx={{ overflow: "auto" }}
          autoHeight
          disableRowSelectionOnClick
          title="Centro Filtrado de Empresas"
          rows={companiesData}
          columns={columns}
          slots={{
            pagination: CompanyFilterTableNavigation,
          }}
        />
      </Box>
      <Divider />
      <CompanyDataActions customFields={customFields} />
      <CompanyFilterModalEdit
        show={modal}
        onShow={handleOnModal}
        data={companyData}
        customFields={customFields}
      />
      <SnackbarStyled
        message={exportDataMessage?.message || undefined}
        type={exportDataMessage?.type || undefined}
        key={`export-${exportDataMessage?.message}`}
        open={exportNotification}
        onClose={handleCloseExportNotification}
      />
      <SnackbarStyled
        message={importDataMessage?.message || undefined}
        type={importDataMessage?.type || undefined}
        key={`import-${importDataMessage?.message}`}
        open={importNotification}
        onClose={handleCloseImportNotification}
      />
      <SnackbarStyled
        message={editCompanieMessage?.message || undefined}
        type={editCompanieMessage?.type || undefined}
        key={`edit-${editCompanieMessage?.message}`}
        open={editCompanieNotification}
        onClose={handleCloseEditCompanieNotification}
      />
      <SnackbarStyled
        message={customFieldMessage?.message || undefined}
        type={customFieldMessage?.type || undefined}
        key={`custom-${customFieldMessage?.message}`}
        open={customFieldNotification}
        onClose={handleCloseCustomFieldNotification}
      />
      <SnackbarStyled
        message={messageError?.message || undefined}
        type={messageError?.type || undefined}
        key={`error-${messageError?.message}`}
        open={getCompaniesNotification}
        onClose={handleCloseGetCompaniesNotification}
      />
    </Box>
  );
};

export default Container;
