import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { onboardingApiGetData } from "../api/onboardingApi";

const initialState = {
  data: [],
  messageError: null,
  showModal: false,
  status: "idle",
};

export const onboardingSliceGetDataThunk = createAsyncThunk(
  "onboarding/getData",
  async (_, { rejectWithValue, signal }) => {
    try {
      const { fapro } = await onboardingApiGetData({
        signal,
      });

      if (fapro.success) {
        return fapro.data;
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const onboardingSlice = createSlice({
  name: "onboardingSlice",
  initialState,
  reducers: {
    onboardingSliceClearMessageError: (state, action) => {
      state.messageError = null;
    },
    onboardingSliceClearData: (state, action) => {
      state.data = null;
    },
    onboardingSliceSetShowModal: (state, action) => {
      state.showModal = action.payload.show;
    },
  },
  extraReducers: {
    [onboardingSliceGetDataThunk.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = "success";
    },
    [onboardingSliceGetDataThunk.rejected]: (state, action) => {
      state.data = [];
      state.status = "error";
      if (action.payload?.code === 401) {
        state.messageError = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.messageError = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [onboardingSliceGetDataThunk.pending]: (state, action) => {
      state.data = [];
      state.status = "loading";
      state.messageError = null;
    },
  },
});

export const onboardingSelectorGetData = createSelector(
  ({ onboardingSlice }) => onboardingSlice.data,
  (data) => data
);

export const onboardingSelectorGetDataStatus = createSelector(
  ({ onboardingSlice }) => onboardingSlice.status,
  (data) => data
);

export const onboardingSelectorGetMessageError = createSelector(
  ({ onboardingSlice }) => onboardingSlice.messageError,
  (data) => data
);

export const onboardingSelectorGetShowModal = createSelector(
  ({ onboardingSlice }) => onboardingSlice.showModal,
  (data) => data
);

export const {
  onboardingSliceClearMessageError,
  onboardingSliceClearData,
  onboardingSliceSetShowModal,
} = onboardingSlice.actions;
