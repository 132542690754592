import "../../components/css/customFields.css";
import { Box, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGridStyled } from "../../components/ui/DataGridStyled";
import { SnackbarStyled } from "../../components/ui/SnackbarStyled";
import { DataGridPaginationCustomFields } from "../../components/customFields/DataGridPaginationCustomFields";
import { CustomFieldsModalRemove } from "../../components/customFields/CustomFieldsModalRemove";
import { CustomFieldsModalEdit } from "../../components/customFields/CustomFieldsModalEdit";
import {
  clearMessageError,
  getCustomFieldsThunk,
  selectCustomFieldsStatus,
  selectCustomFields,
  selectMessageError,
} from "../../redux/slices/customFieldsSlice";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const fieldTypeMapping = {
  text: "Texto",
  number: "Numero",
  list: "Lista",
  checkbox: "Checkbox",
};

const columns = (handleShowEdit, handleShowRemove) => [
  {
    field: "field_name",
    headerName: "Nombre Campo",
    sortable: true,
    flex: 60,
    renderCell: (params) => (
      <div className="box-column-container">
        <div className="box-column-container-div">{params.value}</div>
      </div>
    ),
  },
  {
    field: "field_type",
    headerName: "Tipo",
    flex: 30,
    renderCell: (params) => (
      <div className="box-column-container-div">
        {params.row.field_type === "lista" ? (
          <Tooltip title={params.row.field_type_list_values}>
            <span>{fieldTypeMapping[params.row.field_type]}</span>
          </Tooltip>
        ) : (
          <span>{fieldTypeMapping[params.row.field_type] || params.value}</span>
        )}
      </div>
    ),
  },
  {
    field: "entity",
    headerName: "Entidad",
    flex: 20,
    renderCell: (params) => (
      <div className="box-column-container-div">{params.value}</div>
    ),
  },
  {
    headerName: "Acción",
    field: "id",
    key: "id",
    flex: 20,
    renderCell: ({ row }) => {
      return (
        <Box className="buttons-container-action">
          <IconButton
            color="secondary"
            size="small"
            onClick={() => handleShowEdit(row)}
          >
            <EditOutlinedIcon />
          </IconButton>
          <IconButton
            color="secondary"
            size="small"
            onClick={() => handleShowRemove(row)}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </Box>
      );
    },
  },
];

const CustomFieldsContainer = () => {
  const dispatch = useDispatch();
  const customFields = useSelector(selectCustomFields);
  const customFieldsStatus = useSelector(selectCustomFieldsStatus);
  const messageError = useSelector(selectMessageError);
  const [open, setOpen] = useState(false);

  const [currentField, setCurrentField] = useState(null);

  const [editShow, setEditShow] = useState(false);
  const [removeShow, setRemoveShow] = useState(false);

  const handleShowEdit = (props) => {
    setEditShow(!editShow);
    setCurrentField(props || null);
  };
  const handleShowRemove = (props) => {
    setRemoveShow(!removeShow);
    setCurrentField(props || null);
  };

  useEffect(() => {
    dispatch(getCustomFieldsThunk());
    dispatch(clearMessageError());
  }, [dispatch]);

  const handleClose = () => {
    setOpen(false);
    dispatch(clearMessageError());
  };

  useEffect(() => {
    if (messageError && messageError?.message) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [messageError]);

  return (
    <>
      <Box className="container-custom-fields">
        <DataGridStyled
          title="Campos personalizados"
          rows={customFields}
          slots={{
            pagination: DataGridPaginationCustomFields,
          }}
          columns={columns(handleShowEdit, handleShowRemove)}
          pageSize={5}
          rowsPerPageOptions={[5]}
          loading={customFieldsStatus === "loading"}
          disableRowSelectionOnClick
          getRowId={(row) => row.id}
          initialState={{
            sorting: {
              sortModel: [{ field: "id", sort: "asc" }],
            },
          }}
        />
        <SnackbarStyled
          message={messageError?.message.split("\n").map((msg, index) => (
            <div key={index} className="snackbar-message">
              {msg}
            </div>
          ))}
          type={messageError?.type || undefined}
          key={JSON.stringify(messageError)}
          open={open}
          onClose={handleClose}
        />
      </Box>
      {removeShow && (
        <CustomFieldsModalRemove
          show={removeShow}
          onShow={handleShowRemove}
          data={currentField}
        />
      )}
      {editShow && (
        <CustomFieldsModalEdit
          show={editShow}
          onShow={handleShowEdit}
          data={currentField}
        />
      )}
    </>
  );
};

export default CustomFieldsContainer;
