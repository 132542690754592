import { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  emailSelectorGetData,
  emailSelectorGetDataStatus,
  emailSelectorGetMessageError,
  emailSliceClearMessageError,
  emailSliceGetDataThunk,
} from "../../redux/slices/emailSlice";
import { Box, IconButton } from "@mui/material";
import { authSliceLogoutThunk } from "../../redux/slices/authSlice";
import { useHistory } from "react-router-dom";
import { DataGridStyled } from "../../components/ui/DataGridStyled";
import { DataGridPaginationEmails } from "../../components/emails/DataGridPaginationEmails";
import { EmailsModalRemove } from "../../components/emails/EmailsModalRemove";
import { EmailsModalEdit } from "../../components/emails/EmailsModalEdit";
import { SnackbarStyled } from "../../components/ui/SnackbarStyled";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const Container = () => {
  const dispatch = useDispatch();
  const dataStatus = useSelector(emailSelectorGetDataStatus);
  const data = useSelector(emailSelectorGetData);
  const messageError = useSelector(emailSelectorGetMessageError);
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const [currentField, setCurrentField] = useState(null);

  const [editShow, setEditShow] = useState(false);
  const [removeShow, setRemoveShow] = useState(false);

  const handleClose = () => {
    setOpen(!open);
    if (!open) {
      dispatch(emailSliceClearMessageError());
    }
  };

  const handleShowEdit = (props) => {
    setEditShow(!editShow);
    setCurrentField(props || null);
  };
  const handleShowRemove = (props) => {
    setRemoveShow(!removeShow);
    setCurrentField(props || null);
  };
  const columns = [
    {
      headerName: "Correo Electrónico",
      field: "email",
      key: "email",
      flex: 2,
    },
    {
      headerName: "Fecha de Creación",
      field: "created_at",
      key: "created_at",
      flex: 2,
      valueGetter: ({ row }) => {
        return moment(row.created_at).format("DD/MM/YYYY");
      },
    },
    {
      headerName: "Acción",
      field: "id",
      key: "id",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box
            display="flex"
            gap={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <IconButton
              color="secondary"
              size="small"
              onClick={() => handleShowEdit(row)}
            >
              <EditOutlinedIcon />
            </IconButton>
            <IconButton
              color="secondary"
              size="small"
              onClick={() => handleShowRemove(row)}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    if (messageError && messageError?.message) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [messageError]);

  useEffect(() => {
    dispatch(emailSliceClearMessageError());
    return () => {
      dispatch(emailSliceClearMessageError());
    };
  }, []);

  useEffect(() => {
    const promise = dispatch(emailSliceGetDataThunk());

    promise.unwrap().catch((err) => {
      if (err.code === 401) {
        setTimeout(() => {
          dispatch(authSliceLogoutThunk()).finally(() => {
            history.replace("/");
          });
        }, 3000);
      }
    });

    return () => {
      promise.abort();
    };
  }, []);

  return (
    <>
      <Box p={0} height="94vh" width="100%" bgcolor={"transparent"}>
        <DataGridStyled
          disableRowSelectionOnClick
          title="Gestión de Correos Electrónicos"
          rows={data}
          columns={columns}
          slots={{
            pagination: DataGridPaginationEmails,
          }}
          loading={dataStatus === "loading"}
        />
        <SnackbarStyled
          message={messageError?.message || undefined}
          type={messageError?.type || undefined}
          key={JSON.stringify(messageError)}
          open={open}
          onClose={handleClose}
        />
      </Box>
      {removeShow && (
        <EmailsModalRemove
          show={removeShow}
          onShow={handleShowRemove}
          data={currentField}
        />
      )}
      {editShow && (
        <EmailsModalEdit
          show={editShow}
          onShow={handleShowEdit}
          data={currentField}
        />
      )}
    </>
  );
};

export default Container;
