import React from "react";
import { Link } from "react-router-dom";
import { MenuItem, Tooltip, ListItemIcon, Typography } from "@mui/material";
import Zoom from "@mui/material/Zoom";
import { motion } from "framer-motion";
import '../../css/style.css'; 

const CustomMenuItem = ({
  to,
  icon,
  title,
  children,
  collapsedSidebar,
}) => (
  <Link to={to}>
    <MenuItem
      sx={{
        px: 3,
        py: 1.25,
        "&:hover": {
          bgcolor: "background.hover",
        },
      }}
    >
      <Tooltip TransitionComponent={Zoom} title={title} placement="right">
        <ListItemIcon
          sx={{
            color: (theme) => theme.palette.text.faproPrimary,
            display: "flex",
            alignItems: "center",
            justifyContent: collapsedSidebar ? "flex-start" : "center",
          }}
        >
          {icon}
        </ListItemIcon>
      </Tooltip>
      <motion.div
        className={collapsedSidebar ? 'motion-show' : 'motion-hidden'}
      >
        <Typography
          variant="body2"
          color="text.faproPrimary"
          sx={{ opacity: collapsedSidebar ? 1 : 0 }}
        >
          {children}
        </Typography>
      </motion.div>
    </MenuItem>
  </Link>
);

export default CustomMenuItem;
