import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { taxApiGetData, taxApiGetDataFolderEmbedUrl } from '../api/taxApi';

const initialState = {
    data: {
        companies: [],
        status: 'idle',
    },
    taxFolderEmbedUrl: null,
    messageError: null,
}

export const taxSliceGetDataThunk = createAsyncThunk(
    'tax/getData',
    async (
        _,
        {
            rejectWithValue,
            dispatch,
            signal
        }
    ) => {
        try {
            const {
                fapro
            } = await taxApiGetData({
                signal
            })

            if(fapro.success) {
                return fapro.data
            } else {
                return rejectWithValue(fapro)
            }
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const taxSliceGetDataFolderEmbedUrlThunk = createAsyncThunk(
    'tax/getDataFolderEmbedUrl',
    async (
        payload,
        {
            rejectWithValue,
            signal
        }
    ) => {
        try {

            const {
                fapro
            } = await taxApiGetDataFolderEmbedUrl({
                signal,
                ...payload,
            })

            if(fapro.success) {
                return fapro.data
            } else {
                return rejectWithValue(fapro)
            }
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)


export const taxSlice = createSlice({
    name: 'taxSlice',
    initialState,
    reducers: {
        taxSliceClearMessageError: (state, action) => {
            state.messageError = null
        },
        taxSliceClearTaxFolderEmbedUrl: (state, action) => {
            state.taxFolderEmbedUrl = null
        },
        taxSliceSetMessageError: (state, action) => {
            state.messageError = action.payload; 
        },
    },
    extraReducers: {
        [taxSliceGetDataThunk.fulfilled]: (state, action) => {
            state.data = {
                ...state.data,
                ...action.payload,
                status: 'success'
            }
        },
        [taxSliceGetDataThunk.rejected]: (state, action) => {
            state.data = {
                companies: [],
                status: 'error'
            }
            if(action.payload?.code === 401) {
                state.messageError = {
                    type: 'warning',
                    message: "Su sesión ha expirado, por favor vuelva a iniciar sesión."
                }
            } else {
                state.messageError = {
                    type: 'error',
                    message: action.payload.message
                }
            }
        },
        [taxSliceGetDataThunk.pending]: (state, action) => {
            state.data = {
                companies: [],
                status: 'loading'
            }
            state.messageError = null
        },


        [taxSliceGetDataFolderEmbedUrlThunk.fulfilled]: (state, action) => {
            state.taxFolderEmbedUrl = action.payload
            state.messageError = {
                message: "Documento generado correctamente",
                type: 'success'
            }
        },
        [taxSliceGetDataFolderEmbedUrlThunk.rejected]: (state, action) => {
            state.taxFolderEmbedUrl = null
            
            if(action.payload?.code === 401) {
                state.messageError = {
                    type: 'warning',
                    message: "Su sesión ha expirado, por favor vuelva a iniciar sesión."
                }
            } else {
                state.messageError = {
                    type: 'error',
                    message: action.payload.message
                }
            }

        },
        [taxSliceGetDataFolderEmbedUrlThunk.pending]: (state, action) => {
            state.taxFolderEmbedUrl = null
            state.messageError = null
        },
    }
  }
)


export const taxSelectorGetData = createSelector(
    ({
        taxSlice
    }) => taxSlice.data,
    (data) => data 
)

export const taxSelectorGetDataStatus = createSelector(
    ({
        taxSlice
    }) => taxSlice.data.status,
    (data) => data 
)

export const taxSelectorGetDataEmbedUrl = createSelector(
    ({
        taxSlice
    }) => taxSlice.taxFolderEmbedUrl,
    (data) => data 
)

export const taxSelectorGetMessageError = createSelector(
    ({
        taxSlice
    }) => taxSlice.messageError,
    (data) => data 
)

export const {
    taxSliceClearMessageError,
    taxSliceClearTaxFolderEmbedUrl,
    taxSliceSetMessageError
} = taxSlice.actions