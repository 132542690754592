import React, { useEffect, useState } from "react";
import { ModalStyled } from "../ui/ModalStyled";
import { Box, Typography } from "@mui/material";
import {
  emailSelectorGetDataMailchimp,
  emailSelectorGetRowSelectedMailchimp,
  emailSliceGetDataEmailMailchimpThunk,
  emailSliceSetRowSelectedMailchimp,
} from "../../redux/slices/emailSlice";
import { authSliceLogoutThunk } from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DataGridStyled } from "../ui/DataGridStyled";
import moment from "moment";
import { EmailModalMailchimpDataGridCustomPagination } from "./EmailModalMailchimpDataGridCustomPagination";
import { EmailModalEditMailchimp } from "./EmailModalEditMailchimp";
import { EmailModalRemoveMailchimp } from "./EmailModalRemoveMailchimp";
import { EmailsModalRemoveMassiveRecords } from "./EmailsModalRemoveMassiveRecords";

export const EmailsModalMailchimp = ({ show, onShow }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const data = useSelector(emailSelectorGetDataMailchimp);
  const rowSelected = useSelector(emailSelectorGetRowSelectedMailchimp);

  const [editShow, setEditShow] = useState(false);
  const [removeShow, setRemoveShow] = useState(false);
  const [removeMassiveShow, setRemoveMassiveShow] = useState(false);
  const [currentField, setCurrentField] = useState(null);

  const handleShowEdit = (props) => {
    setEditShow(!editShow);
    setCurrentField(props || null);
  };
  const handleShowRemove = (props) => {
    setRemoveShow(!removeShow);
    setCurrentField(props || null);
  };
  const handleShowRemoveMassive = () => {
    setRemoveMassiveShow(!removeMassiveShow);
  };

  const columns = [
    {
      headerName: "Correo Electrónico",
      field: "email",
      key: "email",
      flex: 1,
    },
    {
      headerName: "Razón de Rechazo",
      field: "reason",
      key: "reason",
      flex: 2,
      renderCell: ({ row }) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: 0.5,
          }}
        >
          <Typography
            variant="body"
            sx={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              textAlign: "left",
              fontSize: 12,
              lineHeight: 1,
              fontWeight: 600,
              color: (theme) => theme.palette.text.faproPrimary + "dd",
            }}
          >
            {row.reason}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              textAlign: "left",
              fontSize: 10,
              lineHeight: 1,
              color: (theme) => theme.palette.text.faproPrimary + "aa",
            }}
          >
            {row.description}
          </Typography>
        </Box>
      ),
    },
    {
      headerName: "Agregado",
      field: "date",
      key: "date",
      valueFormatter: ({ value }) => moment(value).format("DD MMM YYYY"),
      maxWidth: 105,
    },
  ];

  useEffect(() => {
    const promise = dispatch(emailSliceGetDataEmailMailchimpThunk());

    promise.unwrap().catch((err) => {
      if (err.code === 401) {
        setTimeout(() => {
          dispatch(authSliceLogoutThunk()).finally(() => {
            history.replace("/");
          });
        }, 3000);
      }
    });

    return () => {
      promise.abort();
    };
  }, [dispatch, history]);

  return (
    <ModalStyled
      show={show}
      onShow={onShow}
      closeOnBackdrop={false}
      maxWidth="lg"
      title={"Correos con Problemas"}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          bgcolor: "background.terciary",
          boxShadow: 24,
          p: 4,
          pt: 3,
          borderRadius: 2,
          overflowY: "auto",
        }}
        className="md-scroll"
      >
        <Typography
          textAlign={"center"}
          gutterBottom
          sx={{
            maxWidth: "85%",
            mx: "auto",
          }}
        >
          Si ves un correo en esta lista, por favor escríbenos directamente a
          hola@fapro.app contándonos cuál es el que posee problemas.
        </Typography>
        <DataGridStyled
          rowHeight={72}
          title={"Centro de Gestión de Descargas"}
          columns={columns}
          rows={data}
          disableRowSelectionOnClick
          loading={false}
          isRowSelectable={() => true}
          autoHeight
          onRowSelectionModelChange={(newRowSelectionModel) => {
            dispatch(emailSliceSetRowSelectedMailchimp(newRowSelectionModel));
          }}
          hideFooterSelectedRowCount
          rowSelectionModel={rowSelected}
          disabledPrintOptions
          slotPropsToolbar={{
            disableToolsToolbar: true,
          }}
          slotPropsPagination={{
            handleShowRemoveMassive,
          }}
          slots={{
            pagination: EmailModalMailchimpDataGridCustomPagination,
            toolbar: null,
          }}
          sx={{
            mt: 2,
          }}
        />
      </Box>
      {editShow && (
        <EmailModalEditMailchimp
          show={editShow}
          onShow={handleShowEdit}
          data={currentField}
        />
      )}
      {removeShow && (
        <EmailModalRemoveMailchimp
          show={removeShow}
          onShow={handleShowRemove}
          data={currentField}
        />
      )}
      {removeMassiveShow && (
        <EmailsModalRemoveMassiveRecords
          show={removeMassiveShow}
          onShow={handleShowRemoveMassive}
        />
      )}
    </ModalStyled>
  );
};
