export const pick = (object, keys) => {
  return keys.reduce((obj, key) => {
    if (object && object.hasOwnProperty(key)) {
      obj[key] = object[key];
    }
    return obj;
  }, {});
};

export const alphabeticalSorter = (a, b) => {
  const firstEl = a || "";
  const secondEl = b || "";
  return firstEl.localeCompare(secondEl);
};

export const isFeatureAvailable = (features, codename) => {
  for (var i = 0; i < features.length; i++) {
    if (features[i].codename === codename) {
      return true;
    }
  }
  return false;
};
