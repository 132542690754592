import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_AUTH_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const getAuthToken = () => `Bearer ${localStorage.getItem("fapro_token")}`;

const callApi = async ({
  url,
  method,
  data = null,
  params = null,
  signal = null,
}) => {
  try {
    const response = await api({
      url,
      method,
      headers: {
        Authorization: getAuthToken(),
      },
      data,
      params,
      signal,
    });

    if (response.status === 200) {
      return {
        success: true,
        data: response.data.data,
        message: response.data.message,
      };
    } else {
      return {
        success: false,
        message: response?.data?.errors.join(""),
        data: null,
      };
    }
  } catch (error) {
    const message =
      error.response?.data?.errors?.join("\n") ||
      error.response?.data?.message ||
      "Ha ocurrido un error, intente nuevamente más tarde.";
    return { success: false, message, data: null };
  }
};

export const getCustomFields = async (signal) => {
  return callApi({
    url: "/api/v1.0/custom_field",
    method: "get",
    signal,
  });
};

export const postCustomFields = async ({ data, signal }) => {
  return callApi({
    url: "/api/v1.0/custom_field",
    method: "post",
    data,
    signal,
  });
};

export const patchCustomFields = async ({ data, signal }) => {
  return callApi({
    url: "/api/v1.0/custom_field",
    method: "put",
    data,
    signal,
  });
};

export const deleteCustomFields = async ({ customFieldID, signal }) => {
  return callApi({
    url: `/api/v1.0/custom_field/delete`,
    method: "post",
    data: { id: customFieldID },
    signal,
  });
};

export const postCustomFieldCompany = async ({ data, signal }) => {
  return callApi({
    url: "/api/v1.0/custom_field_value",
    method: "post",
    data,
    signal,
  });
};
