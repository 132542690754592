import React, { useEffect, useState } from "react";
import { notificationSelectorGetDataStatus } from "../../redux/slices/notificationSlice";
import { useSelector } from "react-redux";
import { ModalStyled } from "../ui/ModalStyled";
import { Alert, Avatar, Box, Button, Tooltip, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

export const AlertSystemStatus = () => {
  const dataStatus = useSelector(notificationSelectorGetDataStatus);

  const [dataStatusParsed, setDataStatusParsed] = useState([]);

  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (dataStatus.length > 0) {
      const _dataStatusParsed = dataStatus.map((item) => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return {
          key: key,
          value: value,
        };
      });
      setDataStatusParsed(_dataStatusParsed);
    }
  }, [dataStatus]);

  useEffect(() => {
    if (
      (pathname === "/dashboard" || pathname === "/dashboard/") &&
      dataStatus.length > 0
    ) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    return () => {
      setOpen(false);
    };
  }, [pathname, dataStatus]);

  return (
    <ModalStyled
      show={open}
      title={"Servicios no disponibles en este momento"}
      onShow={() => setOpen(false)}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 650,
          bgcolor: "background.terciary",
          boxShadow: 24,
          p: 4,
          pt: 3,
          borderRadius: 2,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
        className="sidenav-scroll"
      >
        <Typography textAlign={"justify"}>
          Lo sentimos, en este momento no es posible acceder a los siguientes
          servicios:
        </Typography>
        <Box
          mt={2}
          display="flex"
          flexDirection="column"
          width="100%"
          justifyContent="flex-start"
          alignItems="flex-start"
          gap={1.35}
        >
          {dataStatusParsed.map(({ key, value }) => (
            <Alert
              key={key}
              variant="standard"
              severity="error"
              sx={{
                width: "100%",
                borderRadius: 2.5,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                fontSize: 14,
                bgcolor: (theme) => `${theme.palette.error.main}17`,
                color: (theme) => `${theme.palette.error.dark}`,
              }}
              iconMapping={{
                error: (
                  <Tooltip title={key}>
                    <Avatar
                      variant="rounded"
                      src={`/assets/images/${key}.jpg`}
                      sx={{
                        width: 32,
                        height: 32,
                        p: 0.05,
                        bgcolor: "#ffffff",
                      }}
                      imgProps={{
                        style: {
                          objectFit: "contain",
                        },
                      }}
                    />
                  </Tooltip>
                ),
              }}
            >
              {`${value} - ${key}`}
            </Alert>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 2,
            width: "100%",
            mt: 3,
          }}
        >
          <Button
            variant="contained"
            color="error"
            sx={{}}
            type="submit"
            onClick={() => setOpen(false)}
          >
            Aceptar
          </Button>
        </Box>
      </Box>
    </ModalStyled>
  );
};
