import { useEffect, useState } from "react";
import Form from "../../components/link/Form";
import { useDispatch, useSelector } from "react-redux";
import {
  linkCompaniesSelectorGetMessageRutKey,
  linkCompaniesSliceClearMessageLinkPostRutKey,
} from "../../redux/slices/linkCompaniesSlices";
import { Box } from "@mui/material";
import CardStyled from "../../components/ui/CardStyled";
import { SnackbarStyled } from "../../components/ui/SnackbarStyled";

const Container = () => {
  const dispatch = useDispatch();
  const messageRutKey = useSelector(linkCompaniesSelectorGetMessageRutKey);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    dispatch(linkCompaniesSliceClearMessageLinkPostRutKey());
  };

  useEffect(() => {
    if (messageRutKey && messageRutKey?.message) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [messageRutKey]);

  useEffect(() => {
    dispatch(linkCompaniesSliceClearMessageLinkPostRutKey());
    return () => {
      dispatch(linkCompaniesSliceClearMessageLinkPostRutKey());
    };
  }, []);

  return (
    <Box
      bgcolor={"transparent"}
      display={"flex"}
      justifyContent="center"
      alignItems="center"
      width="100%"
      height={"94vh"}
    >
      <CardStyled title="Centro de Vinculación de Empresas" mx="auto">
        <Form />
      </CardStyled>
      <SnackbarStyled
        message={messageRutKey?.message || undefined}
        type={messageRutKey?.type || undefined}
        key={JSON.stringify(messageRutKey)}
        open={open}
        onClose={handleClose}
      />
    </Box>
  );
};

export default Container;
