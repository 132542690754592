import { Box, Button, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { authSliceLogoutThunk } from "../../redux/slices/authSlice";
import { ModalStyled } from "../ui/ModalStyled";
import {
  downloadSliceGetDataThunk,
} from "../../redux/slices/downloadSlice";
import {
  emailSelectorGetDataMailchimp,
  emailSelectorGetRowSelectedMailchimp,
  emailSliceRemoveDataEmailMassiveMailchimpThunk,
  emailSliceResetRowSelectedMailchimp,
} from "../../redux/slices/emailSlice";

export const EmailsModalRemoveMassiveRecords = ({ show, onShow }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const history = useHistory();

  const rowSelected = useSelector(emailSelectorGetRowSelectedMailchimp);
  const data = useSelector(emailSelectorGetDataMailchimp);

  const handleSubmit = () => {
    setLoading(true);
    const currentCompanies = data.filter((val) =>
      rowSelected.includes(val.subscriber_id)
    );
    dispatch(
      emailSliceRemoveDataEmailMassiveMailchimpThunk(
        currentCompanies.map(({ email, subscriber_id }) => ({
          email,
          subscriber_id,
        }))
      )
    )
      .unwrap()
      .then(() => {
        dispatch(emailSliceResetRowSelectedMailchimp());
        dispatch(downloadSliceGetDataThunk());
        onShow();
      })
      .catch((err) => {
        if (err?.code === 401) {
          setTimeout(() => {
            dispatch(authSliceLogoutThunk()).finally(() => {
              history.replace("/");
            });
          }, 3000);
        } else {
          onShow();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ModalStyled show={show} onShow={() => onShow()}>
      <Box
        sx={{
          width: "100%",
          maxWidth: 650,
          bgcolor: "background.primary",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
        className="sidenav-scroll"
      >
        <Typography variant="h6" gutterBottom component="h6">
          Eliminar Correos Electrónicos
        </Typography>
        <Typography variant="bold">
          ¿Está seguro que desea eliminar los correos electrónicos
          seleccionados?
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 2,
            width: "100%",
            mt: 3,
          }}
        >
          <Button
            color="error"
            type="button"
            disabled={loading}
            onClick={() => onShow()}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            onClick={handleSubmit}
          >
            Eliminar
          </Button>
        </Box>
      </Box>
    </ModalStyled>
  );
};
