import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { DataGridStyled } from "../../components/ui/DataGridStyled";
import { DataGridPaginationStyled } from "../../components/ui/DataGridPaginationStyled";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Formik } from "formik";
import moment from "moment";
import { ModalStyled } from "../../components/ui/ModalStyled";
import { useDispatch, useSelector } from "react-redux";
import {
  debtorBlackSliceDisableThunk,
  debtorBlackSliceEnableThunk,
  debtorBlackSliceGetDataThunk,
  debtorSelectorGetDataBlack,
  debtorSelectorGetDataStatus,
  debtorSelectorGetEnableData,
  debtorSelectorGetLoadingMassive,
  debtorSelectorGetMessageError,
  debtorSelectorGetRowSelected,
  debtorSliceClearEnableData,
  debtorSliceClearMessageError,
  debtorSliceResetRowSelected,
  debtorSliceSetRowSelected,
} from "../../redux/slices/debtorSlice";
import { SnackbarStyled } from "../../components/ui/SnackbarStyled";
import { BackdropWaitingStyled } from "../../components/ui/BackdropWaitingStyled";

const Container = () => {
  const dispatch = useDispatch();

  const blackData = useSelector(debtorSelectorGetDataBlack);
  const rowSelected = useSelector(debtorSelectorGetRowSelected);
  const dataStatus = useSelector(debtorSelectorGetDataStatus);
  const loadingMassive = useSelector(debtorSelectorGetLoadingMassive);
  const enableData = useSelector(debtorSelectorGetEnableData);

  const messageError = useSelector(debtorSelectorGetMessageError);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(!open);
  };

  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModalIndividualDelete, setOpenModalIndividualDelete] =
    useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [error, setError] = useState("");
  const [debtorToAdd, setDebtorToAdd] = useState("");
  const [debtorToDelete, setDebtorToDelete] = useState("");
  const [addingDebtor, setAddingDebtor] = useState(false);

  const handleChangeAddDebtor = (event) => {
    const { value } = event.target;
    setDebtorToAdd(value.replace(/^(\d{8})(\w{1})$/, "$1-$2"));
  };

  const handleClickOpenModalCreate = () => {
    setOpenModalCreate(true);
  };

  const handleCloseModalCreate = () => {
    setOpenModalCreate(false);
  };

  const handleClickOpenModalIndividualDelete = (rut) => {
    setDebtorToDelete(rut);
    setOpenModalIndividualDelete(true);
  };

  const handleCloseModalIndividualDelete = () => {
    setOpenModalIndividualDelete(false);
  };

  const handleClickOpenModalDelete = () => {
    setOpenModalDelete(true);
  };

  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  useEffect(() => {
    const promise = dispatch(debtorBlackSliceGetDataThunk());

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, []);

  useEffect(() => {
    if (!!messageError && !!messageError.message && !!messageError.type) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [messageError]);

  useEffect(() => {
    dispatch(debtorSliceResetRowSelected());
    dispatch(debtorSliceClearMessageError());
    dispatch(debtorSliceClearEnableData());

    return () => {
      dispatch(debtorSliceResetRowSelected());
      dispatch(debtorSliceClearMessageError());
      dispatch(debtorSliceClearEnableData());
    };
  }, []);

  const addDebtorToList = async (rut) => {
    const cleanValue = rut.replace(/[.]/g, "");
    const rutAndDv = cleanValue.split("-");

    if (rutAndDv.length <= 1) {
      setError("El Rut ingresado no es válido.");
      return false;
    }
    setError("");
    setAddingDebtor(true);
    dispatch(
      debtorBlackSliceEnableThunk({
        data: [
          {
            rut: rutAndDv[0],
            dv: rutAndDv[1],
            debtor_type_name: "Lista negra",
          },
        ],
      })
    )
      .unwrap()
      .then(() => {
        setDebtorToAdd("");
        setAddingDebtor(false);
        handleCloseModalCreate();
        dispatch(debtorBlackSliceGetDataThunk());
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setAddingDebtor(false);
      });
  };
  const removeDebtorList = async (rut) => {
    const rutAndDv = rut.split("-");

    setAddingDebtor(true);
    dispatch(
      debtorBlackSliceDisableThunk({
        data: [
          {
            rut: rutAndDv[0],
            dv: rutAndDv[1],
            debtor_type_name: "Lista negra",
          },
        ],
      })
    )
      .unwrap()
      .then(() => {
        setDebtorToAdd("");
        setAddingDebtor(false);
        handleCloseModalIndividualDelete();
        dispatch(debtorBlackSliceGetDataThunk());
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setAddingDebtor(false);
      });
  };

  const removeDebtorArrayList = async () => {
    setAddingDebtor(true);

    let currentCompanies = blackData.filter((prev) =>
      rowSelected.includes(prev.id)
    );

    dispatch(
      debtorBlackSliceDisableThunk({
        data: currentCompanies.map((el, i) => ({
          ...el,
          debtor_type_name: "Lista negra",
        })),
      })
    )
      .unwrap()
      .then(() => {
        setDebtorToAdd("");
        handleCloseModalDelete();
        dispatch(debtorBlackSliceGetDataThunk());
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setAddingDebtor(false);
      });
  };

  const customToolbar = () => {
    return (
      <>
        <Formik
          initialValues={{
            massiveEnable: false,
            massiveDisable: false,
          }}
        >
          {({}) => (
            <Box
              width={"100%"}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              px={2}
              py={1}
            >
              <Box
                display={"flex"}
                flex={1}
                flexDirection={"column"}
                alignItems={"flex-start"}
                flexWrap={"wrap"}
                justifyContent={"center"}
                gap={1}
              >
                <Button
                  disabled={addingDebtor}
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleClickOpenModalCreate}
                >
                  Agregar
                </Button>
              </Box>

              <DataGridPaginationStyled
                labelDisplayedRows={() => {
                  return "";
                }}
              />
              <Box
                display={"flex"}
                flex={1}
                flexDirection={"column"}
                alignItems={"flex-end"}
                justifyContent={"center"}
                flexWrap={"wrap"}
                gap={1}
              >
                <Button
                  color="error"
                  variant="contained"
                  disabled={!rowSelected.length}
                  onClick={handleClickOpenModalDelete}
                >
                  Eliminar
                </Button>
              </Box>
              <BackdropWaitingStyled loading={loadingMassive} />
            </Box>
          )}
        </Formik>
      </>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          width: "100%",
          gap: 2,
        }}
        bgcolor={"transparent"}
      >
        <Box
          sx={{
            bgcolor: "background.secondary",
          }}
        >
          <DataGridStyled
            loading={dataStatus === "loading"}
            sx={{
              overflow: "auto",
            }}
            autoHeight
            checkboxSelection
            disableRowSelectionOnClick
            isRowSelectable={(params) => {
              return true;
            }}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              dispatch(debtorSliceSetRowSelected(newRowSelectionModel));
            }}
            rowSelectionModel={rowSelected}
            title="Deudores en Lista Negra"
            hideFooterSelectedRowCount={true}
            rows={blackData}
            slots={{
              pagination: customToolbar,
            }}
            columns={[
              {
                field: "id",
                headerName: "Identificador",
                flex: 0.5,
              },
              {
                field: "rut",
                headerName: "RUT",
                flex: 0.8,
                valueGetter: ({ row }) => {
                  return `${row.rut}-${row.dv}`;
                },
              },
              {
                field: "created_at",
                headerName: "Fecha de Creación",
                flex: 1,
                valueGetter: ({ row }) => {
                  return moment(row.created_at).format("DD/MM/YYYY hh:mm A");
                },
              },
              {
                field: "updated_at",
                headerName: "Fecha de Adición a esta Lista",
                flex: 1,
                valueGetter: ({ row }) => {
                  return moment(row.updated_at).format("DD/MM/YYYY hh:mm A");
                },
              },
              {
                field: "action",
                headerName: "Acción",
                minWidth: 85,
                flex: 0.5,
                renderCell: ({ row }) => {
                  return (
                    <IconButton
                      color="secondary"
                      size="small"
                      disabled={false}
                      onClick={() =>
                        handleClickOpenModalIndividualDelete(
                          `${row.rut}-${row.dv}`
                        )
                      }
                    >
                      <DeleteOutlineOutlinedIcon size="small" />
                    </IconButton>
                  );
                },
              },
            ]}
            exportOptionsParsed={(data) => {
              return {
                Identificador: data.id,
                RUT: `${data.rut.replaceAll(" ", "")}`,
                "Fecha de Creación": data.created_at,
                "Fecha de Adición": data.updated_at,
              };
            }}
            showExportOptions
            exportOptionsName={`Centro de Gestión de Deudores en Lista Negra - ${moment().format(
              "DD-MM-YYYY"
            )}`}
          />
        </Box>
      </Box>
      <ModalStyled
        show={openModalCreate}
        onShow={handleCloseModalCreate}
        title={"Agregar Deudor a Lista Negra"}
      >
        <DialogContent sx={{ width: 600 }}>
          <Typography gutterBottom textAlign={"center"}>
            Escribe el Rut del deudor que deseas agregar a la lista
          </Typography>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            id="rut"
            label="RUT"
            variant="outlined"
            name="rut"
            placeholder="12345678-9"
            inputProps={{
              pattern: "^[0-9]{8}[-]{0,1}[0-9kK]{1}$/u",
              maxLength: 10,
              value: debtorToAdd,
              onChange: handleChangeAddDebtor,
            }}
            error={Boolean(error)}
            helperText={error}
          />
        </DialogContent>
        <DialogActions sx={{ mx: 3, mb: 3 }}>
          <Button
            color="error"
            disabled={addingDebtor}
            onClick={handleCloseModalCreate}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={addingDebtor}
            onClick={() => addDebtorToList(debtorToAdd)}
            autoFocus
          >
            Agregar
          </Button>
        </DialogActions>
      </ModalStyled>
      <ModalStyled
        show={openModalIndividualDelete}
        onShow={handleCloseModalIndividualDelete}
        title={"Eliminar Deudor de Lista Negra"}
      >
        <DialogContent>
          <DialogContentText
            id="delete-individual-debtor-modal-description"
            textAlign={"center"}
          >
            <Typography gutterBottom>
              Estás por eliminar el deudor con el Rut: <b>{debtorToDelete}</b> y
              esta acción es irreversible.
            </Typography>
            <Typography variant="bold">¿Estás seguro?</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ mx: 3, mb: 2 }}>
          <Button
            disabled={addingDebtor}
            onClick={handleCloseModalIndividualDelete}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={addingDebtor}
            onClick={() => removeDebtorList(debtorToDelete)}
            autoFocus
          >
            Eliminar
          </Button>
        </DialogActions>
      </ModalStyled>
      <ModalStyled
        open={openModalDelete}
        onShow={handleCloseModalDelete}
        title={"Eliminar Deudores Seleccionados de Lista Negra"}
      >
        <DialogContent>
          <DialogContentText>
            <Typography gutterBottom textAlign={"center"}>
              Estás por eliminar los deudores con los siguientes Ruts:
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>RUT</TableCell>
                    <TableCell>Fecha de Creación</TableCell>
                    <TableCell>Fecha de Adición a la Lista Negra</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowSelected.map((row) => (
                    <TableRow
                      key={row}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {blackData.find((prev) => prev.id === row)?.id}
                      </TableCell>
                      <TableCell>
                        {`${blackData.find((prev) => prev.id === row)?.rut}-${
                          blackData.find((prev) => prev.id === row)?.dv
                        }`}
                      </TableCell>
                      <TableCell>
                        {!!blackData.find((prev) => prev.id === row)?.created_at
                          ? moment(
                              blackData.find((prev) => prev.id === row)
                                ?.created_at
                            ).format("DD/MM/YYYY hh:mm A")
                          : "-  -  -"}
                      </TableCell>
                      <TableCell>
                        {!!blackData.find((prev) => prev.id === row)?.updated_at
                          ? moment(
                              blackData.find((prev) => prev.id === row)
                                ?.updated_at
                            ).format("DD/MM/YYYY hh:mm A")
                          : "-  -  -"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            mx: 3,
            mb: 2,
          }}
        >
          <Button
            disabled={addingDebtor}
            color="primary"
            onClick={handleCloseModalDelete}
          >
            Cancelar
          </Button>
          <Button
            disabled={addingDebtor}
            variant="contained"
            color="error"
            onClick={removeDebtorArrayList}
            autoFocus
          >
            Eliminar
          </Button>
        </DialogActions>
      </ModalStyled>
      <SnackbarStyled
        message={messageError?.message || undefined}
        type={messageError?.type || undefined}
        key={JSON.stringify(messageError)}
        open={open}
        onClose={handleClose}
      />
    </>
  );
};

export default Container;
