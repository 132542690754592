import axios from "axios"

const apiAuthUrl = process.env.REACT_APP_API_AUTH_URL || "https://api.fapro.app"

// const localStorage.getItem('fapro_token') = localStorage.getItem('fapro_token')

export const emailApiGetData = async ({
    signal
}) => {

    try {
        
        const res = await axios(
            {
                method:'get', 
                url:`${apiAuthUrl}/api/v1.0/mail-notifications`, 
                headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`},
                signal
            }
        )

        const data = await res.data

        if (data.code === 1) {
            return {
                fapro: {
                    success: true,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: data.data
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: null
                }
            }
        }

    } catch (err) {
        if (err.response) {
            return {
                fapro: {
                    success: false,
                    code: err.response.data.code,
                    message: err.response.data.message,
                    status: err.response.data.status,
                    data: null
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: 500,
                    message: err.message || 'Opps! Algo salió mal, intente más tarde.',
                    status: 'error',
                    data: null
                }
            }
        }
    }
}


export const emailApiUpdateEmail = async ({
    new_email,
    email,
    id,
}) => {

    try {
        
        const res = await axios(
            {
                method:'put', 
                url:`${apiAuthUrl}/api/v1.0/mail-notifications/${id}`, 
                headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`},
                data: {email: new_email, last_email: email}
            }
        )

        const data = await res.data

        if (data.code === 1) {
            return {
                fapro: {
                    success: true,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: data.data
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: null
                }
            }
        }

    } catch (err) {
        // throw new Error(err.response.data.message)
        if (err.response) {
            return {
                fapro: {
                    success: false,
                    code: err.response.data.code,
                    message: err.response.data.message,
                    status: err.response.data.status,
                    data: null
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: 500,
                    message: err.message || 'Opps! Algo salió mal, intente más tarde.',
                    status: 'error',
                    data: null
                }
            }
        }
    }
}


export const emailApiRemoveEmail = async ({
    id,
    email
}) => {

    try {
        
        const res = await axios(
            {
                method:'delete', 
                url: `${apiAuthUrl}/api/v1.0/mail-notifications/${id}/${email}`, 
                headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`},
            }
        )

        const data = await res.data

        if (data.code === 1) {
            return {
                fapro: {
                    success: true,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: id
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: null
                }
            }
        }

    } catch (err) {
        // throw new Error(err.response.data.message)
        if (err.response) {
            return {
                fapro: {
                    success: false,
                    code: err.response.data.code,
                    message: err.response.data.message,
                    status: err.response.data.status,
                    data: null
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: 500,
                    message: err.message || 'Opps! Algo salió mal, intente más tarde.',
                    status: 'error',
                    data: null
                }
            }
        }
    }
}


export const emailApiNewEmail = async ({
    email
}) => {

    try {
        
        const res = await axios(
            {
                method:'post', 
                url: `${apiAuthUrl}/api/v1.0/mail-notifications`,
                data: {email}, 
                headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`},
            }
        )

        const data = await res.data

        if (data.code === 1) {
            return {
                fapro: {
                    success: true,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: data.data
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: null
                }
            }
        }

    } catch (err) {
        // throw new Error(err.response.data.message)
        if (err.response) {
            return {
                fapro: {
                    success: false,
                    code: err.response.data.code,
                    message: err.response.data.message,
                    status: err.response.data.status,
                    data: null
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: 500,
                    message: err.message || 'Opps! Algo salió mal, intente más tarde.',
                    status: 'error',
                    data: null
                }
            }
        }
    }
}























export const emailApiGetDataEmailMailchimp = async ({
    signal
}) => {

    try {
        
        const res = await axios(
            {
                method:'get', 
                url:`${apiAuthUrl}/api/v1.0/mailchimp/view`, 
                headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`},
                signal
            }
        )

        const data = await res.data

        if (data.code === 200) {
            return {
                fapro: {
                    success: true,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: data.data
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: null
                }
            }
        }

    } catch (err) {
        if (err.response) {
            return {
                fapro: {
                    success: false,
                    code: err.response.data.code,
                    message: err.response.data.message,
                    status: err.response.data.status,
                    data: null
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: 500,
                    message: err.message || 'Opps! Algo salió mal, intente más tarde.',
                    status: 'error',
                    data: null
                }
            }
        }
    }
}


export const emailApiUpdateEmailMailchimp = async ({
    email,
    last_email,
    id
}) => {

    try {
        
        const res = await axios(
            {
                method:'patch', 
                url:`${apiAuthUrl}/api/v1.0/mailchimp/update`, 
                headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`},
                data: {email: last_email, new_email: email}
            }
        )

        const data = await res.data

        if (data.code === 200) {
            return {
                fapro: {
                    success: true,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: {
                        email,
                        subscriber_id: id
                    }
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: null
                }
            }
        }

    } catch (err) {
        // throw new Error(err.response.data.message)
        if (err.response) {
            return {
                fapro: {
                    success: false,
                    code: err.response.data.code,
                    message: err.response.data.message,
                    status: err.response.data.status,
                    data: null
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: 500,
                    message: err.message || 'Opps! Algo salió mal, intente más tarde.',
                    status: 'error',
                    data: null
                }
            }
        }
    }
}

export const emailApiRemoveEmailMailchimp = async ({
    id,
    email,
    subscriber_id
}) => {

    try {
        
        const res = await axios(
            {
                method:'delete', 
                url: `${apiAuthUrl}/api/v1.0/mailchimp/remove`, 
                headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`},
                data: { 
                    subscribers: [
                        { 
                            email,
                            subscriber_id
                        }
                    ] 
                }
            }
        )

        const data = await res.data

        if (data.code === 200) {
            return {
                fapro: {
                    success: true,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: subscriber_id
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: null
                }
            }
        }

    } catch (err) {
        // throw new Error(err.response.data.message)
        if (err.response) {
            return {
                fapro: {
                    success: false,
                    code: err.response.data.code,
                    message: err.response.data.message,
                    status: err.response.data.status,
                    data: null
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: 500,
                    message: err.message || 'Opps! Algo salió mal, intente más tarde.',
                    status: 'error',
                    data: null
                }
            }
        }
    }
}

export const emailApiRemoveEmailMassiveMailchimp = async (payload) => {

    try {
        
        const res = await axios(
            {
                method:'delete', 
                url: `${apiAuthUrl}/api/v1.0/mailchimp/remove`, 
                headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`},
                data: { 
                    subscribers: payload
                }
            }
        )

        const data = await res.data

        if (data.code === 200) {
            return {
                fapro: {
                    success: true,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: payload
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: null
                }
            }
        }

    } catch (err) {
        // throw new Error(err.response.data.message)
        if (err.response) {
            return {
                fapro: {
                    success: false,
                    code: err.response.data.code,
                    message: err.response.data.message,
                    status: err.response.data.status,
                    data: null
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: 500,
                    message: err.message || 'Opps! Algo salió mal, intente más tarde.',
                    status: 'error',
                    data: null
                }
            }
        }
    }
}
