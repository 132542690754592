import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { message } from "antd";
import { useDropzone } from "react-dropzone";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { UploadOutlined } from "@ant-design/icons";
import { fileToBase64 } from "../../helpers/encode";
import { useField } from "formik";
import AddIcon from "@mui/icons-material/Add";

export default function InputFileStyled({
  label,
  actionLabel,
  name,
  style = {},
  fullWidth = false,
  maxFiles = 1,
  disabled = false,
  accept = {
    "application/x-pkcs12": [".pfx"],
  },
  messageError,
  dragLabel = "Arrastre y suelte los archivos aquí",
  showButton = true,
  ...rest
}) {
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);

  const [info, meta, helpers] = useField(name);

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: maxFiles,
    accept: accept,
    onDrop: async (acceptedFiles) => {
      setLoading(true);
      let encodes = await Promise.all(
        acceptedFiles.map((file) => fileToBase64(file))
      );

      setLoading(false);
      helpers.setValue(
        acceptedFiles.map((file, index) => ({
          name: file.name,
          encode: encodes[index],
          file: file,
        }))
      );
    },
    onDropRejected: (rejectedFiles) => {
      messageApi.error(messageError);
    },
    disabled: disabled || loading,
    onError: (error) => {
      console.log(error);
    },
    maxSize: 10000000,
    ...rest,
  });

  return (
    <FormControl
      sx={{
        alignItems: "start",
        p: 0,
        justifyContent: "center",
        m: 0,
        ...style,
      }}
      fullWidth={fullWidth}
      className="dropzone"
    >
      {contextHolder}
      {label && <Typography variant="subtitle1">{label}</Typography>}

      <input {...getInputProps()} />
      {showButton && (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          disabled={disabled}
          startIcon={<UploadOutlined />}
          {...getRootProps()}
        >
          {actionLabel}
        </Button>
      )}
      <Grid
        {...getRootProps()}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        mt={1}
        sx={{
          borderRadius: "5px",
          padding: "1rem",
          color: "#bdbdbd",
          height: "10rem",
          width: "100%",
          cursor: "pointer",
          position: "relative",
          border:
            meta.touched && meta.error
              ? "0.5px dashed #f44336"
              : "0.5px dashed #005886",
        }}
      >
        <Button
          sx={{
            position: "absolute",
            right: 7,
            bottom: 7,
            width: "1.7rem",
            height: "1.7rem",
            maxWidth: "1.7rem",
            maxHeight: "1.7rem",
            minWidth: "1.7rem",
            minHeight: "1.7rem",
            fontSize: "1rem",
            borderRadius: "50%",
            p: 0,
          }}
          color="primary"
          variant="contained"
        >
          <AddIcon />
        </Button>
        {info.value.length > 0 ? (
          <>
            {info.value.map((file, index) => (
              <Grid
                key={file.encode}
                item
                xs={12}
                sm={8}
                md={6}
                lg={4}
                xl={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  border: "0.5px solid #bdbdbd",
                  borderRadius: "5px",
                  padding: "1rem",
                  flexDirection: "column",
                  position: "relative",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (file.file.type === "application/pdf") {
                    const path = URL.createObjectURL(file.file);
                    window.open(path, "_blank");
                  }
                }}
              >
                <IconButton
                  sx={{
                    position: "absolute",
                    top: "0rem",
                    right: "0rem",
                    color: "#bdbdbd",
                  }}
                  fontSize="small"
                  color="error"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    helpers.setValue(info.value.filter((_, i) => i !== index));
                  }}
                  disabled={disabled}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
                <InsertDriveFileOutlinedIcon fontSize="large" />
                <Box
                  sx={{
                    fontSize: "0.8rem",
                    textAlign: "center",
                    width: "100%",
                  }}
                  className="text-limit-1line"
                >
                  {file.name}
                </Box>
              </Grid>
            ))}
          </>
        ) : (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box>
              <Typography
                variant="body2"
                color={meta.touched && meta.error ? "error" : "text.muted"}
                component="p"
              >
                {dragLabel}
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
      {meta.touched && meta.error && (
        <FormHelperText
          sx={{
            color: "#f44336",
          }}
        >
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
}
