import React from "react";
import { ModalStyled } from "../ui/ModalStyled";
import { Box, Button, Typography } from "@mui/material";
import { Formik } from "formik";
import { InputStyled } from "../ui/InputStyled";
import { authSliceLogoutThunk } from "../../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  emailSliceUpdateDataEmailMailchimpThunk,
} from "../../redux/slices/emailSlice";

export const EmailModalEditMailchimp = ({ show, onShow, data }) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const handleOnSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    dispatch(emailSliceUpdateDataEmailMailchimpThunk(values))
      .unwrap()
      .then(() => {
        onShow(null);
        resetForm();
      })
      .catch((err) => {
        if (err?.code === 401) {
          setTimeout(() => {
            dispatch(authSliceLogoutThunk()).finally(() => {
              history.replace("/");
            });
          }, 3000);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <ModalStyled show={show} onShow={onShow}>
      <Box
        sx={{
          width: "100%",
          maxWidth: 650,
          bgcolor: "background.terciary",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
        className="md-scroll"
      >
        <Typography variant="h6" gutterBottom component="h6">
          Edición de Correo Electrónico
        </Typography>
        <Typography>
          Este modal te permite editar un correo electrónico que ya has creado.
          Puedes cambiar el destinatario antes de enviarlo.
        </Typography>
        <Formik
          initialValues={{
            ...data,
            last_email: data.email,
          }}
          onSubmit={handleOnSubmit}
        >
          {({ isSubmitting, handleSubmit }) => (
            <>
              <Box
                component={"form"}
                onSubmit={handleSubmit}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                  mt: 3,
                }}
              >
                <InputStyled
                  fullWidth
                  disabled={isSubmitting}
                  name="email"
                  type="email"
                  label="Correo electrónico"
                  placeholder="ejemplo@fapro.app"
                  inputProps={{
                    autoComplete: "off",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: 2,
                    width: "100%",
                    mt: 2,
                  }}
                >
                  <Button
                    disabled={isSubmitting}
                    // variant="contained"
                    color="error"
                    type="button"
                    onClick={() => onShow(null)}
                  >
                    Cancelar
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Editar
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Formik>
      </Box>
    </ModalStyled>
  );
};
