import React, { useState } from "react";
import { useField } from "formik";
import TextField from "@mui/material/TextField";
import { IconButton, InputAdornment, useTheme } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export const InputStyled = ({
  name,
  label,
  disabled = false,
  type = "text",
  isPassword = false,
  inputProps = {},
  sx,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);
  const theme = useTheme();

  const handleOnChange = (e) => {
    helpers.setValue(e.target.value);
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <TextField
        {...field}
        {...rest}
        disabled={disabled}
        onChange={handleOnChange}
        onBlur={helpers.setTouched}
        error={meta.touched && meta.error ? true : false}
        helperText={meta.touched && meta.error ? meta.error : null}
        label={label}
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        sx={[
          {
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor:
                  meta.touched && meta.error
                    ? theme.palette.error.main
                    : theme.palette.background.lilacLines,
                borderWidth: 1.5,
              },
            },
            "& .MuiInputLabel-root": {
              color: theme.palette.text.purple,
            },
            "& .MuiFormHelperText-root": {
              color: theme.palette.text.secondary,
            },
          },
        ]}
        InputProps={{
          ...inputProps,
          endAdornment: isPassword && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
                style={{ color: theme.palette.background.paper }}
              >
                {showPassword ? (
                  <VisibilityOff
                    sx={{ color: theme.palette.background.paper }}
                  />
                ) : (
                  <Visibility sx={{ color: theme.palette.background.paper }} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};
