import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { ModalStyled } from "../ui/ModalStyled";

export const HelpModal = ({ show, onShow }) => {
  return (
    <ModalStyled
      show={show}
      onShow={() => onShow()}
      title={"Ayuda y soporte Fapro"}
      titleBgColor="primary.main"
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 650,
          bgcolor: "background.terciary",
          boxShadow: 24,
          p: 4,
          pt: 3,
          borderRadius: 2,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
        className="sidenav-scroll"
      >
        <Typography textAlign={"justify"} >
          <Typography variant="bold">
          ¿Necesitas ayuda o quieres hablar con un ejecutivo de atención al
          cliente? 
          </Typography >
          ¡Estamos aquí para ayudarte! <br />
          Puedes contactarnos a través de{" "}
          <Typography
            component={"a"}
            fontWeight={600}
            sx={{
              textDecoration: "underline",
            }}
            color={"text.primary"}
            href="mailto:hola@fapro.app"
          >
            hola@fapro.app
          </Typography>{" "}
          o llamando a nuestro número de celular al +56 9 9070 6241. Estaremos
          encantados de resolver cualquier duda o problema que tengas. <br />  <br />
          ¡Gracias por confiar en nosotros!
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 2,
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            color="info"
            type="submit"
            onClick={() => onShow()}
          >
            Cerrar
          </Button>
        </Box>
      </Box>
    </ModalStyled>
  );
};
