import axios from "axios"

const apiAuthUrl = process.env.REACT_APP_API_AUTH_URL || "https://api.fapro.app"

// const localStorage.getItem('fapro_token') = localStorage.getItem('fapro_token')

export const pwbApiGetData = async ({
    signal
}) => {

    try {
        
        const res = await axios(
            {
                method:'post', 
                url:`${apiAuthUrl}/api/pbi/data_embed`, 
                headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`},
                signal
            },
        )

        const data = await res.data

        if (data.code === 200) {
            return {
                fapro: {
                    success: true,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: data.data
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: null
                }
            }
        }

    } catch (err) {

        if (err.response) {
            return {
                fapro: {
                    success: false,
                    code: err.response.data.code,
                    message: err.response.data.message,
                    status: err.response.data.status,
                    data: null
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: 500,
                    message: err.message || 'Opps! Algo salió mal, intente más tarde.',
                    status: 'error',
                    data: null
                }
            }
        }
    }
}
