import React from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import AppRouter from './router/AppRouter';
import { ThemeContextProvider, useTheme } from './context/ThemeContext';
import { RefinerFeedback } from './redux/refiner/RefinerFeedback.js';

const AppContent = () => {
  const { theme } = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={4} preventDuplicate>
        <AppRouter />
        <RefinerFeedback />
      </SnackbarProvider>
    </ThemeProvider>
  );
};

const App = () => {
  return (
    <ThemeContextProvider>
      <AppContent />
    </ThemeContextProvider>
  );
};

export default App;
