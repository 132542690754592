import { Autocomplete, TextField, useTheme } from "@mui/material";
import { useField } from "formik";
import React from "react";

export const AutoCompleteStyled = ({
  limitOptions = -1,
  label,
  placeholder,
  name,
  options = [],
  disabled,
  inputProps = {},
  sx,
  ...rest
}) => {

  const [info, meta, helpers] = useField(name);
  const theme = useTheme();

  return (
    <Autocomplete
      disabled={disabled}
      options={options}
      getOptionLabel={(option) => option}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            placeholder={placeholder}
            color= "secondary"
            helperText={meta.touched && meta.error && meta.error}
            error={meta.touched && meta.error}
            sx={[
              sx ? sx : {},
              {
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor:
                      meta.touched && meta.error ? "error.main" : "background.lilacLines",
                    borderWidth: 1.5,
                  },
                },
                "& .MuiInputLabel-root": {
                  color: theme.palette.text.purple,
                },
              },
            ]}
            inputProps={{
              ...params.inputProps,
              ...inputProps,
              onBlur: (e) => {
                if (
                  !!e.target.value &&
                  info.value.indexOf(e.target.value) === -1
                ) {
                  helpers.setValue([...info.value, e.target.value]);
                }
                params.inputProps.onChange({
                  target: {
                    value: "",
                  },
                });
              },
              value: params.inputProps.value,
              onChange: (e) => {
                params.inputProps.onChange(e);
              },
              onFocus: (e) => {
                params.inputProps.onFocus(e);
              },
            }}
          />
        );
      }}
      value={info.value}
      onChange={(event, newValue) => {
        helpers.setValue(newValue);
      }}
      disablePortal
      getOptionDisabled={() => {
        if (limitOptions === -1) {
          return false;
        } else {
          return info.value.length >= limitOptions;
        }
      }}
      multiple
      {...rest}
    />
  );
};
