import { Formik, ErrorMessage } from "formik";
import {
  Alert,
  Box,
  Button,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  authSelectorGetMessageForgotPassword,
  authSliceNewPasswordUSerThunk,
} from "../../redux/slices/authSlice";
import { Link, useHistory, useParams } from "react-router-dom";
import { resetPasswordSchema } from "../../utils/schemas";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconHand from "../assets/LoginMano.png";
import "../css/login.css";
import { useState } from "react";

const NewPass = () => {
  const [showPassword, setShowPassword] = useState(true);
  const [showRepeatPassword, setShowRepeatPassword] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();
  const messageForgotPassword = useSelector(
    authSelectorGetMessageForgotPassword
  );
  let user = JSON.parse(atob(useParams().id));

  const handleOnSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    dispatch(
      authSliceNewPasswordUSerThunk({
        ...values,
        email: user.email,
        token: user.token,
      })
    )
      .unwrap()
      .then(() => {
        setTimeout(() => {
          history.push("/");
        }, 3000);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handlePasswordFocus = (e, setFieldValue, field) => {
    setFieldValue(field, "");
    field === "password"
      ? setShowPassword(false)
      : setShowRepeatPassword(false);
  };

  const handlePasswordBlur = (e, setFieldValue, field, defaultText) => {
    if (e.target.value === "") {
      setFieldValue(field, defaultText);
      field === "password"
        ? setShowPassword(true)
        : setShowRepeatPassword(true);
    }
  };

  const handlePasswordChange = (e, handleChange, field) => {
    handleChange(e);
    field === "password"
      ? setShowPassword(false)
      : setShowRepeatPassword(false);
  };

  const togglePasswordVisibility = (field) => {
    field === "password"
      ? setShowPassword(!showPassword)
      : setShowRepeatPassword(!showRepeatPassword);
  };

  return (
    <Box className="login-form">
      <Box className="icon-container">
        <img src={IconHand} alt="Hand Icon" className="hand-icon" />
      </Box>
      <Typography variant="h2">Recuperar Contraseña</Typography>
      <Box sx={{ marginBottom: "42px" }}>
        <Typography
          variant="body1"
          component="p"
          align="center"
          color="text.muted"
          gutterBottom
        >
          Ingresa tu nueva contraseña.
        </Typography>
      </Box>
      <Formik
        initialValues={{
          password: "Nueva contraseña",
          repeat_password: "Confirmar contraseña",
        }}
        onSubmit={handleOnSubmit}
        validationSchema={resetPasswordSchema}
      >
        {({
          isSubmitting,
          handleSubmit,
          handleChange,
          values,
          setFieldValue,
          touched,
          errors,
        }) => (
          <Box
            component="form"
            onSubmit={handleSubmit}
            className="form"
            autoComplete="off"
            noValidate
          >
            <Box
              className="input-container"
              sx={{ width: "50%", marginBottom: "32px" }}
            >
              <TextField
                variant="standard"
                value={values.password}
                inputProps={{ autoComplete: "off" }}
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                onChange={(e) =>
                  handlePasswordChange(e, handleChange, "password")
                }
                onFocus={(e) =>
                  handlePasswordFocus(e, setFieldValue, "password")
                }
                onBlur={(e) => handlePasswordBlur(e, setFieldValue, "password")}
                disabled={isSubmitting}
                sx={{
                  width: "100%",
                  padding: "4px",
                }}
                error={touched.password && Boolean(errors.password)}
                helperText={<ErrorMessage name="password" className="error" />}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => togglePasswordVisibility("password")}
                        onMouseDown={(e) => e.preventDefault()}
                        style={{ color: "#f2f2f2" }} 
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              className="input-container"
              sx={{ width: "50%", marginBottom: "32px" }}
            >
              <TextField
                variant="standard"
                value={values.repeat_password}
                inputProps={{ autoComplete: "off" }}
                id="repeat_password"
                name="repeat_password"
                type={showRepeatPassword ? "text" : "password"}
                onChange={(e) =>
                  handlePasswordChange(e, handleChange, "repeat_password")
                }
                onFocus={(e) =>
                  handlePasswordFocus(e, setFieldValue, "repeat_password")
                }
                onBlur={(e) =>
                  handlePasswordBlur(e, setFieldValue, "repeat_password")
                }
                disabled={isSubmitting}
                sx={{
                  width: "100%",
                  padding: "4px",
                }}
                error={
                  touched.repeat_password && Boolean(errors.repeat_password)
                }
                helperText={
                  <ErrorMessage name="repeat_password" className="error" />
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          togglePasswordVisibility("repeat_password")
                        }
                        onMouseDown={(e) => e.preventDefault()}
                        style={{ color: "#f2f2f2" }} 
                      >
                        {showRepeatPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              className="submit-button"
              sx={{ maxWidth: "180px" }}
            >
              Restablecer Contraseña
            </Button>
            {messageForgotPassword.active && (
              <Alert severity={messageForgotPassword.type}>
                {messageForgotPassword.message}
              </Alert>
            )}
          </Box>
        )}
      </Formik>
      <Link to="/" className="forgot-password">
        <Typography variant="link">Regresar</Typography>
      </Link>
    </Box>
  );
};

export default NewPass;
