import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
// import { Button } from "antd";
import './css/style.css';
import { useEffect } from 'react';
import { Box, Fab } from '@mui/material';

import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';

const PowerBi = ({embedData}) => {
    const { embed_token, embed_url, report_id } = embedData
    const handle = useFullScreenHandle();

    useEffect(() => {
        window.scrollTo(0, 100);
    }, []);

    return(
        <Box
            sx={{
                width: '100%',
                height: '94vh',
                maxHeight: '94vh',
                position: 'relative',
                m: 0,
                bgcolor: "transparent",
            }}
        >
            <FullScreen 
                handle={handle}
                className="fullscreen"
            >
                <Fab 
                    aria-label="fullscreen"
                    sx={{
                        position: 'absolute',
                        top: "1.5rem",
                        right: "1.5rem",
                        zIndex: 100,
                    }}
                    color='primary'
                    size='medium'
                    onClick={handle.active ? handle.exit : handle.enter}
                >
                    {
                        handle.active ? <FullscreenExitOutlinedIcon fontSize="large" /> : <FullscreenOutlinedIcon fontSize="large" />
                        
                    }
                </Fab>
                <PowerBIEmbed
                    embedConfig = {{
                        type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                        id: `${report_id}`,
                        embedUrl: `${embed_url}`,
                        accessToken: `${embed_token}`,
                        tokenType: models.TokenType.Embed,
                        settings: {
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: false
                                }
                            },
                            background: models.BackgroundType.Transparent,
                        },
                    }}
                    eventHandlers = { 
                        new Map([
                            ['loaded', function () {console.log('Report loaded');}],
                            ['rendered', function () {console.log('Report rendered');}],
                            ['error', function (event) {console.log(event.detail);}]
                        ])
                    }
                    cssClassName = { "report-style-class" }
                    getEmbeddedComponent = { (embeddedReport) => {
                        window.report = embeddedReport;
                    }}
                />  
            </FullScreen>
        </Box>
    )
}

export default PowerBi;