import { Box, Button } from "@mui/material";
import React from "react";
import { DataGridPaginationStyled } from "../ui/DataGridPaginationStyled";
import { useSelector } from "react-redux";
import { downloadSelectorGetRowSelected } from "../../redux/slices/downloadSlice";
import { useState } from "react";
import { DownloadModalEnableRecords } from "./DownloadModalEnableRecords";
import { DownloadModalDisableRecords } from "./DownloadModalDisableRecords";
import { Formik } from "formik";
import DownloadButtonEnableMassiveInputFile from "./DownloadEnableMassiveButtonInputFile";
import DownloadButtonDisableMassiveInputFile from "./DownloadButtonDisableMassiveInputFile";
import { useTheme } from "../../context/ThemeContext";

export const DownloadDataGridCustomPagination = (props) => {
  const rowSelected = useSelector(downloadSelectorGetRowSelected);
  const [showEnable, setShowEnable] = useState(false);
  const [showDisable, setShowDisable] = useState(false);
  const handleShowEnable = () => setShowEnable(!showEnable);
  const handleShowDisable = () => setShowDisable(!showDisable);
  const { isNewMode } = useTheme();

  return (
    <>
      <Formik
        initialValues={{
          massiveEnable: [],
          massiveDisable: [],
        }}
      >
        {({ values }) => (
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            px={2}
            py={1}
          >
            <Box
              display={"flex"}
              flex={1}
              flexDirection={"column"}
              alignItems={"flex-start"}
              flexWrap={"wrap"}
              justifyContent={"center"}
              gap={1}
            >
              <Button
                size="small"
                variant="contained"
                disabled={!rowSelected.length}
                onClick={handleShowEnable}
                sx={
                  isNewMode
                    ? {
                        width: "182px",
                        height: "41px",
                        boxShadow: "none",
                        backgroundColor: "background.purple",
                        border: `2px solid inherit`,
                        "&:hover": {
                          backgroundColor: "background.white",
                          border: `2px solid #b9a7ff`,
                        },
                      }
                    : { color: "success" }
                }
              >
                Habilitar
              </Button>

              <DownloadButtonEnableMassiveInputFile
                name={"massiveEnable"}
                actionLabel={"Habilitar por archivo"}
                buttonProps={{
                  sx: isNewMode
                    ? {
                        width: "182px",
                        height: "41px",
                        boxShadow: "none",
                        backgroundColor: "#efe3db",
                        border: `2px solid #b9a7ff`,
                        "&:hover": {
                          backgroundColor: "#fbfcf7",
                          border: `2px solid #b9a7ff`,
                        },
                      }
                    : { color: "success" },
                }}
                accept={{
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                    [".xlsx"],
                }}
              />
            </Box>
            <DataGridPaginationStyled
              {...props}
              labelDisplayedRows={() => {
                return "";
              }}
            />
            <Box
              display={"flex"}
              flex={1}
              flexDirection={"column"}
              alignItems={"flex-end"}
              justifyContent={"center"}
              flexWrap={"wrap"}
              gap={1}
            >
              <Button
                size="small"
                variant="contained"
                disabled={!rowSelected.length}
                onClick={handleShowDisable}
                sx={
                  isNewMode
                    ? {
                        width: "182px",
                        height: "41px",
                        boxShadow: "none",
                        backgroundColor: "background.red",
                        border: `2px rgba(223, 4, 56, 0.3)`,
                        "&:hover": {
                          backgroundColor: "background.white",
                          border: `2px solid rgba(223, 4, 56, 0.3)`,
                        },
                      }
                    : { color: "error" }
                }
              >
                Deshabilitar
              </Button>
              <DownloadButtonDisableMassiveInputFile
                name={"massiveDisable"}
                actionLabel={"Deshabilitar por archivo"}
                buttonProps={{
                  sx: isNewMode
                    ? {
                        width: "182px",
                        height: "41px",
                        boxShadow: "none",
                        backgroundColor: "#efe3db",
                        border: `2px solid #b9a7ff`,
                        "&:hover": {
                          backgroundColor: "#fbfcf7",
                          border: `2px solid #b9a7ff`,
                        },
                      }
                    : { color: "error" },
                }}
                accept={{
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                    [".xlsx"],
                }}
              />
            </Box>
          </Box>
        )}
      </Formik>
      <DownloadModalEnableRecords show={showEnable} onShow={handleShowEnable} />
      <DownloadModalDisableRecords
        show={showDisable}
        onShow={handleShowDisable}
      />
    </>
  );
};
