import { Box, Button, Card, Divider } from '@mui/material'
import { Formik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { authSliceLogoutThunk, authSliceUpdatePasswordUserThunk } from '../../redux/slices/authSlice'
import { editPasswordSchema } from '../../utils/schemas'
import { InputStyled } from '../ui/InputStyled'

export const ChangePasswordForm = () => {

    const history = useHistory()

    const dispatch = useDispatch()

    const handleOnSubmit = (
        values,
        {
            setSubmitting,
            resetForm,
            setStatus,
            setErrors
        }
    ) => {

        setSubmitting(true)
        dispatch(authSliceUpdatePasswordUserThunk(values))
            .unwrap()
            .then(() => {
                resetForm()
            })
            .catch((err) => {
                if(
                    err?.code === 401 
                ) {
                    setTimeout(() => {
                        dispatch(authSliceLogoutThunk())
                            .finally(() => {
                                history.replace('/')
                            })
                    }, 3000);
                }
            })
            .finally(() => {
                setSubmitting(false)
            })

    }

  return (
    <Formik
        initialValues={{
            current_password: '',
            password: '',
            repeat_password: ''
        }}
        onSubmit={handleOnSubmit}
        validationSchema={editPasswordSchema}
    >
        {
            ({
                handleSubmit,
                isSubmitting
            }) => (
                    <Box
                        component={"form"}
                        onSubmit={handleSubmit}
                        sx={{
                            display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 2,
                        }}
                    >
                        <InputStyled
                            fullWidth
                            disabled={isSubmitting}
                            name='current_password'
                            placeholder="********"
                            label="Contraseña Actual"
                            type='password'
                            isPassword={true}
                            inputProps={{
                                autoComplete: 'off',
                                form: {
                                  autoComplete: 'off',
                                },
                              }}
                        />
                        <Divider/>
                        <InputStyled
                            fullWidth
                            disabled={isSubmitting}
                            name='password'
                            placeholder="********"
                            label="Nueva Contraseña"
                            type='password'
                            isPassword={true}
                            inputProps={{
                                autoComplete: 'off',
                                form: {
                                  autoComplete: 'off',
                                },
                              }}
                        />
                        <InputStyled
                            fullWidth
                            disabled={isSubmitting}
                            name='repeat_password'
                            placeholder="********"
                            label="Repetir Contraseña"
                            type='password'
                            isPassword={true}
                            inputProps={{
                                autoComplete: 'off',
                                form: {
                                  autoComplete: 'off',
                                },
                              }}
                        />
                        <Button
                            disabled={isSubmitting}
                            type="submit"
                            variant="contained"
                            color='primary'
                            sx={{
                                // background: (theme) => theme.palette.gradient.main
                            }}
                            onClick={handleSubmit}
                        >
                            Guardar Nueva Contraseña
                        </Button>
                    </Box>
            )
        }
    </Formik>
  )
}
