import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import { useField } from "formik";
import React from "react";

export const CheckboxStyled = ({
  name,
  label,
  disabled = false,
  sxFormLabel = {},
  sxFormGroup = {},
  ...rest
}) => {
  const [info, meta, helpers] = useField(name);

  const handleChange = (event) => {
    if (event.target.name === "all") {
      if (event.target.checked) {
        helpers.setValue(
          info.value.map((option) => {
            return {
              ...option,
              checked: true,
            };
          })
        );
        return;
      } else {
        helpers.setValue(
          info.value.map((option) => {
            return {
              ...option,
              checked: false,
            };
          })
        );
        return;
      }
    } else {
      const allChecked = info.value.every((option) => option.checked);
      if (allChecked) {
        helpers.setValue(
          info.value.map((option) => {
            if (option.value === event.target.name) {
              return {
                ...option,
                checked: !option.checked,
              };
            }
            return option;
          })
        );
        return;
      } else {
        helpers.setValue(
          info.value.map((option) => {
            if (option.value === event.target.name) {
              return {
                ...option,
                checked: !option.checked,
              };
            }
            return option;
          })
        );
        return;
      }
    }
  };

  return (
    <FormControl
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        margin: 0,
      }}
      component="fieldset"
      variant="standard"
      {...rest}
    >
      <FormLabel
        component="legend"
        sx={{
          textAlign: "left",
          ...sxFormLabel,
        }}
        error={meta.touched && meta.error}
      >
        {label}
      </FormLabel>
      <FormGroup
        sx={{
          ...sxFormGroup,
        }}
      >
        {info.value.map((option) => (
          <FormControlLabel
            key={option.value} // Agrega la clave única aquí
            disabled={disabled}
            sx={{
              color: "text.muted",
              "& .MuiFormControlLabel-label": {
                fontSize: 14,
              },
            }}
            control={
              <Checkbox
                checked={option.checked}
                onChange={handleChange}
                name={option.value}
                sx={{
                  color: "background.pagination",
                  p: "5px 9px",
                  "&.Mui-checked": {
                    color: "primary.main",
                  },
                }}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
      {meta.touched && meta.error && (
        <FormHelperText
          sx={{
            color: "text.error",
          }}
        >
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
};
