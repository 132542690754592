import { useEffect } from "react";
import Form from "../../components/accounts/Form";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  authSelectorGetData,
  authSliceLogoutThunk,
} from "../../redux/slices/authSlice";
import {
  accountSelectorGetMessageError,
  accountSliceClearMessageError,
  accountSliceGetDataPermissionsThunk,
} from "../../redux/slices/accountSlice";
import { Box } from "@mui/material";
import CardStyled from "../../components/ui/CardStyled";
import { SnackbarStyled } from "../../components/ui/SnackbarStyled";
import { useState } from "react";

const Container = () => {
  const {
    user: { permissions: userPermissions, is_admin: isAdmin },
  } = useSelector(authSelectorGetData);

  const messageError = useSelector(accountSelectorGetMessageError);

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    dispatch(accountSliceClearMessageError());
  };

  const history = useHistory();

  useEffect(() => {
    if (
      isAdmin ||
      userPermissions.filter((data) => data.codename === "add_accounts")
        .length === 1
    ) {
    } else {
      history.push("/dashboard");
      message.error("No tienes permisos para entrar a este modulo", 4);
    }
  }, []);

  useEffect(() => {
    const promise = dispatch(accountSliceGetDataPermissionsThunk());

    promise.unwrap().catch((err) => {
      if (err?.code === 401) {
        setTimeout(() => {
          dispatch(authSliceLogoutThunk()).finally(() => {
            history.replace("/");
          });
        }, 3000);
      }
    });

    return () => {
      promise.abort();
    };
  }, []);

  useEffect(() => {
    if (!!messageError && !!messageError?.message) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [messageError]);

  useEffect(() => {
    dispatch(accountSliceClearMessageError());
    return () => {
      dispatch(accountSliceClearMessageError());
    };
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <SnackbarStyled
        message={messageError?.message || undefined}
        type={messageError?.type || undefined}
        key={JSON.stringify(messageError)}
        open={open}
        onClose={handleClose}
      />
      <CardStyled
        title={"Centro de Creación de Cuentas"}
        mx="auto"
        width={{
          xs: "100%",
          sm: "90%",
          md: "80%",
          lg: "80%",
          xl: "80%",
        }}
        maxWidth={650}
        maxHeight={"auto"}
        overflow="auto"
      >
        <Form />
      </CardStyled>
    </Box>
  );
};

export default Container;
