import { useContext, useEffect, useState } from 'react';
import Layout from '../../components/commons/PrivateLayoutStyled';
import Loader from '../../components/commons/Loader';
import FaproConext from "../../context/faproContext";
import Api from '../../helpers/api';
import { useHistory } from "react-router-dom";
import { Card } from 'antd';
import { message } from 'antd';
import Form from '../../components/massiveAccounts/Form';

const Container = () => {
    const { loading, handleError, handleLoading, resetMessages } = useContext(FaproConext);
    const [permission, setPermission] = useState([]);
    const [userPermissions, setUserPermissions] = useState(JSON.parse(localStorage.getItem('hitless-ER')));
    const [isAdmin, setIsAdmin] = useState(localStorage.getItem('hitless-ds3'))
    let history = useHistory();

    const getPermission = async () => {
        handleLoading(true);
        handleError(false);
        try {
            const res = await Api.faproGetPermission();
            setPermission(res.data.data);
        } catch (error) {
            handleError(error);
            message.error(error.message, 4)
        }finally{
            handleLoading(false);
        }
    }

    useEffect(() => {
        if((isAdmin === 'true' || userPermissions.filter(data => data.codename === "add_accounts").length === 1)){
            resetMessages();
            getPermission();
        }else{
            history.push('/dashboard');
            message.error('No tienes permisos para entrar a este modulo', 4);
        }
    }, []);

    return(
        <>
            { loading && <Loader /> }
            { !loading  && 
                <Layout>
                    <Card style={{ maxWidth: 800 }}>
                        <h2>Carga Masiva de Cuentas</h2>
                        <Form />
                    </Card>
                </Layout>
            }
        </>
    );
};


export default Container;