import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { ivaApiPostFile } from "../api/ivaApi";

const initialState = {
  data: null,
  messageError: null,
};

export const ivaSlicePostFileThunk = createAsyncThunk(
  "iva/postFile",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { fapro } = await ivaApiPostFile(payload);

      if (fapro.success) {
        return fapro.data;
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const ivaSlice = createSlice({
  name: "ivaSlice",
  initialState,
  reducers: {
    ivaSliceClearMessageError: (state, action) => {
      state.messageError = null;
    },
    ivaSliceClearData: (state, action) => {
      state.data = null;
    },
  },
  extraReducers: {
    [ivaSlicePostFileThunk.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.messageError = {
        type: "success",
        message: "Excel generado exitosamente",
      };
    },
    [ivaSlicePostFileThunk.rejected]: (state, action) => {
      state.data = null;
      if (action.payload?.code === 401) {
        state.messageError = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.messageError = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [ivaSlicePostFileThunk.pending]: (state, action) => {
      state.messageError = null;
      state.data = null;
    },
  },
});

export const ivaSelectorGetData = createSelector(
  ({ ivaSlice }) => ivaSlice.data,
  (data) => data
);

export const ivaSelectorGetMessageError = createSelector(
  ({ ivaSlice }) => ivaSlice.messageError,
  (data) => data
);

export const { ivaSliceClearMessageError, ivaSliceClearData } =
  ivaSlice.actions;
