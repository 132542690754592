import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

export const BackdropWaitingStyled = ({ loading = false }) => {
  return (
    <>
      {true && (
        <Backdrop
          open={loading}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
};
