import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { downloadApiDisable, downloadApiEnable, downloadApiGetData } from '../api/downloadApi';

const initialState = {
    data: {
        companies: [],
        branches: {},
        executives: {},
        groups: {},
        status: 'idle',
    },
    messageError : null,
    enableData: null,
    disableData: null,
    rowSelected: []
}

export const downloadSliceGetDataThunk = createAsyncThunk(
    'download/getData',
    async (
        _,
        {
            rejectWithValue,
            signal
        }
    ) => {
        try {

            const {
                fapro
            } = await downloadApiGetData({
                signal
            })

            if(fapro.success) {
                return fapro.data
            } else {
                return rejectWithValue(fapro)
            }
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const downloadSliceEnableThunk = createAsyncThunk(
    'download/enableData',
    async (
        payload,
        {
            rejectWithValue,
            dispatch
        }
    ) => {
        try {

            const {
                fapro
            } = await downloadApiEnable(payload)

            if(fapro.success) {
                return {
                    ...fapro,
                    message: payload.length>1
                    ? "Se habilita la descarga de las Empresas recibidas"
                    : "Se habilita la descarga de la Empresa" 
                }
            } else {
                return rejectWithValue(fapro)
            }
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const downloadSliceDisableThunk = createAsyncThunk(
    'download/disableData',
    async (
        payload,
        {
            rejectWithValue,
            dispatch
        }
    ) => {
        try {

            const {
                fapro
            } = await downloadApiDisable(payload)

            if(fapro.success) {
                return payload.length>1
                    ? "Se deshabilita la descarga de las Empresas recibidas"
                    : "Se deshabilita la descarga de la Empresa"    
            } else {
                return rejectWithValue(fapro)
            }
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)


export const downloadSlice = createSlice({
    name: 'downloadSlice',
    initialState,
    reducers: {
        downloadSliceClearMessageError: (state, action) => {
            state.messageError = null
        },
        downloadSliceResetRowSelected: (state, action) => {
            state.rowSelected = []
        },
        downloadSliceSetRowSelected: (state, action) => {
            state.rowSelected = action.payload
        },
        downloadSliceClearEnableData: (state, action) => {
            state.enableData = null
        }
    },
    extraReducers: {
        [downloadSliceGetDataThunk.fulfilled]: (state, action) => {
            state.data = {
                ...action.payload,
                status: 'success'
            }
        },
        [downloadSliceGetDataThunk.rejected]: (state, action) => {
            state.data = {
                companies: [],
                branches: {},
                executives: {},
                groups: {},
                status: 'error'
            }
            if(action.payload?.code === 401) {
                state.messageError = {
                    type: 'warning',
                    message: "Su sesión ha expirado, por favor vuelva a iniciar sesión."
                }
            } else {
                state.messageError = {
                    type: 'error',
                    message: action.payload.message
                }
            }
          
        },
        [downloadSliceGetDataThunk.pending]: (state, action) => {
            state.data = {
                companies: [],
                branches: {},
                executives: {},
                groups: {},
                status: 'loading'
            }
            // state.messageError = null
        },



        [downloadSliceEnableThunk.fulfilled]: (state, action) => {
            state.enableData = action.payload
            if(action.payload.data.error_credentials_ruts.length) {
                state.messageError = {
                    type: 'success',
                    message: "Se habilita la descarga para algunas Empresas. Para otras Empresas ocurrieron errores ir a detalles."
                }
            } else {
                state.messageError = {
                    type: 'success',
                    message: action.payload.message
                }
            }
        },
        [downloadSliceEnableThunk.rejected]: (state, action) => {
            if(action.payload?.code === 401) {
                state.messageError = {
                    type: 'warning',
                    message: "Su sesión ha expirado, por favor vuelva a iniciar sesión."
                }
            } else {
                state.enableData = action?.payload || null
                state.messageError = {
                    type: 'error',
                    message: action.payload.message
                }
            }
        },
        [downloadSliceEnableThunk.pending]: (state, action) => {
            state.enableData = null
            state.messageError = null
        },


        [downloadSliceDisableThunk.fulfilled]: (state, action) => {
            state.disableData = action.payload
            state.messageError = {
                type: 'success',
                message: action.payload
            }
        },
        [downloadSliceDisableThunk.rejected]: (state, action) => {
            state.disableData = null
            if(action.payload?.code === 401) {
                state.messageError = {
                    type: 'warning',
                    message: "Su sesión ha expirado, por favor vuelva a iniciar sesión."
                }
            } else {
                state.messageError = {
                    type: 'error',
                    message: action.payload.message
                }
            }
        },
        [downloadSliceDisableThunk.pending]: (state, action) => {
            state.enableData = null
            state.messageError = null
        },
    }
  }
)


export const downloadSelectorGetData = createSelector(
    ({
        downloadSlice
    }) => downloadSlice.data,
    (data) => data 
)

export const downloadSelectorGetDataStatus = createSelector(
    ({
        downloadSlice
    }) => downloadSlice.data.status,
    (data) => data 
)

export const downloadSelectorGetMessageError = createSelector(
    ({
        downloadSlice
    }) => downloadSlice.messageError,
    (data) => data 
)

export const downloadSelectorGetRowSelected = createSelector(
    ({
        downloadSlice
    }) => downloadSlice.rowSelected,
    (data) => data 
)

export const downloadSelectorGetEnableData = createSelector(
    ({
        downloadSlice
    }) => downloadSlice.enableData,
    (data) => data
)

export const {
    downloadSliceClearMessageError,
    downloadSliceResetRowSelected,
    downloadSliceSetRowSelected,
    downloadSliceClearEnableData
} = downloadSlice.actions



// downloadApiGetData