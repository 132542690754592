import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Ingresa un correo electrónico válido")
    .required("El correo electrónico es requerido"),
  password: yup.string().required("La contraseña es requerida"),
});

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("La contraseña es requerida")
    .min(8, "La contraseña debe tener al menos 8 caracteres"),
  repeat_password: yup
    .string()
    .required("La contraseña es requerida")
    .min(8, "La contraseña debe tener al menos 8 caracteres")
    .oneOf([yup.ref("password"), null], "Las contraseñas no coinciden"),
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email("Ingresa un correo electrónico válido")
    .required("El correo electrónico es requerido"),
});

export const linkRutPasswordSchema = yup.object().shape({
  name: yup.string().required("El nombre de la empresa es requerido"),
  rut: yup
    .string()
    .matches(/^([0-9]{7,8})-([0-9kK]{1})$/, "El RUT no es válido")
    .required("El RUT es requerido"),
  password: yup.string().required("La contraseña es requerida"),
});

export const linkCertificateSchema = yup.object().shape({
  name: yup.string().required("El nombre de la empresa es requerido"),
  rut: yup
    .string()
    .matches(/^([0-9]{7,8})-([0-9kK]{1})$/, "El RUT no es válido")
    .required("El RUT es requerido"),
  password: yup.string().required("La contraseña es requerida"),
  certificate: yup
    .array()
    .min(1, "El certificado es requerido")
    .required("El certificado es requerido"),
});

export const companyImportSchema = yup.object().shape({
  file: yup
    .array()
    .min(1, "El archivo es requerido")
    .required("El archivo es requerido"),
});

export const ivaImportSchema = yup.object().shape({
  file: yup
    .array()
    .min(1, "El archivo es requerido")
    .required("El archivo es requerido"),
});

export const createAccountSchema = yup.object().shape({
  name: yup.string().required("El nombre es requerido"),
  lastName: yup.string().required("El apellido es requerido"),
  email: yup
    .string()
    .email("Ingresa un correo electrónico válido")
    .required("El correo electrónico es requerido"),
  password: yup.string().required("La contraseña es requerida"),
  phone_number: yup
    .string()
    .matches(/^\d+$/, "El número de teléfono agregado no es válido")
    .test(
      "no-repeated-digits",
      "El número de teléfono agregado no es válido",
      (value) => {
        return !/(\d)\1{5}/.test(value);
      }
    )
    .test(
      "no-specific-sequences",
      "El número de teléfono agregado no es válido",
      (value) => {
        return !/(012345|123456|234567|345678|456789|098765|987654|876543|765432|654321)/.test(
          value
        );
      }
    )
    .min(9, "Debe tener al menos 9 caracteres")
    .max(15, "No puede tener más de 15 caracteres")
    .nonNullable()
    .required("El número de teléfono es requerido"),
  executive: yup.array(),
  group: yup.array(),
  branch: yup.array(),
  permissions: yup.array(),
});

export const editAccountSchema = yup.object().shape({
  name: yup.string().required("El nombre es requerido"),
  lastName: yup.string().required("El apellido es requerido"),
  phone_number: yup
    .string()
    .matches(/^\d+$/, "El número de teléfono agregado no es válido")
    .test(
      "no-repeated-digits",
      "El número de teléfono agregado no es válido",
      (value) => {
        return !/(\d)\1{5}/.test(value);
      }
    )
    .test(
      "no-specific-sequences",
      "El número de teléfono agregado no es válido",
      (value) => {
        return !/(012345|123456|234567|345678|456789|098765|987654|876543|765432|654321)/.test(
          value
        );
      }
    )
    .min(9, "Debe tener al menos 9 caracteres")
    .max(15, "No puede tener más de 15 caracteres")
    .nonNullable()
    .required("El número de teléfono es requerido"),
  executive: yup.array(),
  group: yup.array(),
  branch: yup.array(),
  permissions: yup.array(),
});

export const editUserSchema = yup.object().shape({
  name: yup.string().required("El nombre es requerido"),
  lastName: yup.string().required("El apellido es requerido"),
  phone: yup
    .string()
    .matches(/^\d+$/, "El número de teléfono agregado no es válido")
    .test(
      "no-repeated-digits",
      "El número de teléfono agregado no es válido",
      (value) => {
        return !/(\d)\1{5}/.test(value);
      }
    )
    .test(
      "no-specific-sequences",
      "El número de teléfono agregado no es válido",
      (value) => {
        return !/(012345|123456|234567|345678|456789|098765|987654|876543|765432|654321)/.test(
          value
        );
      }
    )
    .min(9, "Debe tener al menos 9 caracteres")
    .max(15, "No puede tener más de 15 caracteres")
    .nonNullable()
    .required("El número de teléfono es requerido"),
});

export const editPasswordSchema = yup.object().shape({
  current_password: yup.string().required("La contraseña actual es requerida"),
  password: yup
    .string()
    .required("La contraseña es requerida")
    .test(
      "passwords-match",
      "La contraseña nueva no puede ser igual a la actual",
      function (value) {
        return this.parent.current_password !== value;
      }
    )
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/,
      "La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un número"
    ),
  repeat_password: yup
    .string()
    .required("La contraseña es requerida")
    .oneOf([yup.ref("password"), null], "Las contraseñas no coinciden")
    .test(
      "passwords-match",
      "La contraseña nueva no puede ser igual a la actual",
      function (value) {
        return this.parent.current_password !== value;
      }
    )
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/,
      "La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un número"
    ),
});

export const createCustomFieldSchema = yup.object().shape({
  entity: yup.string().required("El tipo de entidad es requerido"),
  required: yup.string().required("El campo es requerido es requerido"),
  field_name: yup.string().required("El nombre del campo requerido"),
  field_type: yup.string().required("El tipo de campo es requerido")
});

export const emailValidationSchema = (domain) =>
  yup.object().shape({
    mails1: yup
      .string()
      .email()
      .test(
        "mail1",
        `El primer correo debe tener alguna de las siguientes extensiones: ${domain.join(
          ", "
        )}`,
        (value) => !value || domain.includes(value.split("@")[1]?.trim())
      ),
    mails2: yup
      .string()
      .email()
      .test(
        "mail2",
        `El segundo correo debe tener alguna de las siguientes extensiones: ${domain.join(
          ", "
        )}`,
        (value) => !value || domain.includes(value.split("@")[1]?.trim())
      ),
  });

export const generateValidationSchema = (customFields) => {
  const schemaFields = customFields.reduce((schema, field) => {
    if (field.required === "1") {
      if (field.field_type === "list") {
        schema[field.field_name] = yup
          .string()
          .required(`${field.field_name} es obligatorio`);
      } else if (field.field_type === "text" || field.field_type === "number") {
        schema[field.field_name] = yup
          .string()
          .required(`${field.field_name} es obligatorio`);
      }
    }
    return schema;
  }, {});

  return yup.object().shape(schemaFields);
};

export const getFullValidationSchema = (customFields, domain) => {
  return generateValidationSchema(customFields).concat(
    emailValidationSchema(domain)
  );
};
