import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isFeatureAvailable } from "../../helpers/utils"
import { authSelectorGetData, authSliceLogoutThunk } from '../../redux/slices/authSlice'
import { downloadSelectorGetData, downloadSelectorGetRowSelected, downloadSliceDisableThunk, downloadSliceGetDataThunk, downloadSliceResetRowSelected } from '../../redux/slices/downloadSlice'
import { ModalStyled } from '../ui/ModalStyled'

export const DownloadModalDisableRecords = ({
    show,
    onShow,
}) => {

    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()

    const history = useHistory()

    const rowSelected = useSelector(downloadSelectorGetRowSelected)
    const {
        companies
  } = useSelector(downloadSelectorGetData)

    const {
      user: { features },
    } = useSelector(authSelectorGetData);
    const [isDisableNowAvailable, setisDisableNowAvailable] = useState(false);

    useEffect(() => {
      setisDisableNowAvailable(
        isFeatureAvailable(features, "Permitir deshabilitar empresas antes de los 30 días")
      );
      }, [features]);

    const handleSubmit = () => {
        setLoading(true)
        const currentCompanies = companies.filter((val) => rowSelected.includes(val.id))
        dispatch(downloadSliceDisableThunk(currentCompanies))
            .unwrap()
            .then(() => {
                dispatch(downloadSliceResetRowSelected())
                dispatch(downloadSliceGetDataThunk())
                onShow()
            })
            .catch((err) => {
                if(
                err?.code === 401
                ) {
                    setTimeout(() => {
                        dispatch(authSliceLogoutThunk())
                            .finally(() => {
                                history.replace('/')
                            })
                    }, 3000);
                } else {
                  onShow()
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

  return (
    <ModalStyled
        show={show}
        onShow={() => onShow()}
        title={"Deshabilitar Empresas"}
    >
        <Box
          sx={{
            width: "100%",
            maxWidth: 650,
            bgcolor: "background.terciary",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
          className="sidenav-scroll"
        >
          {/* <Typography
            variant="h6"
            gutterBottom
            component="h6"
          >
            Deshabilitar la Descarga de Datos
          </Typography> */}
          <Typography gutterBottom textAlign="justify">
              {isDisableNowAvailable ? (
                  <>
                      La deshabilitación de la descarga se realizará de forma inmediata.
                      <br />
                      <br />
                  </>
              ) : (
                  <>
                      Toda empresa que tiene más de 30 días en el sistema se deshabilitará automáticamente.
                      <br />
                      Toda empresa que tiene menos de 30 días en el sistema se programará su deshabilitación pasados los 30 días después de su creación.
                      <br />
                      <br />
                  </>
          )}
          </Typography>
          <Typography gutterBottom textAlign={"justify"} variant="bold">
              ¿Seguro que deseas deshabilitar empresas?
          </Typography>
          <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: 2,
                width: '100%',
                mt: 3,
            }}
          >
            <Button
                // variant="contained"
                color='error'
                type="button"
                disabled={loading}
                onClick={() => onShow()}
            >
                Cancelar
            </Button>
            <Button
                variant="contained"
                color='primary'
                sx={{
                    // background: (theme) => theme.palette.gradient.main
                }}
                type="submit"
                disabled={loading}
                onClick={handleSubmit}
            >
                Deshabilitar Empresas
            </Button>
          </Box>
        </Box>
    </ModalStyled>
  )
}
