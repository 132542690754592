import React, { useState } from "react";
import { ModalStyled } from "../ui/ModalStyled";
import { Box, Button, Typography } from "@mui/material";
import { emailSliceRemoveDataThunk } from "../../redux/slices/emailSlice";
import { authSliceLogoutThunk } from "../../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

export const EmailsModalRemove = ({ show, onShow, data }) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [open, setOpen] = useState(false);

  const handleOnSubmit = () => {
    setOpen(true);
    dispatch(emailSliceRemoveDataThunk(data))
      .unwrap()
      .then(() => {
        onShow(null);
      })
      .catch((err) => {
        if (err?.code === 401) {
          setTimeout(() => {
            dispatch(authSliceLogoutThunk()).finally(() => {
              history.replace("/");
            });
          }, 3000);
        }
      })
      .finally(() => {
        setOpen(false);
      });
  };

  return (
    <ModalStyled
      show={show}
      onShow={onShow}
      title={"¿Eliminar Correo Electrónico?"}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 650,
          bgcolor: "background.terciary",
          boxShadow: 24,
          p: 4,
          pt: 3,
          borderRadius: 2,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
        className="md-scroll"
      >
        <Typography gutterBottom textAlign={"center"}>
          Al realizar esta accion no podras revertirla.
        </Typography>
        <Box
          display={"flex"}
          width={"100%"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          gap={2}
          alignItems={"center"}
          justifyContent={"flex-end"}
          mt={3}
        >
          <Button
            disabled={open}
            color="error"
            onClick={() => onShow(null)}
          >
            Cancelar
          </Button>
          <Button
            disabled={open}
            color="error"
            variant="contained"
            onClick={handleOnSubmit}
          >
            Eliminar
          </Button>
        </Box>
      </Box>
    </ModalStyled>
  );
};
