import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_AUTH_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});


const getAuthToken = () => `Bearer ${localStorage.getItem('fapro_token')}`;


const callApi = async ({ url, method, data = null, params = null, signal = null }) => {
  try {
    const response = await api({
      url,
      method,
      headers: {
        Authorization: getAuthToken(),
      },
      data,
      params,
      signal
    });

    if (response.status === 200) {
      return { 
        success: true, 
        data: response.data.data,
        message: response.data.message
      };
    } else {
      return { success: false, message: response.statusText, data: null };
    }
  } catch (error) {
    const message = error.response?.data?.message || error.message || 'Ha ocurrido un error, intente nuevamente más tarde.';
    return { success: false, message, data: null };
  }
};


export const configApiGetFeatures = async (signal) => {
  return callApi({
    url: '/api/v1.0/features',
    method: 'get',
    signal,
  });
};


export const configApiToggleFeature = async ({ codename, signal }) => {
  return callApi({
    url: '/api/v1.0/features',
    method: 'patch',
    data: { codename },
    signal,
  });
};
