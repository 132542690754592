import SidenavLayout from "./components/sidenav/SidenavLayout";
import { Box, Card } from "@mui/material";
import { OnboardingModal } from "../onboarding/OnboardingModal";

const PrivateLayoutStyled = ({ children }) => {
  return (
    <Box
      component={"main"}
      bgcolor={"transparent"}
      sx={{
        width: "100%",
        height: "100vh",
        minHeight: "100vh",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <OnboardingModal />
      <Box
        sx={{
          display: "flex",
          flex: "1 1",
          height: "100vh",
          bgcolor: "transparent",
          overflow: "auto",
        }}
      >
        <SidenavLayout />
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
            p: 2,
          }}
          className="main-layout"
          component={Card}
          bgcolor={"background.secondary"}
        >
          <Box
            component={"div"}
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "transparent",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                overflow: "auto",
                bgcolor: "transparent",
                position: "relative",
              }}
            >
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PrivateLayoutStyled;
