import { Box, Button, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { authSliceLogoutThunk } from "../../../../redux/slices/authSlice";
import { ModalStyled } from "../../../ui/ModalStyled";

export const ModalLogout = ({ show, onShow }) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const handleSubmit = () => {
    dispatch(authSliceLogoutThunk())
      .unwrap()
      .finally(() => {
        history.push("/");
      });
  };

  return (
    <ModalStyled show={show} onShow={() => onShow(null)} title="Cerrar Sesión">
      <Box
        sx={{
          width: "100%",
          maxWidth: 650,
          bgcolor: "background.terciary",
          boxShadow: 24,
          p: 4,
          pt: 3,
          borderRadius: 2,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
        className="sidenav-scroll"
      >
        <Typography  color="text.primary" >
          Si cierras sesión, tendrás que volver a iniciar sesión.
          <br />
        </Typography>
        <Typography  variant="bold">
          ¿Estas seguro de que deseas cerrar sesión?
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 2,
            width: "100%",
            mt: 3,
          }}
        >
          <Button color="error" type="button" onClick={() => onShow()}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="error"
            type="submit"
            onClick={handleSubmit}
          >
            Cerrar sesión
          </Button>
        </Box>
      </Box>
    </ModalStyled>
  );
};
