import {
  createAsyncThunk,
  createSelector,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import {
  getCustomFields,
  deleteCustomFields,
  postCustomFields,
  patchCustomFields,
  postCustomFieldCompany,
} from "../api/customFieldsApi";

export const customFieldsAdapter = createEntityAdapter({
  selectId: (item) => item.id,
  sortComparer: (a, b) => new Date(a.created_at) - new Date(b.created_at),
});

const initialState = {
  customFields: [],
  status: "idle",
  message: null,
};

export const getCustomFieldsThunk = createAsyncThunk(
  "config/getCustomFields",
  async (_, { rejectWithValue, signal }) => {
    try {
      const response = await getCustomFields({ signal });
      if (response.success) {
        return response.data;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postCustomFieldsThunk = createAsyncThunk(
  "config/postCustomFields",
  async (data, { rejectWithValue, signal }) => {
    try {
      const response = await postCustomFields({ data, signal });
      if (response.success) {
        return response.data;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const patchCustomFieldsThunk = createAsyncThunk(
  "config/patchCustomFields",
  async (data, { rejectWithValue, signal }) => {
    try {
      const response = await patchCustomFields({ data, signal });
      if (response.success) {
        return response.data;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postCustomFieldCompanyThunk = createAsyncThunk(
  "config/postCustomFieldCompany",
  async (data, { rejectWithValue, signal }) => {
    try {
      const response = await postCustomFieldCompany({ data, signal });
      if (response.success) {
        return response.data;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteCustomFieldsThunk = createAsyncThunk(
  "config/deleteCustomFields",
  async (data, { rejectWithValue, signal }) => {
    try {
      const response = await deleteCustomFields({
        customFieldID: data.id,
        signal,
      });
      if (response.success) {
        return response.code;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const customFieldsSlice = createSlice({
  name: "customFieldsSlice",
  initialState,
  reducers: {
    clearMessageError: (state) => {
      state.message = null;
    },
  },
  extraReducers: {
    [getCustomFieldsThunk.fulfilled]: (state, action) => {
      state.customFields = action.payload;
      state.status = "succeeded";
    },
    [getCustomFieldsThunk.rejected]: (state, action) => {
      state.status = "error";
      state.message = { message: action.payload.message, type: "error" };
    },
    [getCustomFieldsThunk.pending]: (state) => {
      state.status = "loading";
    },
    [deleteCustomFieldsThunk.fulfilled]: (state, action) => {
      state.message = {
        message: "Campo eliminado correctamente.",
        type: "success",
      };
    },
    [deleteCustomFieldsThunk.rejected]: (state, action) => {
      state.message = { message: action.payload.message, type: "error" };
    },

    [postCustomFieldsThunk.fulfilled]: (state, action) => {
      state.message = {
        message: "Campo agregado correctamente.",
        type: "success",
      };
    },
    [postCustomFieldsThunk.rejected]: (state, action) => {
      if (action.payload?.code === 401) {
        state.message = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.message = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [patchCustomFieldsThunk.pending]: (state, action) => {
      state.message = null;
    },
    [patchCustomFieldsThunk.fulfilled]: (state, action) => {
      state.message = {
        message: "Campo modificado correctamente.",
        type: "success",
      };
    },
    [patchCustomFieldsThunk.rejected]: (state, action) => {
      if (action.payload?.code === 401) {
        state.message = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.message = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [postCustomFieldCompanyThunk.pending]: (state, action) => {
      state.message = null;
    },
    [postCustomFieldCompanyThunk.fulfilled]: (state, action) => {
      state.message = {
        message: "Campo de la empresa modificado correctamente.",
        type: "success",
      };
    },
    [postCustomFieldCompanyThunk.rejected]: (state, action) => {
      if (action.payload?.code === 401) {
        state.message = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.message = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
  },
});

export const selectCustomFields = createSelector(
  (state) => state.customFieldsSlice.customFields,
  (customFields) => customFields
);

export const selectCustomFieldsStatus = createSelector(
  (state) => state.customFieldsSlice.status,
  (status) => status
);

export const selectMessageError = createSelector(
  (state) => state.customFieldsSlice.message,
  (message) => message
);

export const { clearMessageError } = customFieldsSlice.actions;
