import { Box, Button, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { DataGridPaginationStyled } from "../ui/DataGridPaginationStyled";
import { CustomFieldsModalCreate } from "./CustomFieldsModalCreate";

export const DataGridPaginationCustomFields = (props) => {
  const [newShow, setNewShow] = useState(false);
  const handleShowNew = () => {
    setNewShow(!newShow);
  };

  return (
    <>
      <Box className="data-grid-pagination-custom-fields">
        <Box className="data-grid-pagination-custom-fields__button">
          <Tooltip title="Crear campo personalizado">
            <Button
              type="button"
              onClick={handleShowNew}
              variant="contained"
              color="primary"
              size="small"
            >
              Agregar campo
            </Button>
          </Tooltip>
        </Box>
        <DataGridPaginationStyled {...props} />
      </Box>
      {newShow && (
        <CustomFieldsModalCreate show={newShow} onShow={handleShowNew} />
      )}
    </>
  );
};
