import { Formik } from "formik";
import InputFileStyled from "../ui/InputFileStyled";
import { InputStyled } from "../ui/InputStyled";
import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  linkCompaniesSliceClearMessageLinkPostDigital,
  linkCompaniesSlicePostDataDigital,
} from "../../redux/slices/linkCompaniesSlices";
import { linkCertificateSchema } from "../../utils/schemas";
import {
  authSelectorGetData,
  authSliceLogoutThunk,
} from "../../redux/slices/authSlice";
import { useHistory } from "react-router-dom";

const LoginForm = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { user } = useSelector(authSelectorGetData);

  const handleOnSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    dispatch(linkCompaniesSliceClearMessageLinkPostDigital());
    dispatch(
      linkCompaniesSlicePostDataDigital({
        ...values,
        certificate: values.certificate[0].encode,
      })
    )
      .unwrap()
      .then((payload) => {
        if (typeof payload === "string") {
        } else if (typeof payload === "object") {
          resetForm();
        }
      })
      .catch((err) => {
        if (err?.code === 401) {
          setTimeout(() => {
            dispatch(authSliceLogoutThunk()).finally(() => {
              history.replace("/");
            });
          }, 3000);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{
        name: "",
        rut: "",
        password: "",
        certificate: [],
        executive: user?.executive || "",
        branch: user?.branch || "",
        group: user?.group || "",
      }}
      onSubmit={handleOnSubmit}
      validationSchema={linkCertificateSchema}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            m: "auto",
          }}
        >
          <InputStyled
            disabled={isSubmitting}
            name="name"
            label="Nombre de la empresa"
            placeholder="Fapro"
            fullWidth
            inputProps={{
              autoComplete: "off",
              form: {
                autoComplete: "off",
              },
            }}
          />
          <InputStyled
            disabled={isSubmitting}
            name="rut"
            label="RUT del SII"
            placeholder="12345678-9"
            fullWidth
            inputProps={{
              autoComplete: "off",
              form: {
                autoComplete: "off",
              },
            }}
          />
          <InputStyled
            name="password"
            label="Contraseña"
            placeholder="********"
            type="password"
            fullWidth
            disabled={isSubmitting}
            isPassword
          />
          <InputFileStyled
            name="certificate"
            showButton={false}
            actionLabel="Click para cargar"
            fullWidth
            disabled={isSubmitting}
            messageError="Certificado no válido"
          />
          <Button
            color="primary"
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            Vincular empresa
          </Button>
        </Box>
      )}
    </Formik>
  );
};

export default LoginForm;
