import { createTheme } from "@mui/material/styles";
import shadows from "./shadows";

const colors = {
  primary: "#074641",
  secondary: "#7C4DFF",
  beige: "#efe3db",
  white: "#fbfcf7",
  darkGreen: "#074641",
  lightPurple: "#b9a7ff",
  darkText: "#042623",
  errorBorder: "#d30030",
  skyBlue: "#99c4d6",
  blank: "#ffffff",
  clearPurple: "#d4c8ff",
  lightGrey: "#f2f2f2",
  clearGrey: "#f9f9f9",
  greenYellow: "#c5e660",
};

const oldColors = {
  primary: "#005885",
  secondary: "#45b5d0",
  error: "#d30030",
  background: "#ffffff",
  paper: "#e5edf0",
  textPrimary: "#042623",
  textSecondary: "#666666",
  opaco: "rgba(102,102,102)",
  clearBlue: "#8fbacc",
};

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: oldColors.primary,
      contrastText: "rgba(255,255,255,0.87)",
    },
    secondary: {
      main: oldColors.secondary,
      contrastText: "rgba(255,255,255,0.87)",
    },
    terciary: {
      main: oldColors.primary,
      contrastText: "rgba(255,255,255,0.87)",
    },
    pagination: {
      main: oldColors.primary,
      contrastText: "rgba(255,255,255,0.87)",
    },
    default: {
      main: oldColors.secondary,
      contrastText: "rgba(255,255,255,0.87)",
    },
    checkBox: {
      main: oldColors.primary,
    },
    gradient: {
      main: "linear-gradient(to right, #a60847, #d30030)",
      contrastText: "rgba(255,255,255,1)",
      contrastTextDisabled: "rgba(255,255,255,0.87)",
    },
    dark: {
      main: "#005885",
    },
    light: {
      main: "#ffffff",
      dark: "#f9f9f9",
    },
    success: {
      main: "#1AA861",
      contrastText: "rgba(255,255,255,1)",
      lightFapro: "rgba(26, 168, 97, 0.3)",
    },
    error: {
      main: "#A81A55",
      lightFapro: "rgba(223, 4, 56, 0.3)",
      contrastText: "rgba(255,255,255,1)",
    },
    warning: {
      lightFapro: "rgba(223, 135, 4, 0.3)",
      main: "#febc2f",
      contrastText: "rgba(255,255,255,1)",
    },
    schedule: {
      main: "#A81A55",
      lightFapro: "rgba(223, 4, 56, 0.3)",
    },
    text: {
      faproPrimary: "#005885",
      faproSecondary: "#45b5d0",
      faproTertiary: "#4cb6d0",
      primary: "#005885",
      muted: "rgba(0,0,0,0.6)",
      white: "#ffffff",
      error: "#d60438",
      default: "#666666",
      perfil: "#005885",
    },
    icons: {
      principal: colors.skyBlue,
    },
    background: {
      primary: "#ffffff",
      secondary: "#e5edf0",
      terciary: colors.blank,
      paper: colors.blank,
      arrowMenu: oldColors.opaco,
      borderCard: colors.opaco,
      success: "#81c784",
      error: "#e57373",
      white: colors.blank,
      pagination: oldColors.clearBlue,
      oldClearBlue: oldColors.oldClearBlue,
      lilacLines: "#e5edf0",
    },
    info: {
      main: "#4CBFD9",
      secondary: "#4CBFD9",
      contrastText: "rgba(255,255,255,1)",
    },
  },
  typography: {
    allVariants: {
      color: "#666666",
    },
    bold: {
      fontFamily: "'Merriweather Sans', 'Roboto', Helvetica, sans-serif",
      fontSize: "12pt",
      fontWeight: 500,
    },
    fontFamily: "'Merriweather Sans', 'Roboto', Helvetica, sans-serif",
    button: {
      textTransform: "none",
      fontSize: "12pt",
      fontWeight: 600,
    },
    body2Link: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
      borderBottom: "1px solid transparent",
      boxSizing: "content-box",
      "&:hover": {
        borderBottom: "1px solid currentcolor",
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          borderRadius: 6,
          fontSize: 14,
          fontWeight: 600,
          letterSpacing: 0.2,
          padding: "6px 20px",
          boxShadow: "none",
          "&.Mui-disabled": {
            color: "rgba(255,255,255,0.87)",
            opacity: 0.5,
          },
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-colCellTitleContainer": {
            color: "#4cb8d2",
            fontSize: 16,
            fontWeight: 600,
          },
          "& .MuiDataGrid-colCellWrapper": {
            borderBottomWidth: 2,
            borderBottomColor: "#4cb8d2",
          },
          "& .MuiDataGrid-cell": {
            fontSize: 14,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.beige,
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.beige,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.beige,
            },
          },
        },
      },
    },
  },
});

export const newTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: colors.darkGreen,
      contrastText: colors.lightPurple,
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.white,
    },
    terciary: {
      main: colors.white,
      contrastText: "rgba(255,255,255,0.87)",
    },
    checkBox: {
      main: colors.clearPurple,
    },
    pagination: {
      main: colors.darkText,
      contrastText: colors.darkText,
    },
    default: {
      main: "#666666",
      contrastText: "rgba(255,255,255,0.87)",
    },
    gradient: {
      main: colors.lightPurple,
      contrastText: "rgba(255,255,255,1)",
      contrastTextDisabled: "rgba(255,255,255,0.87)",
    },
    dark: {
      main: "#005885",
    },
    light: {
      main: "#ffffff",
      dark: "#f9f9f9",
    },
    error: {
      main: colors.darkText,
      lightFapro: "rgba(223, 4, 56, 0.3)",
      contrastText: "rgba(255,255,255,1)",
    },
    warning: {
      main: colors.darkText,
      lightFapro: colors.white,
      contrastText: "rgba(255,255,255,1)",
    },
    success: {
      main: colors.darkText,
      lightFapro: colors.lightPurple,
      contrastText: "rgba(255,255,255,1)",
    },
    schedule: {
      main: colors.darkText,
      lightFapro: colors.greenYellow,
    },
    text: {
      faproPrimary: colors.white,
      faproSecondary: colors.darkText,
      faproTertiary: colors.white,
      primary: colors.darkText,
      secondary: colors.darkText,
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
      muted: "rgba(0,0,0,0.6)",
      white: "#ffffff",
      error: "#d60438",
      default: colors.darkText,
      perfil: colors.lightPurple,
      purple: colors.lightPurple,
    },
    background: {
      default: colors.white,
      paper: colors.beige,
      primary: colors.darkGreen,
      secondary: colors.white,
      terciary: colors.white,
      success: "#81c784",
      error: "#e57373",
      white: colors.white,
      arrowMenu: colors.white,
      borderCard: colors.beige,
      pagination: colors.lightGrey,
      secondaryButton: colors.clearPurple,
      oldClearBlue: colors.clearPurple,
      lilacLines: colors.clearPurple,
      clearGrey: colors.clearGrey,
      purple: colors.lightPurple,
      red: "rgba(223, 4, 56, 0.3)",
    },
    icons: {
      principal: colors.darkText,
    },
    info: {
      main: "#4CBFD9",
      secondary: colors.lightPurple,
      contrastText: "rgba(255,255,255,1)",
    },
  },
  shadows: shadows,
  typography: {
    fontFamily: "Outfit, sans-serif",
    h1: {
      fontSize: "40pt",
      fontWeight: 600,
      color: colors.darkText,
    },
    h2: {
      fontSize: "30pt",
      fontWeight: 500,
      color: colors.darkText,
    },
    h3: {
      fontSize: "20pt",
      fontWeight: 600,
      color: colors.lightPurple,
    },
    bold: {
      fontSize: "12pt",
      fontWeight: 700,
    },
    body1: {
      fontSize: "13pt",
      fontWeight: 500,
      color: colors.darkText,
    },
    body2: {
      fontSize: "12pt",
      fontWeight: 500,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: colors.white,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: colors.beige,
          boxShadow: `0px 10px 10px rgba(0, 0, 0, 0.1)`,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: colors.lightPurple,
            "& .MuiTypography-root": {
              color: colors.darkText,
            },
            "& .MuiListItemIcon-root": {
              color: colors.darkText,
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Outfit, sans-serif",
          fontWeight: 600,
          fontSize: "9pt",
          padding: "10px",
          borderRadius: "24px",
          backgroundColor: colors.lightPurple,
          color: colors.darkText,
          textTransform: "none",
          cursor: "pointer",
          transition: "background-color 0.3s",
          boxShadow: "none",
          border: `2px solid ${colors.lightPurple}`,
          "&:hover": {
            boxShadow: "none",
            backgroundColor: colors.white,
          },
          "&.Mui-disabled": {
            border: "none",
          },
          "&.button-perfil-styles": {
            width: "auto",
            margin: 0,
            padding: 0,
            borderRadius: "4px",
            backgroundColor: "initial",
            textTransform: "capitalize",
            border: "initial",
            cursor: "pointer",
            transition: "initial",
            boxShadow: "none",
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        panelWrapper: {
          backgroundColor: colors.white,
          "& .MuiFormControlLabel-root": {
            color: colors.darkGreen,
          },
          "& .MuiInputLabel-root": {
            color: colors.darkGreen,
          },
          "& .MuiInputBase-root": {
            color: colors.darkGreen,
          },
          "& .MuiTypography-root": {
            color: colors.darkGreen,
          },
          "& .MuiButtonBase-root": {
            color: colors.darkGreen,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label.Mui-focused": {
            color: colors.primary.main,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: colors.beige,
            },
            "&:hover fieldset": {
              borderColor: colors.primary.main,
            },
            "&.Mui-focused fieldset": {
              borderColor: colors.primary.main,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.clearPurple,
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.primary.main,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.clearPurple,
            },
            color: colors.darkGreen,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root": {
            color: colors.lightPurple,
          },
          "& .MuiOutlinedInput-root": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.lightPurple,
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.lightPurple,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.lightPurple,
            },
            color: colors.darkGreen,
          },
        },
      },
    },
  },
});
