export const isAdmin = (userData) => {
    const isAdmin = userData?.is_admin;
    return isAdmin;
};

export const isBoss = (userData) => {
    return userData?.branch && userData.branch.trim() !== "" && userData?.group && userData.group.trim() !== "";
};

export const isAnalyst = (userData) => {
    const requiredPermissions = ["view_faproreport", "view_taxfolder", "view_vaciadoriva", "add_companycertificate"];
    return requiredPermissions.some(permission => userData?.permissions.some(p => p.codename === permission));
};

export const getUserRole = ({ user }) => {
    if (isAdmin(user)) {
        return "Administrador";
    } else if (isBoss(user)) {
        return "Jefe";
    } else if (isAnalyst(user)){
        return "Ejecutivo/Analista";
    }
    else {
        return "";
    }
};
