import axios from "axios"

const onboardingUrl = process.env.REACT_APP_ONBOARDING_URL || "https://onboarding.fapro.app"

export const onboardingApiGetData = async ({
    signal
}) => {

    try {
        
        const res = await axios(
            {
                method:'get', 
                baseURL: onboardingUrl,
                url:'/onboarding/',
                headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`},
                signal
            },
        )

        const data = await res.data

        return {
            fapro: {
                success: true,
                code: 200,
                message: "Datos obtenidos correctamente",
                status: "success",
                data: data
            }
        }

    } catch (err) {

        if (err.response) {
            return {
                fapro: {
                    success: false,
                    code: err.response.data.code,
                    message: err.response.data.message,
                    status: err.response.data.status,
                    data: null
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: 500,
                    message: err.message || 'Opps! Algo salió mal, intente más tarde.',
                    status: 'error',
                    data: null
                }
            }
        }
    }
}
