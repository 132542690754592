import React, { useState } from 'react'
import { Button, Upload, Space, message, Radio } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const apiAuthUrl = process.env.REACT_APP_API_AUTH_URL || "http://localhost:9003"

export default function Form() {
    const history = useHistory();
    const [archivo, setArchivo] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [action, setAction] = useState("cargar");

    const handleUpload = () => {
        const formData = new FormData();

        formData.append('excel_file', archivo, archivo.name)

        setUploading(true);

        axios({
            method: action === "cargar" ? 'post' : 'put',
            url: action === "cargar" ? `${apiAuthUrl}/api/v1.0/add-users-excel` : `${apiAuthUrl}/api/v1.0/users-excel`,
            data: formData,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`
            }
        })
            .then((res) => { 
                setUploading(false); 
                message.success(res.data.message);
                history.push("/dashboard/accounts-list")
             })
            .catch(err => {
                if (err.message.includes("400")) {
                    message.error("La/Las cuentas que intenta cargar ya existen en la base de datos.");
                }
                else {
                    message.error("Hubo un problema al cargar el archivo.");
                }
                setUploading(false);
            })
    };

    const props = {
        beforeUpload: (file) => {
            const notExcelFile = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel";
            if (!notExcelFile) {
                message.error(`${file.name} no es un archivo de excel`);
            }

            setArchivo(file);
            return false;
        },
        onChange: (info) => {
            console.log(info.fileList);
        },
        action: "#",
        listType: "text",
        maxCount: 1,
        showUploadList: {
            showRemoveIcon: true,
            showDownloadIcon: false,
        },
        headers: { 'Authorization': `Bearer ${localStorage.getItem('fapro_token')}` }
    };


    return (
        <Space direction="vertical" size="large">
            <Radio.Group defaultValue="cargar" buttonStyle="solid" onChange={(e) => setAction(e.target.value)}>
                <Radio.Button value="cargar">Carga Masiva</Radio.Button>
                <Radio.Button value="editar">Edición Masiva</Radio.Button>
            </Radio.Group>
            <Upload {...props}>
                <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
            </Upload>
            <Button
                type="primary"
                onClick={handleUpload}
                disabled={archivo === null}
                loading={uploading}
                style={{
                    marginTop: 16,
                }}
            >
                {uploading ? 'Cargando Cuentas' : 'Iniciar Carga'}
            </Button>
        </Space>
    )
}
