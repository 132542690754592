import React, { createContext, useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { lightTheme, newTheme } from '../utils/theme';
import { selectFeatures as selectConfigFeatures } from '../redux/slices/configSlice';
import { authSelectorGetFeatures } from '../redux/slices/authSlice';

const ThemeContext = createContext();

const ThemeContextProvider = ({ children }) => {
  const configFeatures = useSelector(selectConfigFeatures);
  const authFeatures = useSelector(authSelectorGetFeatures);
  const [theme, setTheme] = useState(lightTheme);
  const [isNewMode, setIsNewMode] = useState(false);

  useEffect(() => {
    const hasFaproV2 = authFeatures.some(feature => feature.codename === 'Fapro_V2');
    if (hasFaproV2) {
      setTheme(newTheme);
      setIsNewMode(true);
    } else {
      setTheme(lightTheme);
      setIsNewMode(false);
    }
  }, [authFeatures]);

  useEffect(() => {
    const hasFaproV2 = configFeatures.some(feature => feature.codename === 'Fapro_V2' && feature.available === true);
    if (hasFaproV2) {
      setTheme(newTheme);
      setIsNewMode(true);
    } else {
      setTheme(lightTheme);
      setIsNewMode(false);
    }
  }, [configFeatures]);

  return (
    <ThemeContext.Provider value={{ theme, isNewMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

const useTheme = () => {
  return useContext(ThemeContext);
};

export { ThemeContextProvider, useTheme };
