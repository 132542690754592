import { configureStore } from '@reduxjs/toolkit'
import { accountSlice } from './slices/accountSlice'
import { authSlice } from './slices/authSlice'
import { downloadSlice } from './slices/downloadSlice'
import { emailSlice } from './slices/emailSlice'
import { filterCompaniesSlice } from './slices/filterCompaniesSlice'
import { ivaSlice } from './slices/ivaSlice'
import { linkCompaniesSlice } from './slices/linkCompaniesSlices'
import { pwbSlice } from './slices/pwbSlice'
import { taxSlice } from './slices/taxSlice'
import { uiSlice } from './slices/uiSlice'
import { notificationSlice } from './slices/notificationSlice'
import { onboardingSlice } from './slices/onboardingSlice'
import { debtorSlice } from './slices/debtorSlice'
import { configSlice } from './slices/configSlice'
import { customFieldsSlice } from './slices/customFieldsSlice'


export const store = configureStore({
  reducer: {
    authSlice: authSlice.reducer,
    uiSlice: uiSlice.reducer,
    pwbSlice: pwbSlice.reducer,
    linkCompaniesSlice: linkCompaniesSlice.reducer,
    taxSlice: taxSlice.reducer,
    filterCompaniesSlice: filterCompaniesSlice.reducer,
    ivaSlice: ivaSlice.reducer,
    emailSlice: emailSlice.reducer,
    downloadSlice: downloadSlice.reducer,
    accountSlice: accountSlice.reducer,
    notificationSlice: notificationSlice.reducer,
    onboardingSlice: onboardingSlice.reducer,
    debtorSlice: debtorSlice.reducer,
    configSlice: configSlice.reducer,
    customFieldsSlice: customFieldsSlice.reducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
})