import React, { useState } from "react";
import { ModalStyled } from "../ui/ModalStyled";
import { Box, Button, Typography } from "@mui/material";
import {
  deleteCustomFieldsThunk,
  getCustomFieldsThunk,
} from "../../redux/slices/customFieldsSlice";
import { authSliceLogoutThunk } from "../../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

export const CustomFieldsModalRemove = ({ show, onShow, data }) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [open, setOpen] = useState(false);

  const handleOnSubmit = () => {
    setOpen(true);
    dispatch(deleteCustomFieldsThunk(data))
      .unwrap()
      .then(() => {
        onShow(null);
        dispatch(getCustomFieldsThunk());
      })
      .catch((err) => {
        if (err?.code === 401) {
          setTimeout(() => {
            dispatch(authSliceLogoutThunk()).finally(() => {
              history.replace("/");
            });
          }, 3000);
        }
      })
      .finally(() => {
        setOpen(false);
      });
  };

  return (
    <ModalStyled
      show={show}
      onShow={onShow}
      title={"¿Eliminar Campo personalizado?"}
    >
      <Box className="form-box-container md-scroll">
        <Typography gutterBottom textAlign={"justify"} color="text.primary">
          Esta acción elimina todos los registros de este campo en las empresas.
          No se podrá revertir.
          <br />
        </Typography>
        <Typography gutterBottom textAlign={"justify"} variant="bold">
          ¿Está seguro de eliminar este campo?
        </Typography>

        <Box className="button-confirm-cancel-modal">
          <Button disabled={open} color="error" onClick={() => onShow(null)}>
            Cancelar
          </Button>
          <Button
            disabled={open}
            color="error"
            variant="contained"
            onClick={handleOnSubmit}
          >
            Eliminar
          </Button>
        </Box>
      </Box>
    </ModalStyled>
  );
};
