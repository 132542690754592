import React from "react";
import LoginForm from "../../components/login/LoginForm";
import LeftImage from "../../components/login/LeftImage";

const Container = () => {
  return (
    <div className="app">
      <LeftImage />
      <LoginForm />
    </div>
  );
};

export default Container;
