import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";

export default function CardStyled({ children, title, width, ...rest }) {
  return (
    <Box
      width={{
        xs: "100%",
        sm: "90%",
        md: "80%",
        lg: "70%",
        xl: "60%",
      }}
      maxWidth={500}
      bgcolor="background.primary"
      component={Card}
      borderRadius={"10px"}
      {...rest}
    >
      <Box width="100%" bgcolor="primary.main" py={0.75}>
        <Typography variant="subtitle1" component="h6" color={"text.white"}>
          {title}
        </Typography>
      </Box>
      <CardContent
        sx={{
          width: "100%",
          height: "100%",
          bgcolor: "background.white",
        }}
      >
        {children}
      </CardContent>
    </Box>
  );
}
