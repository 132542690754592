import { Box, Button } from '@mui/material'
import React from 'react'
import { DataGridPaginationStyled } from '../ui/DataGridPaginationStyled'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { emailSelectorGetRowSelectedMailchimp } from '../../redux/slices/emailSlice';

export const EmailModalMailchimpDataGridCustomPagination = ({
    handleShowRemoveMassive,
    ...rest
}) => {


    const rowSelected = useSelector(emailSelectorGetRowSelectedMailchimp)

    const [showEnable, setShowEnable] = useState(false)
    const [showDisable, setShowDisable] = useState(false)
    const handleShowEnable = () => setShowEnable(!showEnable)
    const handleShowDisable = () => setShowDisable(!showDisable)

  return (
    <>
        <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            px={2}
            py={1}
        >
            <Button
            size='small'
                color="error"
            variant='contained'
            disabled={
                // !rowSelected?.length
                true
            }
            onClick={handleShowRemoveMassive}
            >
            Eliminar
            </Button>
            <DataGridPaginationStyled
            {
                ...rest
            }
            labelDisplayedRows={() => {
                return ""
            }}
            />
        </Box>
        {/* <DownloadModalEnableRecords
            show={showEnable}
            onShow={handleShowEnable}
        />
        <DownloadModalDisableRecords
            show={showDisable}
            onShow={handleShowDisable}
        /> */}
    </>
  )
}
