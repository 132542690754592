import { Box, Button, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DataGridPaginationStyled } from "../../components/ui/DataGridPaginationStyled";
import { DataGridStyled } from "../../components/ui/DataGridStyled";
import { SnackbarStyled } from "../../components/ui/SnackbarStyled";
import { isFeatureAvailable } from "../../helpers/utils";
import {
  authSelectorGetData,
  authSliceLogoutThunk,
} from "../../redux/slices/authSlice";
import {
  taxSelectorGetData,
  taxSelectorGetDataEmbedUrl,
  taxSelectorGetDataStatus,
  taxSelectorGetMessageError,
  taxSliceClearMessageError,
  taxSliceClearTaxFolderEmbedUrl,
  taxSliceGetDataFolderEmbedUrlThunk,
  taxSliceGetDataThunk,
} from "../../redux/slices/taxSlice";
import {
  handleDownloadEERR,
  handleDownloadDocuments,
} from "./download_documents";
import { useTheme } from "../../context/ThemeContext";


const DataGridCustomFooter = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={"100%"}
      height={"100%"}
    >
      <DataGridPaginationStyled />
    </Box>
  );
};

const Container = () => {
  const dispatch = useDispatch();
  const messageError = useSelector(taxSelectorGetMessageError);
  const data = useSelector(taxSelectorGetDataEmbedUrl);
  const loadingData = useSelector(taxSelectorGetDataStatus);
  const { companies } = useSelector(taxSelectorGetData);
  const history = useHistory();
  const [currentPromise, setCurrentPromise] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(null);
  const [loadingEERR, setLoadingEERR] = useState(null);
  const [loadingZIP, setLoadingZIP] = useState(null);
  const { isNewMode } = useTheme();
  const {
    user: { features },
  } = useSelector(authSelectorGetData);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [isEerrAvailable, setIsEerrAvailable] = useState(false);
  const [isOnboardingZipAvailable, setIsOnboardingZipAvailable] =
    useState(false);

  useEffect(() => {
    setIsEerrAvailable(
      isFeatureAvailable(features, "Habilitar creación de Estados Financieros")
    );
    setIsOnboardingZipAvailable(
      isFeatureAvailable(features, "Habilitar Descarga Onboarding en Zip")
    );
  }, [features]);

  const handleGenerateEERR = async (companyId, companyName, dispatch) => {
    try {
      setLoadingEERR(companyId);
      await handleDownloadEERR(
        companyId,
        companyName,
        dispatch,
        setLoadingMessage
      );
    } catch (error) {
      console.log("Error capturado en el componente generate_eerr:", error);
    } finally {
      setLoadingEERR(null);
    }
  };

  const handleDownloadDocs = async (rut, dispatch) => {
    try {
      setLoadingZIP(rut);
      await handleDownloadDocuments(rut, dispatch, setLoadingMessage);
    } catch (error) {
      console.log(
        "Error capturado en el componente handleDownloadDocuments:",
        error
      );
    } finally {
      setLoadingZIP(null);
    }
  };

  useEffect(() => {
    const promise = dispatch(taxSliceGetDataThunk());

    promise.unwrap().catch((err) => {
      if (err?.code === 401) {
        setTimeout(() => {
          dispatch(authSliceLogoutThunk()).finally(() => {
            history.replace("/");
          });
        }, 3000);
      }
    });

    return () => {
      promise.abort();
    };
  }, [dispatch, history]);

  useEffect(() => {
    if (data?.embed_url) {
      window.open(data.embed_url, "_blank");
    }
    return () => {
      dispatch(taxSliceClearTaxFolderEmbedUrl());
    };
  }, [data, dispatch]);

  useEffect(() => {
    if (currentPromise) {
      currentPromise
        .unwrap()
        .catch((err) => {
          if (err?.code === 401) {
            setTimeout(() => {
              dispatch(authSliceLogoutThunk()).finally(() => {
                history.replace("/");
              });
            }, 3000);
          }
        })
        .finally(() => {
          setCurrentPromise(null);
        });
    }

    return () => {
      if (currentPromise) {
        currentPromise.abort();
      }
    };
  }, [currentPromise, dispatch, history]);

  const handleClose = () => {
    setOpen(false);
    dispatch(taxSliceClearMessageError());
  };

  useEffect(() => {
    if (messageError && messageError?.message) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [messageError]);

  useEffect(() => {
    dispatch(taxSliceClearMessageError());
    return () => {
      dispatch(taxSliceClearMessageError());
    };
  }, [dispatch]);
  
  return (
    <Box p={0} height="94vh" width="100%" bgcolor={"transparent"}>
      <DataGridStyled
        disableRowSelectionOnClick
        title="Centro de Carpeta Tributaria"
        rows={companies}
        columns={[
          {
            field: "business_name",
            headerName: "Empresa",
            flex: 1,
          },
          {
            field: "rut",
            headerName: "Rut",
            flex: 1,
          },
          isOnboardingZipAvailable && {
            field: "created_at",
            headerName: "Fecha de Vinculación",
            flex: 1,
          },
          {
            field: "id",
            headerName: "Descargar",
            flex: 1,
            renderCell: ({ row }) => {
              return (
                <>
                  {isOnboardingZipAvailable ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      aria-label="Descargar Documentos"
                      disabled={!row.connected || loadingZIP === row.rut}
                      title="Descargar Documentos"
                      onClick={() => handleDownloadDocs(row.rut, dispatch)}
                      sx={{ minWidth: "fit-content" }}
                    >
                      Descargar Documentos
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      disabled={
                        (row.has_taxfolder && row.connected ? false : true) ||
                        loading === row.id
                      }
                      aria-label={
                        row.has_taxfolder && row.connected
                          ? "Descargar Carpeta Tributaria"
                          : "Descargar Carpeta Tributaria deshabilitado"
                      }
                      title={
                        row.has_taxfolder && row.connected
                          ? "Descargar Carpeta Tributaria"
                          : "Descargar Carpeta Tributaria deshabilitado"
                      }
                      onClick={() => {
                        setLoading(row.id);
                        const promise = dispatch(
                          taxSliceGetDataFolderEmbedUrlThunk({
                            company_id: row.id,
                          })
                        );
                        promise.unwrap().finally(() => {
                          setLoading(null);
                        });
                        setCurrentPromise(promise);
                      }}
                    >
                      Carpeta tributaria
                    </Button>
                  )}
                  {isEerrAvailable && (
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      aria-label="Generar EERR"
                      disabled={
                        !row.connected ||
                        row.authentication !== "password" ||
                        loadingEERR === row.id
                      }
                      title="Generar EERR"
                      onClick={() =>
                        handleGenerateEERR(row.id, row.business_name, dispatch)
                      }
                      sx={{
                        ml: 2,
                        ...(isNewMode && {
                          backgroundColor: (theme) => theme.palette.background.secondaryButton,
                          borderColor: (theme) => theme.palette.background.secondaryButton,
                          width: 121,
                        }),
                      }}
                    >
                      Generar EERR
                    </Button>
                  )}
                </>
              );
            },
          },
        ]}
        slots={{
          pagination: DataGridCustomFooter,
        }}
        loading={loadingData === "loading"}
      />
      <SnackbarStyled
        message={messageError?.message || undefined}
        type={messageError?.type || undefined}
        key={JSON.stringify(messageError)}
        open={open}
        onClose={handleClose}
      />
      <Snackbar
        open={!!loadingMessage}
        message={loadingMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
    </Box>
  );
};

export default Container;
