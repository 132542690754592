import { useEffect } from "react";
import { useSelector } from "react-redux";
import { authSelectorGetData } from "../slices/authSlice.js";
import { getUserRole } from "../../utils/userUtils.js";

export const RefinerFeedback = () => {
  const userData = useSelector(authSelectorGetData);
  const token = localStorage.getItem("fapro_token");

  useEffect(() => {
    if (token && userData) {
      renderRefinerScript(userData);
    }
  }, [userData, token]);

  const renderRefinerScript = (userData) => {
    const {
      first_name,
      last_name,
      email,
      environment_name,
      phone_number,
      id,
      date_joined,
    } = userData.user;
    const userRole = getUserRole(userData);
    const projectId = process.env.REACT_APP_REFINER_KEY || "";

    const scriptElement = document.createElement("script");
    scriptElement.type = "text/javascript";
    scriptElement.innerHTML = `
            window._refinerQueue = window._refinerQueue || [];
            function _refiner(){_refinerQueue.push(arguments);}
            _refiner('setProject', '${projectId}');
            _refiner('identifyUser', {
                id: '${id}',
                email: '${email}',
                name: '${first_name} ${last_name}',
                phone: '${phone_number}',
                role: '${userRole}',
                tenant: '${environment_name}',
                created_at: '${date_joined}'
            });
        `;
    document.head.appendChild(scriptElement);

    const embedScriptElement = document.createElement("script");
    embedScriptElement.type = "text/javascript";
    embedScriptElement.src = "https://js.refiner.io/v001/client.js";
    embedScriptElement.async = true;
    document.head.appendChild(embedScriptElement);
  };

  return null;
};
