import axios from "axios";
const apiAuthUrl =
  process.env.REACT_APP_API_AUTH_URL || "https://api.fapro.app";

export const authApiLogin = async (email, password) => {
  try {
    const res = await axios.post(`${apiAuthUrl}/api/login`, {
      email,
      password,
    });
    const data = await res.data;

    if (data.code === 200) {
      return {
        fapro: {
          success: true,
          code: data.code,
          message: data.message,
          status: data.status,
          data: {
            accessToken: data.data.accessToken,
            user: data.data.user,
          },
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: data.code,
          message: data.message,
          status: data.status,
          data: null,
        },
      };
    }
  } catch (err) {
    if (err.response) {
      return {
        fapro: {
          success: false,
          code: err.response.data.code,
          message: err.response.data.message,
          status: err.response.data.status,
          data: null,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: 500,
          message: err.message || "Opps! Algo salió mal, intente más tarde.",
          status: "error",
          data: null,
        },
      };
    }
  }
};

export const authApiLogout = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const res = await axios.post(`${apiAuthUrl}/api/logout`, {}, config);
    const data = await res.data;

    if (data.code === 200) {
      return {
        fapro: {
          success: true,
          code: data.code,
          message: data.message,
          status: data.status,
          data: null,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: data.code,
          message: data.message,
          status: data.status,
          data: null,
        },
      };
    }
  } catch (err) {
    if (err.response) {
      return {
        fapro: {
          success: false,
          code: err.response.data.code,
          message: err.response.data.message,
          status: err.response.data.status,
          data: null,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: 500,
          message: err.message || "Opps! Algo salió mal, intente más tarde.",
          status: "error",
          data: null,
        },
      };
    }
  }
};

export const authApiForgotPassword = async (email) => {
  try {
    const res = await axios.post(`${apiAuthUrl}/api/v1.0/reset-password`, {
      email,
    });
    const data = await res.data;

    if (data.code === 200) {
      return {
        fapro: {
          success: true,
          code: data.code,
          message: data.message,
          status: data.status,
          data: data.data,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: data.code,
          message: data.message,
          status: data.status,
          data: null,
        },
      };
    }
  } catch (err) {
    if (err.response) {
      return {
        fapro: {
          success: false,
          code: err.response.data.code,
          message: err.response.data.message,
          status: err.response.data.status,
          data: null,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: 500,
          message: err.message || "Opps! Algo salió mal, intente más tarde.",
          status: "error",
          data: null,
        },
      };
    }
  }
};

export const authApiRenewToken = async (token) => {
  try {
    const res = await axios.get(`${apiAuthUrl}/api/token`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = res.data;

    if (data.code === 202) {
      const userData = await fetchUserDetails(token);
      if (userData.code === 200) {
        return {
          fapro: {
            success: true,
            code: data.code,
            message: data.message,
            status: data.status,
            data: userData.data,
          },
        };
      } else {
        return {
          fapro: {
            success: false,
            code: userData.code,
            message: userData.message,
            status: userData.status,
            data: null,
          },
        };
      }
    } else {
      return {
        fapro: {
          success: false,
          code: data.code,
          message: data.message,
          status: data.status,
          data: null,
        },
      };
    }
  } catch (err) {
    throw new Error(err.response.data.message);
  }
};

export const authApiUpdateUserInfo = async ({
  name: first_name,
  lastName: last_name,
  phone: phone_number,
}) => {
  try {
    const res = await axios.post(
      `${apiAuthUrl}/api/user/update`,
      {
        first_name,
        last_name,
        phone_number,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("fapro_token")}`,
        },
      }
    );

    const data = await res.data;

    if (data.code === 200) {
      return {
        fapro: {
          success: true,
          code: data.code,
          message: data.message,
          status: data.status,
          data: data.data,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: data.code,
          message: data.message,
          status: data.status,
          data: null,
        },
      };
    }
  } catch (err) {
    if (err.response) {
      return {
        fapro: {
          success: false,
          code: err.response.data.code,
          message: err.response.data.message,
          status: err.response.data.status,
          data: null,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: 500,
          message: err.message || "Opps! Algo salió mal, intente más tarde.",
          status: "error",
          data: null,
        },
      };
    }
  }
};
export const authApiUpdateUserPassword = async (payload) => {
  try {
    const res = await axios.post(
      `${apiAuthUrl}/api/user/password_update`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("fapro_token")}`,
        },
      }
    );

    const data = await res.data;

    if (data.code === 200) {
      return {
        fapro: {
          success: true,
          code: data.code,
          message: data.message,
          status: data.status,
          data: data.data,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: data.code,
          message: data.message,
          status: data.status,
          data: null,
        },
      };
    }
  } catch (err) {
    if (err.response) {
      return {
        fapro: {
          success: false,
          code: err.response.data.code,
          message: err.response.data.message,
          status: err.response.data.status,
          data: null,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: 500,
          message: err.message || "Opps! Algo salió mal, intente más tarde.",
          status: "error",
          data: null,
        },
      };
    }
  }
};

export const authApiNewPasswordUSer = async ({
  email,
  password,
  repeat_password,
  token,
}) => {
  try {
    const res = await axios.patch(`${apiAuthUrl}/api/v1.0/reset-password`, {
      email,
      password,
      repeat_password,
      token,
    });

    const data = await res.data;

    if (data.code === 200) {
      return {
        fapro: {
          success: true,
          code: data.code,
          message: data.message,
          status: data.status,
          data: true,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: data.code,
          message: data.message,
          status: data.status,
          data: null,
        },
      };
    }
  } catch (err) {
    throw new Error(err.response.data.message);
  }
};

export const fetchUserDetails = async (accessToken) => {
  try {
    const response = await axios.get(`${apiAuthUrl}/api/user-detail`, {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Error al obtener datos del usuario");
    }
  } catch (err) {
    throw new Error(err.response ? err.response.data.message : err.message);
  }
};
