import React from "react";
import { ModalStyled } from "../ui/ModalStyled";
import {
  Box,
  Button,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { Formik } from "formik";
import { InputStyled } from "../ui/InputStyled";
import {
  postCustomFieldsThunk,
  getCustomFieldsThunk,
} from "../../redux/slices/customFieldsSlice";
import { authSliceLogoutThunk } from "../../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { AutoCompleteStyled } from "../ui/AutoCompleteStyled";
import { createCustomFieldSchema } from "../../utils/schemas";

export const CustomFieldsModalCreate = ({ show, onShow }) => {
  const [field_type, setFieldType] = React.useState("");
  const [entity, setEntity] = React.useState("");
  const [required, setRequired] = React.useState("");

  const handleChange = (event) => {
    setFieldType(event.target.value);
  };

  const handleChangeEntity = (event) => {
    setEntity(event.target.value);
  };

  const handleChangeRequired = (event) => {
    setRequired(event.target.value);
  };

  const dispatch = useDispatch();

  const history = useHistory();

  const handleOnSubmit = (values, { setSubmitting, resetForm, setErrors }) => {
    setSubmitting(true);

    const data_ = {
      field_name: values.field_name,
      entity: values.entity,
      field_type: values.field_type,
      required: values.required,
      order: 0,
    };

    if (values.field_type === "list") {
      if (
        !values.field_type_list_values ||
        values.field_type_list_values.length === 0
      ) {
        setErrors({
          field_type_list_values: "El campo de Lista de opciones es requerido",
        });
        setSubmitting(false);
        return;
      }
      data_.field_type_list_values = values.field_type_list_values.join(",");
    }

    dispatch(postCustomFieldsThunk(data_))
      .unwrap()
      .then(() => {
        resetForm();
        onShow(null);
        dispatch(getCustomFieldsThunk());
      })
      .catch((err) => {
        if (err?.code === 401) {
          setTimeout(() => {
            dispatch(authSliceLogoutThunk()).finally(() => {
              history.replace("/");
            });
          }, 3000);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <ModalStyled
      show={show}
      onShow={onShow}
      title={"Agregar campo personalizado"}
    >
      <Box className="form-box-container md-scroll">
        <Formik
          initialValues={{
            entity: "",
            required: "",
            field_name: "",
            field_type: "",
            field_type_list_values: [],
          }}
          onSubmit={handleOnSubmit}
          validationSchema={createCustomFieldSchema}
        >
          {({
            isSubmitting,
            handleSubmit,
            errors,
            touched,
            values,
            setFieldValue,
          }) => (
            <>
              <Box
                component={"form"}
                onSubmit={handleSubmit}
                className="form-container"
              >
                <InputStyled
                  fullWidth
                  disabled={isSubmitting}
                  name="field_name"
                  label="Nombre del campo"
                  placeholder="Nombre del campo"
                  type="text"
                  inputProps={{
                    autoComplete: "off",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />

                <FormControl fullWidth>
                  <InputLabel id="tipo-de-cambio-label">
                    Tipo de Campo
                  </InputLabel>
                  <Select
                    name="field_type"
                    labelId="tipo-de-cambio-label"
                    id="tipo-campo-id"
                    value={field_type}
                    label="Tipo de Campo"
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue("field_type", e.target.value);
                      if (e.target.value !== "list") {
                        setFieldValue("field_type_list_values", []);
                      }
                    }}
                    fullWidth
                  >
                    <MenuItem value={"text"}>Texto</MenuItem>
                    <MenuItem value={"number"}>Numérico</MenuItem>
                    <MenuItem value={"list"}>Lista (opciones)</MenuItem>
                    <MenuItem value={"checkbox"}>Seleccion (Checkbox)</MenuItem>
                  </Select>
                  {!field_type && errors.field_type && touched.field_type && (
                    <FormHelperText>{errors.field_type}</FormHelperText>
                  )}
                </FormControl>

                {field_type === "list" && (
                  <>
                    <AutoCompleteStyled
                      disabled={isSubmitting}
                      fullWidth
                      freeSolo
                      name={"field_type_list_values"}
                      label="Lista de opciones"
                      placeholder="Presiona enter para agregar"
                      value={values.field_type_list_values}
                      onChange={(event, newValue) => {
                        setFieldValue("field_type_list_values", newValue);
                      }}
                    />
                    {errors.field_type_list_values &&
                      touched.field_type_list_values && (
                        <FormHelperText error>
                          {errors.field_type_list_values.message}
                        </FormHelperText>
                      )}
                  </>
                )}

                <FormControl fullWidth>
                  <InputLabel id="tipo-de-entidad-label">
                    Tipo de Entidad
                  </InputLabel>
                  <Select
                    name="entity"
                    labelId="tipo-de-entidad-label"
                    id="tipo-de-entidad-id"
                    value={entity}
                    label="Tipo de Entidad"
                    onChange={(e) => {
                      handleChangeEntity(e);
                      setFieldValue("entity", e.target.value);
                    }}
                    fullWidth
                  >
                    <MenuItem value={"empresa"}>Empresa</MenuItem>
                  </Select>
                  {!entity && errors.entity && touched.entity && (
                    <FormHelperText>{errors.entity}</FormHelperText>
                  )}
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="tipo-de-entidad-label">
                    ¿El campo es requerido?
                  </InputLabel>
                  <Select
                    name="required"
                    labelId="tipo-de-entidad-label"
                    id="campo-requerido-id"
                    value={required}
                    label="¿El campo es requerido?"
                    onChange={(e) => {
                      handleChangeRequired(e);
                      setFieldValue("required", e.target.value);
                    }}
                    fullWidth
                  >
                    <MenuItem value={1}>Si</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                  {required === "" && errors.required && touched.required && (
                    <FormHelperText>{errors.required}</FormHelperText>
                  )}
                </FormControl>

                <Box className="button-confirm-cancel-modal">
                  <Button
                    disabled={isSubmitting}
                    color="error"
                    type="button"
                    onClick={() => onShow(null)}
                  >
                    Cancelar
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Crear
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Formik>
      </Box>
    </ModalStyled>
  );
};
