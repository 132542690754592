import React from 'react';
import backgroundImage from '../assets/LoginBackgroung.png'; 
import logoImage from '../assets/LoginLogoHorizontal.svg'; 
import "../css/login.css";

const LeftImage = () => {
  return (
    <div className="left-image">
      <img src={backgroundImage} alt="Fapro LeftImageLogin" className="background-image"/>
      <img src={logoImage} alt="Icon logo" className="logo-login-icon"/>
    </div>
  );
};

export default LeftImage;
