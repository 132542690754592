import { useEffect, useState } from 'react';
import Form from '../../components/link/FormCertificate';
import { useDispatch, useSelector } from 'react-redux';
import { linkCompaniesSelectorGetMessageDigital, linkCompaniesSliceClearMessageLinkPostDigital } from '../../redux/slices/linkCompaniesSlices';
import { Box } from '@mui/material';
import { SnackbarStyled } from '../../components/ui/SnackbarStyled';
import CardStyled from '../../components/ui/CardStyled';

const Container = () => {

    const dispatch = useDispatch();
    const messageDigital = useSelector(linkCompaniesSelectorGetMessageDigital) 
    
    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
        dispatch(linkCompaniesSliceClearMessageLinkPostDigital())
    }

    useEffect(() => {
        if(messageDigital && messageDigital?.message) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [messageDigital])

    useEffect(() => {
        dispatch(linkCompaniesSliceClearMessageLinkPostDigital())
        return () => {
            dispatch(linkCompaniesSliceClearMessageLinkPostDigital())
        }
    }, [])

    return(
        <Box
            bgcolor={"transparent"}
            display={"flex"}
            justifyContent="center"
            alignItems="center"
            width="100%"
            height={"94vh"}
        >
            <CardStyled
                title="Centro de Vinculación de Empresas"
                mx="auto"
            >
                <Form/>
            </CardStyled>
            <SnackbarStyled
                message={messageDigital?.message||undefined}
                type={messageDigital?.type||undefined}
                key={JSON.stringify(messageDigital)}
                open={open}
                onClose={handleClose}
            />
        </Box>
    );
};


export default Container;