import { Box, Button, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { DataGridPaginationStyled } from '../ui/DataGridPaginationStyled'
import { EmailsModalCreate } from './EmailsModalCreate'
import AddIcon from '@mui/icons-material/Add';
import { EmailsModalMailchimp } from './EmailModalMailchimp';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';

export const DataGridPaginationEmails = (props) => {

  const [newShow, setNewShow] = useState(false)
  const handleShowNew = () => {
    setNewShow(!newShow)
  }

  const [mailchimpShow, setMailchimpShow] = useState(false)
  const handleShowMailchimp = () => {
    setMailchimpShow(!mailchimpShow)
  }

  return (
    <>
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        px={2}
        py={1}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={1}
        >
          <Tooltip
            title="Crear nuevo correo electrónico"
          >
          <Button
            type="button"
            onClick={handleShowNew}
            variant="contained"
            color="primary"
            sx={{
              // background: (theme) => theme.palette.gradient.main
              width: 35,
              height: 35,
              minWidth: 35,
              minHeight: 35,
              borderRadius: 35,
              p: 0,
            }}
            size='small'
          >
            <AddIcon/>                          
          </Button>
          </Tooltip>
          <Tooltip
            title="Suscripción Newsletter"
          >
            <Button
              type="button"
              onClick={handleShowMailchimp}
              variant="contained"
              color="primary"
              sx={{
                // background: (theme) => theme.palette.gradient.main
                width: 35,
                height: 35,
                minWidth: 35,
                minHeight: 35,
                borderRadius: 35,
                p: 0,
              }}
              size='small'
            >
              <DraftsOutlinedIcon
                fontSize='small'
              />                          
            </Button>
          </Tooltip>
        </Box>
        <DataGridPaginationStyled
          {
            ...props
          }
        />
      </Box>
      {
          mailchimpShow
              && (
                  <EmailsModalMailchimp
                      show={mailchimpShow}
                      onShow={handleShowMailchimp}
                  />
              )
      }
      {
          newShow
              && (
                  <EmailsModalCreate
                      show={newShow}
                      onShow={handleShowNew}
                  />
              )
      }
    </>
  )
}
