import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { authSliceLogoutThunk } from "../../redux/slices/authSlice";
import {
  filterCompaniesSelectorGetImportDataErrors,
  filterCompaniesSliceClearImportDataErrors,
  filterCompaniesSliceImportDataThunk,
} from "../../redux/slices/filterCompaniesSlice";
import { companyImportSchema } from "../../utils/schemas";
import InputFileStyled from "../ui/InputFileStyled";
import shadows from "@mui/material/styles/shadows";
import { useTheme } from "../../context/ThemeContext";

export default function CompanyDataActions({ customFields }) {
  const dispatch = useDispatch();
  const { isNewMode } = useTheme();

  const importDataErrors = useSelector(
    filterCompaniesSelectorGetImportDataErrors
  );

  const history = useHistory();

  const handleImportDataFilter = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    dispatch(
      filterCompaniesSliceImportDataThunk({
        file: values.file[0].file,
        customFields,
      })
    )
      .unwrap()
      .then(() => {
        resetForm();
      })
      .catch((err) => {
        if (err?.code === 401) {
          setTimeout(() => {
            dispatch(authSliceLogoutThunk()).finally(() => {
              history.replace("/");
            });
          }, 3000);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (importDataErrors) {
      dispatch(filterCompaniesSliceClearImportDataErrors());
    }

    return () => {
      dispatch(filterCompaniesSliceClearImportDataErrors());
    };
  }, [dispatch, importDataErrors]);

  return (
    <Box
      width={"100%"}
      bgcolor="background.terciary"
      mt={2.5}
      p={3}
      borderRadius={2}
      boxShadow={shadows[2]}
    >
      <Formik
        initialValues={{
          file: [],
        }}
        onSubmit={handleImportDataFilter}
        validationSchema={companyImportSchema}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Grid
            container
            width="100%"
            spacing={{
              xs: 0,
              sm: 0,
              md: 2,
              lg: 2,
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={isNewMode ? 6 : 5}
              lg={isNewMode ? 6 : 5}
              xl={isNewMode ? 6 : 5}
            >
              <Typography
                variant="subtitle1"
                textAlign={isNewMode ? "center" : "left"}
                sx={
                  isNewMode
                    ? {
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "text.purple",
                      }
                    : {}
                }
              >
                Importar
              </Typography>
              <InputFileStyled
                name="file"
                actionLabel="Click para importar archivo"
                fullWidth
                showButton={false}
                disabled={isSubmitting}
                messageError="Archivo no valido"
                dragLabel="Click para importar archivo"
                accept={{
                  "application/vnd.ms-excel": [".xls", ".xlsx"],
                }}
                sx={
                  isNewMode
                    ? { color: (theme) => theme.palette.text.purple }
                    : {}
                }
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  mt: 2,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Cargar archivos
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={isNewMode ? 6 : 7}
              lg={isNewMode ? 6 : 7}
              xl={isNewMode ? 6 : 7}
            >
              <Typography
                variant="subtitle1"
                textAlign={isNewMode ? "center" : "left"}
                my={"4px"}
                sx={
                  isNewMode
                    ? {
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: (theme) => theme.palette.text.purple,
                      }
                    : {}
                }
              >
                Detalles de la importación
              </Typography>
              <Box
                sx={{
                  border: 1.5,
                  borderColor: "info.secondary",
                  height: "90%",
                  bgcolor: "transparent",
                }}
                maxHeight={250}
                borderRadius={2}
                py={1}
                px={2}
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems={isNewMode ? "center" : "flex-start"}
                overflow={"hidden"}
              >
                <Box
                  width={"100%"}
                  sx={{
                    borderBottom: 1.25,
                    borderColor: "info.secondary",
                  }}
                  pb={0.25}
                  mb={1}
                  textAlign={isNewMode ? "center" : "left"}
                >
                  <Typography
                    variant="body2"
                    component="p"
                    sx={{
                      color: "info.secondary",
                      fontWeight: 600,
                      fontSize: ".95rem",
                    }}
                  >
                    (Línea excel) - Resultado
                  </Typography>
                </Box>
                <Box
                  width={"100%"}
                  height={"100%"}
                  overflow="auto"
                  className="warning-scroll"
                  pl={1}
                  my={0.5}
                  position={"relative"}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={isNewMode ? "center" : "flex-start"}
                >
                  {isSubmitting && (
                    <Box
                      position={"absolute"}
                      top={0}
                      left={0}
                      width={"100%"}
                      height={"100%"}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      zIndex={10}
                    >
                      <CircularProgress color="info" size={30} />
                    </Box>
                  )}
                  {!isSubmitting &&
                    (importDataErrors ? (
                      importDataErrors?.map((err) => (
                        <Typography
                          key={err.rut}
                          variant="body2"
                          component="p"
                          sx={{
                            color: "text.muted",
                            fontWeight: 500,
                            fontSize: ".8rem",
                            "&:not(:last-of-type)": {
                              marginBottom: ".5rem",
                            },
                          }}
                        >
                          &#x2022; {err.error}
                        </Typography>
                      ))
                    ) : (
                      <Box
                        position={"absolute"}
                        top={0}
                        left={0}
                        width={"100%"}
                        height={"100%"}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        zIndex={10}
                      >
                        <Typography
                          variant="body2"
                          component="p"
                          color="text.muted"
                          textAlign={isNewMode ? "center" : "left"}
                        >
                          No hay errores
                        </Typography>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </Formik>
    </Box>
  );
}
