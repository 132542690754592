import { Formik, ErrorMessage } from "formik";
import { Alert, Box, Button, Typography, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  authSelectorGetMessageForgotPassword,
  authSliceForgotPasswordThunk,
} from "../../redux/slices/authSlice";
import { forgotPasswordSchema } from "../../utils/schemas";
import { Link } from "react-router-dom";
import IconHand from "../assets/LoginMano.png";
import "../css/login.css";

const Wrapper = () => {
  const dispatch = useDispatch();
  const messageForgotPassword = useSelector(
    authSelectorGetMessageForgotPassword
  );

  const handleOnSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    dispatch(authSliceForgotPasswordThunk(values))
      .unwrap()
      .then(() => {
        resetForm();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Box className="login-form">
      <Box className="icon-container">
        <img src={IconHand} alt="Hand Icon" className="hand-icon" />
      </Box>
      <Typography variant="h2">Recuperar Contraseña</Typography>
      <Box sx={{ marginBottom: "42px" }}>
        <Typography
          variant="body1"
          component="p"
          align="center"
          color="text.muted"
          gutterBottom
        >
          Ingresa tu correo electrónico, y se te enviará un link para
          restablecer tu contraseña.
        </Typography>
      </Box>
      <Formik
        initialValues={{ email: "Correo electrónico" }}
        onSubmit={handleOnSubmit}
        validationSchema={forgotPasswordSchema}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          setFieldValue,
          isSubmitting,
          errors,
          touched,
        }) => (
          <Box
            component="form"
            onSubmit={handleSubmit}
            className="form"
            autoComplete="off"
            noValidate
          >
            <Box className="input-container" sx={{ width: "50%" }}>
              <TextField
                variant="standard"
                value={values.email}
                inputProps={{ autoComplete: "off" }}
                id="email"
                name="email"
                type="text"
                onChange={handleChange}
                onFocus={(e) =>
                  e.target.value === "Correo electrónico" &&
                  setFieldValue("email", "")
                }
                onBlur={(e) =>
                  e.target.value === "" &&
                  setFieldValue("email", "Correo electrónico")
                }
                disabled={isSubmitting}
                sx={{
                  width: "100%",
                  padding: "4px",
                }}
                error={touched.email && Boolean(errors.email)}
                helperText={<ErrorMessage name="email" className="error" />}
              />
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              className="submit-button"
              sx={{ maxWidth: "170px" }}
            >
              Recuperar Contraseña
            </Button>
            {messageForgotPassword.active && (
              <Alert severity={messageForgotPassword.type}>
                {messageForgotPassword.message}
              </Alert>
            )}
          </Box>
        )}
      </Formik>
      <Link to="/" className="forgot-password">
        <Typography variant="link">Regresar</Typography>
      </Link>
    </Box>
  );
};

export default Wrapper;
