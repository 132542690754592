import { Box, Button, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { accountSliceDeleteDataRemoveAccountThunk } from "../../redux/slices/accountSlice";
import { authSliceLogoutThunk } from "../../redux/slices/authSlice";
import { ModalStyled } from "../ui/ModalStyled";

export const ModalRemoveAccount = ({ show, onShow, data }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const history = useHistory();

  const handleSubmit = () => {
    setLoading(true);
    dispatch(accountSliceDeleteDataRemoveAccountThunk(data))
      .unwrap()
      .then((res) => {
        if (typeof res === "string") {
        } else {
          onShow(null);
        }
      })
      .catch((err) => {
        if (err?.code === 401) {
          onShow(null);
          setTimeout(() => {
            dispatch(authSliceLogoutThunk()).finally(() => {
              history.replace("/");
            });
          }, 3000);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ModalStyled
      show={show}
      onShow={() => onShow(null)}
      title="Eliminar Cuenta de Usuario"
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 650,
          bgcolor: "background.terciary",
          boxShadow: 24,
          p: 4,
          pt: 3,
          borderRadius: 2,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
        className="sidenav-scroll"
      >
        <Typography variant="bold">
          ¿Estás seguro de que deseas eliminar esta cuenta?
        </Typography>
        <Typography color="text.primary" > Esta acción es permanente.</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 2,
            width: "100%",
            mt: 3,
          }}
        >
          <Button
            color="error"
            type="button"
            disabled={loading}
            onClick={() => onShow(null)}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="error"
            type="submit"
            disabled={loading}
            onClick={handleSubmit}
          >
            Eliminar cuenta
          </Button>
        </Box>
      </Box>
    </ModalStyled>
  );
};
