import { Box, Dialog, IconButton, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

export const ModalStyled = ({
  children,
  show,
  onShow,
  scroll = "paper",
  sx = {},
  title,
  titleBgColor = "primary.main",
  closeOnBackdrop = true,
  isNewMode,
  ...rest
}) => {
  return (
    <Dialog
      open={show}
      onClose={closeOnBackdrop ? onShow : null}
      scroll={scroll}
      {...rest}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          borderRadius: 5,
        },
        ...sx,
      }}
    >
      {!isNewMode && title && (
        <Box
          sx={{
            position: "relative",
            top: 0,
            right: 0,
            width: "100%",
            height: 50,
            minHeight: 50,
            maxHeight: 50,
            bgcolor: titleBgColor,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <Typography
            variant="h6"
            component="h6"
            color="white"
            sx={{
              fontSize: 16,
            }}
            textAlign={"center"}
          >
            {title}
          </Typography>
          <IconButton
            sx={{
              position: "absolute",
              top: "50%",
              right: "1%",
              color: "white",
              transform: "translateY(-50%)",
            }}
            color="inherit"
            onClick={onShow}
          >
            <CloseIcon color="white" fontSize="small" />
          </IconButton>
        </Box>
      )}
      {!isNewMode ? (
        <>
          {children}
        </>
      ) : (
        <>{children}</>
      )}
    </Dialog>
  );
};
