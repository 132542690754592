import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  authSelectorGetError,
  authSliceClearError,
} from "../../redux/slices/authSlice";
import { SnackbarStyled } from "./SnackbarStyled";

export default function PublicLayoutStyled({ children }) {
  const dispatch = useDispatch();
  const messageError = useSelector(authSelectorGetError);
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    dispatch(authSliceClearError());
  };

  useEffect(() => {
    if (messageError && messageError?.message) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [messageError]);

  useEffect(() => {
    dispatch(authSliceClearError());
    return () => {
      dispatch(authSliceClearError());
    };
  }, [pathname]);

  return (
    <>
      {children}
      <SnackbarStyled
        message={messageError?.message || undefined}
        type={messageError?.type || undefined}
        key={JSON.stringify(messageError)}
        open={open}
        onClose={handleClose}
      />
    </>
  );
}
