import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  accountApiAccountCreate,
  accountApiAccountDelete,
  accountApiAccountGetDataById,
  accountApiAccountUpdate,
  accountApiGetPermissions,
  accountApiGetUsers,
} from "../api/accountApi";

export const permissionsAdapter = createEntityAdapter({
  selectId: (item) => item.codename,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const permissionsSelectors = permissionsAdapter.getSelectors(
  ({ accountSlice }) => accountSlice.permissions
);
const {
  selectAll: selectAllPermissions,
} = permissionsSelectors;

export const accountAdapter = createEntityAdapter({
  selectId: (item) => item.id,
  sortComparer: (a, b) => a.first_name.localeCompare(b.first_name),
});

const accountsSelectors = accountAdapter.getSelectors(
  ({ accountSlice }) => accountSlice.accounts
);
const {
  selectAll: selectAllAccounts,
  selectById: selectAccountById,
} = accountsSelectors;

export const accountSliceGetDataPermissionsThunk = createAsyncThunk(
  "account/getDataPermissions",
  async (_, { rejectWithValue, signal }) => {
    try {
      const { fapro } = await accountApiGetPermissions({
        signal,
      });

      if (fapro.success) {
        return fapro.data;
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const accountSliceGetDataUsersThunk = createAsyncThunk(
  "account/getDataUsers",
  async (_, { rejectWithValue, dispatch, signal }) => {
    try {
      const { fapro } = await accountApiGetUsers({
        signal,
      });

      if (fapro.success) {
        return fapro.data;
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const accountSlicePostDataCreateAccountThunk = createAsyncThunk(
  "account/postDataCreateAccount",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { fapro } = await accountApiAccountCreate(payload);

      if (fapro.success) {
        return fapro.message;
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const accountSlicePutDataUpdateAccountThunk = createAsyncThunk(
  "account/putDataUpdateAccount",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { fapro } = await accountApiAccountUpdate(payload);

      if (fapro.success) {
        return fapro.data;
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const accountSliceDeleteDataRemoveAccountThunk = createAsyncThunk(
  "account/deleteDataRemoveAccount",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { fapro } = await accountApiAccountDelete(payload);

      if (fapro.success) {
        return fapro.data;
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const accountSliceGetDataByIdAccountThunk = createAsyncThunk(
  "account/getDataByIdAccount",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { fapro } = await accountApiAccountGetDataById(payload);

      if (fapro.success) {
        return fapro.data;
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const accountSlice = createSlice({
  name: "accountSlice",
  initialState: {
    permissions: permissionsAdapter.getInitialState(),
    messageError: null,
    accounts: accountAdapter.getInitialState({
      status: "idle",
    }),
    userEdit: null,
  },
  reducers: {
    accountSliceClearMessageError: (state, action) => {
      state.messageError = null;
    },
    accountSliceClearUserEdit: (state, action) => {
      state.userEdit = null;
    },
    accountSliceInitialData: (state, action) => {
      permissionsAdapter.removeAll(state.permissions);
      accountAdapter.removeAll(state.accounts);
      state.messageError = null;
      state.userEdit = null;
    },
  },
  extraReducers: {
    [accountSliceGetDataPermissionsThunk.fulfilled]: (state, action) => {
      permissionsAdapter.setAll(state.permissions, action.payload);
    },
    [accountSliceGetDataPermissionsThunk.rejected]: (state, action) => {
      permissionsAdapter.removeAll(state.permissions);
      if (action.payload?.code === 401) {
        state.messageError = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.messageError = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [accountSliceGetDataPermissionsThunk.pending]: (state, action) => {
      state.messageError = null;
    },

    [accountSliceGetDataUsersThunk.fulfilled]: (state, action) => {
      accountAdapter.setAll(state.accounts, action.payload);
      state.accounts.status = "success";
    },
    [accountSliceGetDataUsersThunk.rejected]: (state, action) => {
      accountAdapter.removeAll(state.accounts);
      state.accounts.status = "error";
      if (action.payload?.code === 401) {
        state.messageError = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.messageError = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [accountSliceGetDataUsersThunk.pending]: (state, action) => {
      state.accounts.status = "loading";
      state.messageError = null;
    },

    [accountSlicePostDataCreateAccountThunk.fulfilled]: (state, action) => {
      state.messageError = {
        type: "success",
        message: "Cuenta creada exitosamente",
      };
    },
    [accountSlicePostDataCreateAccountThunk.rejected]: (state, action) => {
      if (action.payload?.code === 401) {
        state.messageError = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.messageError = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [accountSlicePostDataCreateAccountThunk.pending]: (state, action) => {
      state.messageError = null;
    },

    [accountSlicePutDataUpdateAccountThunk.fulfilled]: (state, action) => {
      accountAdapter.upsertOne(state.accounts, action.payload);
      state.messageError = {
        type: "success",
        message: "Cuenta actualizada correctamente",
      };
    },
    [accountSlicePutDataUpdateAccountThunk.rejected]: (state, action) => {
      if (action.payload?.code === 401) {
        state.messageError = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.messageError = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [accountSlicePutDataUpdateAccountThunk.pending]: (state, action) => {
      state.messageError = null;
    },

    [accountSliceDeleteDataRemoveAccountThunk.fulfilled]: (state, action) => {
      accountAdapter.removeOne(state.accounts, action.payload);
      state.messageError = {
        type: "success",
        message: "Cuenta eliminada correctamente",
      };
    },
    [accountSliceDeleteDataRemoveAccountThunk.rejected]: (state, action) => {
      if (action.payload?.code === 401) {
        state.messageError = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.messageError = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [accountSliceDeleteDataRemoveAccountThunk.pending]: (state, action) => {
      state.messageError = null;
    },

    [accountSliceGetDataByIdAccountThunk.fulfilled]: (state, action) => {
      state.userEdit = action.payload;
    },
    [accountSliceGetDataByIdAccountThunk.rejected]: (state, action) => {
      if (action.payload?.code === 401) {
        state.messageError = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.messageError = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [accountSliceGetDataByIdAccountThunk.pending]: (state, action) => {
      state.messageError = null;
      state.userEdit = null;
    },
  },
});

export const accountSelectorGetDataAccounts = createSelector(
  selectAllAccounts,
  (data) => data
);
export const accountSelectorGetDataAccountsStatus = createSelector(
  (state) => state.accountSlice.accounts.status,
  (data) => data
);

export const accountSelectorGetDataByIdAccount = createSelector(
  selectAccountById,
  (data) => data
);

export const accountSelectorGetDataPermissions = createSelector(
  selectAllPermissions,
  (data) => data
);

export const accountSelectorGetMessageError = createSelector(
  ({ accountSlice }) => accountSlice.messageError,
  (data) => data
);

export const accountSelectorGetUserEdit = createSelector(
  ({ accountSlice }) => accountSlice.userEdit,
  (data) => data
);

export const {
  accountSliceClearMessageError,
  accountSliceClearUserEdit,
  accountSliceInitialData,
} = accountSlice.actions;
