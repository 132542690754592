import axios from "axios"

export const notificationApiGetDataStatus = async ({
    signal
}) => {

    try {
        
        const res = await axios(
            {
                method:'get', 
                url: process.env.REACT_APP_STATUS_API_URL,
                headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`},
                signal
            },
        )

        const data = await res.data

        if (
            data.code === 1
                || data.code === 2
        ) {
            return {
                fapro: {
                    success: true,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: data.data
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: null
                }
            }
        }

    } catch (err) {
        if (err.response) {
            return {
                fapro: {
                    success: false,
                    code: err.response.data.code,
                    message: err.response.data.message,
                    status: err.response.data.status,
                    data: null
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: 500,
                    message: err.message || 'Opps! Algo salió mal, intente más tarde.',
                    status: 'error',
                    data: null
                }
            }
        }
    }
}
