import { Chip } from '@mui/material'
import React from 'react'

export const ChipStyled = ({
    ...rest
}) => {
  return (
    <Chip
        {...rest}
    />
  )
}
