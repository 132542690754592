import React, { useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Menu,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  notificationSelectorGetDataStatus,
  notificationSelectorGetStatus,
  notificationSliceClearData,
  notificationSliceGetDataStatusThunk,
} from "../../redux/slices/notificationSlice";
import shadows from "@mui/material/styles/shadows";
import { uiSelectorGetCollapsedSidebar } from "../../redux/slices/uiSlice";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useTheme } from "../../context/ThemeContext";

export const Notification = () => {
  const { isNewMode } = useTheme();
  const dispatch = useDispatch();

  const dataStatus = useSelector(notificationSelectorGetDataStatus);
  const status = useSelector(notificationSelectorGetStatus);
  const collapsedSidebar = useSelector(uiSelectorGetCollapsedSidebar);

  const [dataStatusParsed, setDataStatusParsed] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(notificationSliceGetDataStatusThunk());
    return () => {
      dispatch(notificationSliceClearData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (dataStatus.length > 0) {
      const _dataStatusParsed = dataStatus.map((item) => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return {
          key: key,
          value: value,
        };
      });
      setDataStatusParsed(_dataStatusParsed);
    }
  }, [dataStatus]);

  return (
    <>
      <IconButton
        size={!collapsedSidebar ? "small" : "medium"}
        aria-label={
          dataStatus.length > 0
            ? `Tienes ${dataStatus.length} notificaciones`
            : "No tienes notificaciones"
        }
        disabled={status === "loading"}
        color="inherit"
        onClick={handleMenu}
        sx={{
          cursor: status === "loading" ? "not-allowed" : "pointer",
          bgcolor: "background.primary",
          boxShadow: 1,
          "&:hover": {
            bgcolor: "background.primary",
          },
          position: "relative",
        }}
      >
        <Badge
          badgeContent={dataStatus.length}
          color="error"
          sx={{
            "& .MuiBadge-badge": {
              fontSize: collapsedSidebar ? "0.65rem" : "0.5rem",
              width: collapsedSidebar ? "1rem" : "0.75rem",
              height: collapsedSidebar ? "1rem" : "0.75rem",
              maxWidth: collapsedSidebar ? "1rem" : "0.75rem",
              maxHeight: collapsedSidebar ? "1rem" : "0.75rem",
              minWidth: collapsedSidebar ? "1rem" : "0.75rem",
              minHeight: collapsedSidebar ? "1rem" : "0.75rem",
              top: "50%",
              right: "50%",
              backgroundColor: "transparent !important",
            },
          }}
        >
          <NotificationsIcon
            sx={{
              fontSize: collapsedSidebar ? "1.7rem" : "1.35rem",
              color: !!dataStatus.length ? "error.main" : "text.faproPrimary",
            }}
          />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        sx={{
          mt: 7,
          minWidth: 200,
          maxWidth: 360,
          width: dataStatus.length === 0 ? 220 : "100%",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          sx={{ px: 2, py: 1, pb: 0 }}
        >
          Notificaciones
        </Typography>
        <List
          sx={{
            mb: 2,
            maxWidth: 360,
          }}
        >
          {dataStatus.length === 0 ? (
            <ListItem
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
                width: 200,
                minWidth: 200,
              }}
            >
              <svg
                width="120"
                height="100"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false"
              >
                <g fill="none" fillRule="evenodd">
                  <g transform="translate(24 31.67)">
                    <ellipse
                      fillOpacity={!isNewMode ? "0.8" : "0.08"}
                      fill={!isNewMode ? "#f5f5f5" : "#fff"}
                      cx="67.797"
                      cy="106.89"
                      rx="67.797"
                      ry="12.668"
                    />
                    <path
                      fill={!isNewMode ? "#aeb8c2" : "#b9a7ff"}
                      d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                    />
                    <path
                      fill={!isNewMode ? "#f5f5f7" : "#b9a7ff"}
                      d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                    />
                    <path
                      fill={!isNewMode ? "#dce0e6" : "#074641"}
                      d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                    />
                  </g>
                  <path
                    fill={!isNewMode ? "#dce0e6" : "#b9a7ff"}
                    d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                  />
                  <g
                    fill={!isNewMode ? "#fff" : "#1c1c1c"}
                    transform="translate(149.65 15.383)"
                  >
                    <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                    <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                  </g>
                </g>
              </svg>
              <Typography variant="body2" color="text.muted">
                No hay notificaciones
              </Typography>
            </ListItem>
          ) : (
            <>
              <ListSubheader sx={{ bgcolor: "background.primary" }}>
                Alertas del sistema
              </ListSubheader>
              {dataStatusParsed.map(({ key, value }) => (
                <ListItem button key={key}>
                  <ListItemAvatar title={key}>
                    <Avatar
                      alt={key}
                      src={`/assets/images/${key}.jpg`}
                      sx={{
                        width: 32,
                        height: 32,
                        p: 0.05,
                        bgcolor: "#ffffff",
                        boxShadow: shadows[1],
                      }}
                      imgProps={{
                        style: {
                          objectFit: "contain",
                        },
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText primary={key} secondary={value} />
                </ListItem>
              ))}
            </>
          )}
        </List>
      </Menu>
    </>
  );
};
