import React, { useState, useCallback, memo } from "react";
import {
  DataGrid,
  useGridApiContext,
  esES,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarExportContainer,
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector,
} from "@mui/x-data-grid";
import { Box, MenuItem, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DataGridEmptyStyled } from "./DataGridEmptyStyled";
import { DataGridLoadingStyled } from "./DataGridLoadingStyled";
import { DataGridPaginationStyled } from "./DataGridPaginationStyled";
import { exportFileXLSX } from "../../utils/exportFileXLSX";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-cellCheckbox .MuiDataGrid-checkboxInput": {
    color: theme.palette.primary.main,
  },
  "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-checkboxInput": {
    color: theme.palette.primary.main,
  },
  "& .MuiDataGrid-cellCheckbox .MuiDataGrid-checkboxInput.Mui-checked": {
    color: theme.palette.primary.main,
  },
  "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-checkboxInput.Mui-checked":
    {
      color: theme.palette.primary.main,
    },
  "& .center-header-datagrid .MuiDataGrid-columnHeaderTitleContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .super-app-theme--Filled": {
    backgroundColor: theme.palette.background.paper,
    "&:hover, &.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.background.default,
    },
  },
  "& .super-app-theme--Outfilled": {
    backgroundColor: theme.palette.background.default,
    "&:hover, &.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.background.paper,
    },
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#ffffff",
    color: theme.palette.text.white,
  },
  "& .MuiDataGrid-columnHeaderTitleContainer": {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
  "& .MuiDataGrid-row": {
    color: `${theme.palette.text.primary}dd`,
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.background.default,
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.paper,
    },
  },
  "& .MuiDataGrid-cell:first-of-type": {
    ml: "16px",
  },
  "& .MuiDataGrid-columnHeader:first-of-type": {
    ml: "16px",
  },
  borderRadius: 2,
  border: 0,
  bgcolor: "background.primary",
  height: "100%",
}));

const getJson = (apiRef) => {
  const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

  return filteredSortedRowIds.map((id) => {
    const row = {};
    visibleColumnsField.forEach((field) => {
      row[field] = apiRef.current.getCellParams(id, field).value;
    });
    return row;
  });
};

const ExcelExportMenuItem = memo(({ hideMenu, name, parsedFunc }) => {
  const apiRef = useGridApiContext();

  const handleExport = useCallback(() => {
    const jsonString = getJson(apiRef);
    exportFileXLSX(jsonString.map(parsedFunc), name);
    hideMenu?.();
  }, [apiRef, parsedFunc, name, hideMenu]);

  return <MenuItem onClick={handleExport}>Exportar a Excel</MenuItem>;
});

const CustomToolbar = memo(
  ({
    title,
    setFilterButtonEl,
    disabledPrintOptions,
    disableToolsToolbar,
    showExportOptions,
    exportOptionsName,
    exportOptionsParsed,
  }) => (
    <GridToolbarContainer
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 0,
        alignItems: "flex-start",
      }}
      lang="es"
    >
      <Box
        width="100%"
        bgcolor="primary.main"
        color="text.faproSecondary"
        py={0.75}
        sx={{ borderTopLeftRadius: ".75rem", borderTopRightRadius: ".75rem" }}
        ref={setFilterButtonEl}
      >
        <Typography
          variant="subtitle1"
          component="h6"
          color="text.white"
          textAlign="center"
        >
          {title}
        </Typography>
      </Box>
      {!disableToolsToolbar && (
        <Box
          px="28px"
          py={1}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          bgcolor="transparent"
        >
          <GridToolbarQuickFilter
            color="primary"
            variant="outlined"
            size="small"
            placeholder="Buscar"
            autoComplete="off"
            sx={{ "& .MuiSvgIcon-root": { color: "icons.principal" } }}
            debounceMs={200}
          />
          <Box
            display="flex"
            flexDirection="row"
            gap={1}
            flexWrap="wrap"
            justifyContent="flex-end"
          >
            <GridToolbarColumnsButton
              lang="es"
              text="Columnas"
              aria-label="Columnas"
              aria-labelledby="Columnas"
              size="small"
              sx={{
                fontSize: ".75rem",
                color: "icons.principal",
                background: "inherit",
                border: "none",
              }}
            />
            <GridToolbarFilterButton
              lang="es"
              text="Filtros"
              aria-label="Filtros"
              aria-labelledby="Filtros"
              size="small"
              sx={{
                fontSize: ".75rem",
                color: "icons.principal",
                background: "inherit",
                border: "none",
              }}
            />
            {showExportOptions && (
              <GridToolbarExportContainer
                sx={{
                  fontSize: ".75rem",
                  color: "icons.principal",
                  background: "inherit",
                  border: "none",
                }}
              >
                <ExcelExportMenuItem
                  name={exportOptionsName}
                  parsedFunc={exportOptionsParsed}
                />
              </GridToolbarExportContainer>
            )}
          </Box>
        </Box>
      )}
    </GridToolbarContainer>
  )
);

export const DataGridStyled = ({
  rows,
  columns,
  title,
  sx = {},
  slots = {},
  disabledPrintOptions = true,
  slotPropsToolbar = {},
  slotPropsPagination = {},
  slotProps = {},
  showExportOptions = false,
  exportOptionsName = "Export",
  exportOptionsParsed,
  ...rest
}) => {
  const [filterButtonEl, setFilterButtonEl] = useState(null);

  return (
    <StyledDataGrid
      getRowClassName={(params) =>
        `super-app-theme--${params.row._control ? "Outfilled" : "Filled"}`
      }
      columns={columns}
      rows={rows}
      slots={{
        noRowsOverlay: DataGridEmptyStyled,
        loadingOverlay: DataGridLoadingStyled,
        pagination: DataGridPaginationStyled,
        toolbar: CustomToolbar,
        ...slots,
      }}
      slotProps={{
        panel: { anchorEl: filterButtonEl },
        toolbar: {
          setFilterButtonEl,
          title,
          disabledPrintOptions: disabledPrintOptions || rows.length > 100,
          showExportOptions,
          exportOptionsName,
          exportOptionsParsed,
          ...slotPropsToolbar,
        },
        pagination: { ...slotPropsPagination },
        ...slotProps,
      }}
      density="standard"
      initialState={{
        pagination: {
          paginationModel: { pageSize: 10, page: 0 },
          paginationMode: "client",
        },
      }}
      pageSizeOptions={[5, 10, 25, 50, 75]}
      sx={{
        borderRadius: 2,
        border: 0,
        bgcolor: "background.terciary",
        height: "100%",
        "& .MuiDataGrid-columnHeaderTitleContainer": {
          color: (theme) => `${theme.palette.text.primary}`,
          backgroundColor: "background.terciary",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 600,
          backgroundColor: "background.terciary",
        },
        "& .MuiDataGrid-row": {
          color: (theme) => `${theme.palette.text.primary}dd`,
        },
        "& .MuiDataGrid-cell:first-of-type": { ml: "16px" },
        "& .MuiDataGrid-columnHeader:first-of-type": { ml: "16px" },
        ...sx,
      }}
      hideScrollbar
      localeText={esES.components.MuiDataGrid.defaultProps.localeText}
      {...rest}
    />
  );
};
