import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { authSelectorGetData } from "../redux/slices/authSlice";

export default function RouteController({ children, requiredAuth = false }) {
  const user = useSelector(authSelectorGetData);
  const location = useLocation();

  const { pathname } = location;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      {requiredAuth ? (
        !!user ? (
          children
        ) : (
          <Redirect to="/" state={{ from: location }} replace />
        )
      ) : (
        <>
          {!!user ? (
            <Redirect to="/dashboard" state={{ from: location }} replace />
          ) : (
            children
          )}
        </>
      )}
    </>
  );
}
