import React, { useState, useRef, useEffect } from "react";
import { Formik, ErrorMessage } from "formik";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Box, Button, Typography, TextField, InputAdornment, IconButton } from "@mui/material";
import { authSliceLoginThunk } from "../../redux/slices/authSlice";
import { loginSchema } from "../../utils/schemas";
import "../css/login.css";
import IconHand from "../assets/LoginMano.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";


const LoginForm = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(true);
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const handleOnSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const sanitizedValues = {
      ...values,
      password: values.password === "Contraseña" ? "" : values.password,
    };

    try {
      await dispatch(authSliceLoginThunk(sanitizedValues));
    } finally {
      if (isMountedRef.current) {
        setSubmitting(false);
      }
    }
  };

  const handlePasswordFocus = (e, setFieldValue) => {
    if (e.target.value === "Contraseña") {
      setFieldValue("password", "");
      setShowPassword(false);
    }
  };

  const handlePasswordBlur = (e, setFieldValue) => {
    if (e.target.value === "") {
      setFieldValue("password", "Contraseña");
      setShowPassword(true);
    }
  };

  const handlePasswordChange = (e, handleChange) => {
    handleChange(e);
    setShowPassword(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Formik
      initialValues={{ email: "Correo electrónico", password: "Contraseña" }}
      onSubmit={handleOnSubmit}
      validationSchema={loginSchema}
    >
      {({
        isSubmitting,
        handleSubmit,
        handleChange,
        values,
        setFieldValue,
        errors,
        touched,
      }) => (
        <Box className="login-form">
          <Box className="icon-container">
            <img src={IconHand} alt="Hand Icon" className="hand-icon" />
          </Box>
          <Typography variant="h1">Hola!</Typography>
          <form
            onSubmit={handleSubmit}
            className="form"
            autoComplete="off"
            noValidate
          >
            <Box
              className="input-container"
              sx={{
                width: "50%",
                marginBottom: "16px",
              }}
            >
              <TextField
                variant="standard"
                value={values.email}
                inputProps={{ autoComplete: "off" }}
                id="email"
                name="email"
                type="text"
                onChange={handleChange}
                onFocus={(e) =>
                  e.target.value === "Correo electrónico" &&
                  setFieldValue("email", "")
                }
                onBlur={(e) =>
                  e.target.value === "" &&
                  setFieldValue("email", "Correo electrónico")
                }
                disabled={isSubmitting}
                sx={{
                  width: "100%",
                  padding: "4px",
                }}
                error={touched.email && Boolean(errors.email)}
                helperText={
                  <ErrorMessage name="email" className="error" />
                }
              />
            </Box>
            <Box
              className="input-container"
              sx={{
                width: "50%",
              }}
            >
              <TextField
                variant="standard"
                value={values.password}
                inputProps={{ autoComplete: "off" }}
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                onChange={(e) => handlePasswordChange(e, handleChange)}
                onFocus={(e) => handlePasswordFocus(e, setFieldValue)}
                onBlur={(e) => handlePasswordBlur(e, setFieldValue)}
                disabled={isSubmitting}
                sx={{
                  width: "100%",
                  padding: "4px",
                }}
                error={touched.password && Boolean(errors.password)}
                helperText={<ErrorMessage name="password"  className="error" /> }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => togglePasswordVisibility("password")}
                        onMouseDown={(e) => e.preventDefault()}
                        style={{ color: "#f2f2f2" }} 
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Button
              type="submit"
              disabled={isSubmitting}
              sx={{ maxWidth: '122px' }}
            >
              Iniciar Sesión
            </Button>
          </form>
          <center>
            <Link to="/recovery" className="forgot-password">
              <Typography variant="link">¿Olvidaste tu contraseña?</Typography>
            </Link>
          </center>
        </Box>
      )}
    </Formik>
  );
};

export default LoginForm;