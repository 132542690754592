import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, IconButton, Switch, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGridStyled } from "../../components/ui/DataGridStyled";
import { SnackbarStyled } from "../../components/ui/SnackbarStyled";
import {
  clearMessageError,
  getConfigFeaturesThunk,
  selectFeatureStatus,
  selectFeatures,
  selectMessageError,
  toggleFeatureThunk,
} from "../../redux/slices/configSlice";

const columns = (dispatch) => [
  {
    field: 'codename',
    headerName: 'Código',
    sortable: true,
    flex: 30,
    renderCell: (params) => (
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <div style={{ display: 'flex',  alignItems: 'center', justifyContent: 'space-between', whiteSpace: 'normal', wordBreak: 'break-word', textAlign: 'left' }}>
          {params.value}
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <Tooltip title={params.row.description || ""}>
            <IconButton size="small">
              <HelpOutlineIcon fontSize="small" />
              </IconButton>
          </Tooltip>
        </div>
      </div>
    ),
  },
  {
    field: 'description',
    headerName: 'Descripción',
    flex: 50,
    renderCell: (params) => (
      <div style={{ display: 'flex',  alignItems: 'center', justifyContent: 'space-between', whiteSpace: 'normal', wordBreak: 'break-word', textAlign: 'left' }}>
          {params.value}
        </div>
    ),

  },
  {
    field: 'enabled',
    headerName: 'Habilitado',
    flex: 20,
    renderCell: (params) => (
      <Switch
        checked={params.row.available}
        onChange={() => dispatch(toggleFeatureThunk(params.row.codename))}
      />
    ),
    sortable: false,
    filterable: false,
  },
];

const SettingsContainer = () => {
  const dispatch = useDispatch();
  const features = useSelector(selectFeatures);
  const featureStatus = useSelector(selectFeatureStatus);
  const messageError = useSelector(selectMessageError);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(getConfigFeaturesThunk());
  }, [dispatch]);

  const handleClose = () => {
    setOpen(false);
    dispatch(clearMessageError());
  };

  useEffect(() => {
    if (messageError && messageError?.message) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [messageError]);

  return (
    <Box p={0} height="94vh" width="100%" bgcolor={"transparent"}>
      <DataGridStyled
        title="Configuraciones"
        rows={features}
        columns={columns(dispatch)}
        pageSize={5}
        rowsPerPageOptions={[5]}
        loading={featureStatus === "loading"}
        disableSelectionOnClick
        getRowId={(row) => row.codename}
        initialState={{
          sorting: {
            sortModel: [{ field: 'codename', sort: 'asc' }],
          },
        }}
      />
      <SnackbarStyled
        message={messageError?.message || undefined}
        type={messageError?.type || undefined}
        key={JSON.stringify(messageError)}
        open={open}
        onClose={handleClose}
      />
    </Box>
  );
};

export default SettingsContainer;
