import NewPass from "../../components/recovery/NewPass";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { authSliceClearMessageForgotPassword } from "../../redux/slices/authSlice";
import LeftImage from "../../components/login/LeftImage";

const Container = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authSliceClearMessageForgotPassword());
  }, [dispatch]);

  return (
    <div className="app">
      <LeftImage />
      <NewPass />
    </div>
  );
};
export default Container;
