import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  filterCompaniesApiDataExport,
  filterCompaniesApiDataImport,
  filterCompaniesApiGetData,
  filterCompaniesApiUpdateCompanie,
} from "../api/filterCompaniesApi";

const initialState = {
  data: {
    companies: [],
    branches: {},
    executives: {},
    groups: {},
    status: "idle",
  },

  exportData: null,
  exportDataLoading: false,
  exportDataMessage: null,

  importDataMessage: null,
  importDataErrors: null,

  editCompanieMessage: null,
  messageError: null,
};

export const companiesAdapter = createEntityAdapter({
  selectId: (item) => item.id,
  sortComparer: (a, b) => new Date(a.created_at) - new Date(b.created_at),
});

const companiesSelectors = companiesAdapter.getSelectors(
  ({ filterCompaniesSlice }) => filterCompaniesSlice.companies
);
const {
  selectAll: selectAllCompanies,
} = companiesSelectors;

export const filterCompaniesSliceGetDataThunk = createAsyncThunk(
  "filterCompanies/getData",
  async (_, { rejectWithValue, signal }) => {
    try {
      const { fapro } = await filterCompaniesApiGetData({
        signal,
      });

      if (fapro.success) {
        return fapro.data;
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const filterCompaniesSliceExportDataThunk = createAsyncThunk(
  "filterCompanies/exportData",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { fapro } = await filterCompaniesApiDataExport();

      if (fapro.success) {
        return fapro.data;
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const filterCompaniesSliceImportDataThunk = createAsyncThunk(
  "filterCompanies/importData",
  async (payload, { rejectWithValue }) => {
    try {
      const { fapro } = await filterCompaniesApiDataImport(payload);

      if (fapro.success) {
        return fapro.message;
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const filterCompaniesSliceUpdateCompanie = createAsyncThunk(
  "filterCompanies/updateCompanie",
  async (payload, { rejectWithValue }) => {
    try {
      const { fapro } = await filterCompaniesApiUpdateCompanie(payload);

      if (fapro.success) {
        return fapro.message;
      } else {
        return rejectWithValue(fapro.message);
      }
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const filterCompaniesSlice = createSlice({
  name: "filterCompaniesSlice",
  initialState: {
    ...initialState,
    companies: companiesAdapter.getInitialState(),
  },
  reducers: {
    filterCompaniesSliceClearExportData: (state, action) => {
      state.exportData = null;
    },
    filterCompaniesSliceClearImportDataMessage: (state, action) => {
      state.importDataMessage = null;
    },
    filterCompaniesSliceClearExportDataMessage: (state, action) => {
      state.exportDataMessage = null;
    },
    filterCompaniesSliceClearEditCompanieMessage: (state, action) => {
      state.editCompanieMessage = null;
    },
    filterCompaniesSliceClearMessageError: (state, action) => {
      state.messageError = null;
    },
    filterCompaniesSliceClearImportDataErrors: (state, action) => {
      state.importDataErrors = null;
    },
  },
  extraReducers: {
    [filterCompaniesSliceGetDataThunk.fulfilled]: (state, action) => {
      state.data = {
        ...action.payload,
        status: "success",
      };
      companiesAdapter.setAll(state.companies, action.payload?.companies || []);
    },
    [filterCompaniesSliceGetDataThunk.rejected]: (state, action) => {
      state.data = {
        companies: [],
        branches: {},
        executives: {},
        groups: {},
        status: "error",
      };
      if (action.payload?.code === 401) {
        state.messageError = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.messageError = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [filterCompaniesSliceGetDataThunk.pending]: (state, action) => {
      state.data = {
        companies: [],
        branches: {},
        executives: {},
        groups: {},
        status: "loading",
      };
      state.messageError = null;
    },

    [filterCompaniesSliceExportDataThunk.fulfilled]: (state, action) => {
      state.exportData = action.payload;
      state.exportDataLoading = false;
      state.exportDataMessage = {
        type: "success",
        message: "Datos exportados correctamente",
      };
    },
    [filterCompaniesSliceExportDataThunk.rejected]: (state, action) => {
      state.exportData = null;
      state.exportDataLoading = false;

      if (action.payload?.code === 401) {
        state.exportDataMessage = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.exportDataMessage = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [filterCompaniesSliceExportDataThunk.pending]: (state, action) => {
      state.exportData = null;
      state.exportDataLoading = true;
    },

    [filterCompaniesSliceImportDataThunk.fulfilled]: (state, action) => {
      state.importDataMessage = {
        type: "success",
        message: action.payload,
      };
    },
    [filterCompaniesSliceImportDataThunk.rejected]: (state, action) => {
      if (action.payload?.code === 401) {
        state.importDataMessage = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.importDataMessage = {
          type: "error",
          message: action.payload.message,
        };
        if (action.payload?.data) {
          state.importDataErrors = action.payload.data;
        }
      }
    },
    [filterCompaniesSliceImportDataThunk.pending]: (state, action) => {
      state.importDataMessage = null;
      state.importDataErrors = null;
    },

    [filterCompaniesSliceUpdateCompanie.fulfilled]: (state, action) => {
      state.editCompanieMessage = {
        type: "success",
        message: action.payload,
      };
    },
    [filterCompaniesSliceUpdateCompanie.rejected]: (state, action) => {
      state.editCompanieMessage = {
        type: "error",
        message: action.payload,
      };
    },
    [filterCompaniesSliceUpdateCompanie.pending]: (state, action) => {
      state.editCompanieMessage = null;
    },
  },
});

export const filterCompaniesSelectorCompanies = createSelector(
  selectAllCompanies,
  (companies) => companies
);

export const filterCompaniesSelectorCompaniesStatus = createSelector(
  (state) => state.filterCompaniesSlice.data.status,
  (status) => status
);

export const filterCompaniesSelectorGetData = createSelector(
  ({ filterCompaniesSlice }) => filterCompaniesSlice.data,
  (data) => data
);

export const filterCompaniesSelectorGetExportData = createSelector(
  ({ filterCompaniesSlice }) => filterCompaniesSlice.exportData,
  (data) => data
);

export const filterCompaniesSelectorGetExportDataLoading = createSelector(
  ({ filterCompaniesSlice }) => filterCompaniesSlice.exportDataLoading,
  (data) => data
);

export const filterCompaniesSelectorGetImportDataMessage = createSelector(
  ({ filterCompaniesSlice }) => filterCompaniesSlice.importDataMessage,
  (data) => data
);

export const filterCompaniesSelectorGetImportDataErrors = createSelector(
  ({ filterCompaniesSlice }) => filterCompaniesSlice.importDataErrors,
  (data) => data
);

export const filterCompaniesSelectorGetExportDataMessage = createSelector(
  ({ filterCompaniesSlice }) => filterCompaniesSlice.exportDataMessage,
  (data) => data
);

export const filterCompaniesSelectorGetEditCompanieMessage = createSelector(
  ({ filterCompaniesSlice }) => filterCompaniesSlice.editCompanieMessage,
  (data) => data
);

export const filterCompaniesSelectorGetMessageError = createSelector(
  ({ filterCompaniesSlice }) => filterCompaniesSlice.messageError,
  (data) => data
);

export const {
  filterCompaniesSliceClearExportData,
  filterCompaniesSliceClearImportDataMessage,
  filterCompaniesSliceClearExportDataMessage,
  filterCompaniesSliceClearEditCompanieMessage,
  filterCompaniesSliceClearMessageError,
  filterCompaniesSliceClearImportDataErrors,
} = filterCompaniesSlice.actions;
