import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { message } from "antd";
import { useDropzone } from "react-dropzone";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { UploadOutlined } from "@ant-design/icons";
import { fileToBase64 } from "../../helpers/encode";
import { useField } from "formik";
import AddIcon from "@mui/icons-material/Add";

import { read, readFile, utils } from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadSelectorGetData,
  downloadSliceEnableThunk,
  downloadSliceGetDataThunk,
  downloadSliceResetRowSelected,
} from "../../redux/slices/downloadSlice";
import { authSliceLogoutThunk } from "../../redux/slices/authSlice";
import { useHistory } from "react-router-dom";
import { BackdropWaitingStyled } from "../ui/BackdropWaitingStyled";

export default function DownloadButtonEnableMassiveInputFile({
  label,
  actionLabel,
  name,
  style = {},
  fullWidth = false,
  maxFiles = 1,
  disabled = false,
  accept = {
    "application/x-pkcs12": [".pfx"],
  },
  messageError,
  buttonProps = {},
  ...rest
}) {
  const { companies } = useSelector(downloadSelectorGetData);
  const dispatch = useDispatch();
  const history = useHistory();

  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);

  const [info, meta, helpers] = useField(name);

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: maxFiles,
    accept: accept,
    onDrop: async (acceptedFiles) => {
      setLoading(true);
      let encodes = await Promise.all(
        acceptedFiles.map((file) => fileToBase64(file))
      );

      setLoading(false);
      helpers.setValue(
        acceptedFiles.map((file, index) => ({
          name: file.name,
          encode: encodes[index],
          file: file,
        }))
      );
    },
    onDropRejected: (rejectedFiles) => {
      alert(messageError);
    },
    disabled: disabled || loading,
    onError: (error) => {
      console.log(error);
    },
    maxSize: 10000000,
    ...rest,
  });

  useEffect(() => {
    if (info.value && info.value.length > 0) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = read(data, { type: "array" });
        workbook.SheetNames.forEach((sheet) => {
          let colName = utils.sheet_to_json(workbook.Sheets[sheet], {
            header: 1,
            defval: "",
          })[0];

          if (!colName.map((val) => val.toLowerCase()).includes("rut")) {
            alert("El archivo no tiene la columna RUT");
            helpers.setValue([]);
            return;
          }

          colName.forEach((col, index) => {
            if (col.toLowerCase() === "rut") {
              let data = utils
                .sheet_to_json(workbook.Sheets[sheet], {
                  header: 1,
                  defval: "",
                })
                .slice(1);
              let dataRut = data.map((row) => row[index]);
              dataRut = dataRut.filter((row) => row !== "");
              dataRut = [...new Set(dataRut)];
              dataRut = dataRut.map((val) => ({
                rut: val,
              }));

              if (dataRut.length > 20) {
                alert("El archivo no puede tener más de 20 RUTs");
                return;
              }

              if (companies.length === 0) {
                dataRut = [];
              }
              dataRut = dataRut.filter((val) =>
                companies.find((company) => company.rut === val.rut)
              );

              if (dataRut.length === 0) {
                alert(
                  "El archivo no tiene los ruts de las empresas que ya existen en la lista"
                );
                helpers.setValue([]);
                return;
              }

              setLoading(true);
              dispatch(downloadSliceEnableThunk(dataRut))
                .unwrap()
                .then(() => {
                  dispatch(downloadSliceResetRowSelected());
                  dispatch(downloadSliceGetDataThunk());
                })
                .catch((err) => {
                  if (err?.code === 401) {
                    setTimeout(() => {
                      dispatch(authSliceLogoutThunk()).finally(() => {
                        history.replace("/");
                      });
                    }, 3000);
                  }
                })
                .finally(() => {
                  setLoading(false);
                });
            }
          });
        });
      };
      reader.readAsArrayBuffer(info.value[0].file);
    }
  }, [info.value]);

  return (
    <>
      {contextHolder}
      <FormControl
        sx={{
          alignItems: "start",
          p: 0,
          justifyContent: "center",
          m: 0,
          ...style,
        }}
        fullWidth={fullWidth}
        className="dropzone"
      >
        <input {...getInputProps()} />

        <Button
          variant="outlined"
          color="primary"
          size="small"
          disabled={disabled || loading}
          startIcon={<UploadOutlined />}
          {...buttonProps}
          {...getRootProps()}
        >
          {actionLabel}
        </Button>
        {meta.touched && meta.error && (
          <FormHelperText
            sx={{
              color: "#f44336",
            }}
          >
            {meta.error}
          </FormHelperText>
        )}
      </FormControl>
      <BackdropWaitingStyled loading={loading} />
    </>
  );
}
