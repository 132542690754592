import {
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import React from "react";
import MuiPagination from "@mui/material/Pagination";

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="pagination"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
      size="small"
      siblingCount={0}
      boundaryCount={1}
    />
  );
}

export const DataGridPaginationStyled = (props) => {
  return (
    <>
      <GridPagination
        ActionsComponent={Pagination}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          flexWrap: "nowrap",
          "& .MuiPaginationItem-root": {
            "&.Mui-selected": {
              bgcolor: "background.pagination",
            },
          },
        }}
        labelRowsPerPage="Filas por página:"
        labelDisplayedRows={({ from, to, count }) => {
          return null;
        }}
        {...props}
      />
    </>
  );
};
