import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { pwbApiGetData } from '../api/pwbApi';

const initialState = {
    data: {
        emdeb_token:undefined, 
        embed_url:undefined, 
        report_id:undefined
    },
    messageError : null
}

export const pwbSliceGetDataThunk = createAsyncThunk(
    'pwb/getData',
    async (
        _,
        {
            rejectWithValue,
            signal,
            dispatch
        }
    ) => {
        try {

            const {
                fapro
            } = await pwbApiGetData({
                signal
            })

            if(fapro.success) {
                return fapro.data
            } else {
                return rejectWithValue(fapro)
            }
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)


export const pwbSlice = createSlice({
    name: 'pwbSlice',
    initialState,
    reducers: {
        pwbSliceClearMessageError: (state, action) => {
            state.messageError = null
        },
        pwbSliceClearData: (state, action) => {
            state.data = {
                emdeb_token:undefined, 
                embed_url:undefined, 
                report_id:undefined
            }
        }
    },
    extraReducers: {
        [pwbSliceGetDataThunk.fulfilled]: (state, action) => {
            state.data = {
                ...state.data,
                ...action.payload
            }
        },
        [pwbSliceGetDataThunk.rejected]: (state, action) => {
            state.data= {
                emdeb_token:undefined, 
                embed_url:undefined, 
                report_id:undefined
            }
            if(action.payload?.code === 401) {
                state.messageError = {
                    type: 'warning',
                    message: "Su sesión ha expirado, por favor vuelva a iniciar sesión."
                }
            } else {
                state.messageError = {
                    type: 'error',
                    message: action.payload.message
                }
            }

        },
        [pwbSliceGetDataThunk.pending]: (state, action) => {
            state.data= {
                emdeb_token:undefined, 
                embed_url:undefined, 
                report_id:undefined
            }
            state.messageError = null
        }
    }
  }
)


export const pwbSelectorGetData = createSelector(
    ({
        pwbSlice
    }) => pwbSlice.data,
    (data) => data 
)

export const pwbSelectorGetMessageError = createSelector(
    ({
        pwbSlice
    }) => pwbSlice.messageError,
    (data) => data 
)

export const {
    pwbSliceClearMessageError,
    pwbSliceClearData
} = pwbSlice.actions