import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authSelectorGetData } from "../../../../redux/slices/authSlice";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  List,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Typography,
  Tooltip,
  Badge,
} from "@mui/material";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import {
  uiSelectorGetCollapsedSidebar,
  uiSliceSetCollapsedSidebar,
} from "../../../../redux/slices/uiSlice";
import { onboardingSliceSetShowModal } from "../../../../redux/slices/onboardingSlice";
import Zoom from "@mui/material/Zoom";
import { motion } from "framer-motion";
import { ModalLogout } from "./ModalLogout";
import { Notification } from "../../../notification/Notification";
import { AlertSystemStatus } from "../../../alert-system/AlertSystemStatus";
import { HelpModal } from "../../../help-modal/HelpModal";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import GppBadOutlinedIcon from "@mui/icons-material/GppBadOutlined";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import "../../css/style.css";
import CustomMenuItem from "./CustomMenuItem";
import { useTheme } from "../../../../context/ThemeContext";
import classNames from "classnames";
import LogoHorizontal from "../../../../components/assets/MenuLogoHorizontal.png";
import LogoFapro from "../../../../components/assets/MenuColor.png";
import LogoHorizontalColor from "../../../../components/assets/fapro_horizontal_color.png";
import MenuUser from "../../../../components/assets/MenuUser.png";
import AvatarProfile from "../../../../components/assets/profile_1.png";
import { isFeatureAvailable } from "../../../../helpers/utils";

const {
  REACT_APP_REPORT,
  REACT_APP_LINK_RUT,
  REACT_APP_LINK_CERTIFICATE,
  REACT_APP_IVA,
  REACT_APP_COMPANY_FILTER,
  REACT_APP_TAX_FOLDER,
  REACT_APP_CREATE_ACCOUNT,
  REACT_APP_LIST_ACCOUNT,
  REACT_APP_EMAIL,
  REACT_APP_DOWNLOAD,
  REACT_APP_WEB_USAGE,
  REACT_APP_DEBTORS_LIST,
  REACT_APP_CONFIG,
} = process.env;

export default function SidenavLayout() {
  const dispatch = useDispatch();
  const { isNewMode } = useTheme();

  const {
    user: { permissions, is_admin: isAdmin, first_name, last_name, features },
  } = useSelector(authSelectorGetData);

  const collapsedSidebar = useSelector(uiSelectorGetCollapsedSidebar);

  const handleOnCollapsedSidebar = () => {
    dispatch(uiSliceSetCollapsedSidebar(!collapsedSidebar));
  };

  const [showLogout, setShowLogout] = useState(false);
  const handleShowLogout = () => {
    setShowLogout(!showLogout);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [linkOpen, setLinkOpen] = useState(false);
  const handleClickLink = () => {
    setLinkOpen(!linkOpen);
  };

  const [compOpen, setCompOpen] = useState(false);
  const handleClickComp = () => {
    setCompOpen(!compOpen);
  };

  const [anchorElComp, setAnchorElComp] = useState(null);
  const openComp = Boolean(anchorElComp);
  const handleCloseElComp = () => {
    setAnchorElComp(null);
  };
  const handleClickElComp = (event) => {
    if (!anchorElComp) {
      setAnchorElComp(event.currentTarget);
    } else {
      setAnchorElComp(null);
    }
  };

  const [anchorElLink, setAnchorElLink] = useState(null);
  const openLink = Boolean(anchorElLink);
  const handleCloseElLink = () => {
    setAnchorElLink(null);
  };
  const handleClickElLink = (event) => {
    if (!anchorElLink) {
      setAnchorElLink(event.currentTarget);
    } else {
      setAnchorElLink(null);
    }
  };

  const [accountOpen, setAccountOpen] = useState(false);
  const handleClickAccount = () => {
    setAccountOpen(!accountOpen);
  };
  const [anchorElAccount, setAnchorElAccount] = useState(null);
  const openAccount = Boolean(anchorElAccount);
  const handleCloseElAccount = () => {
    setAnchorElAccount(null);
  };
  const handleClickElAccount = (event) => {
    if (!anchorElAccount) {
      setAnchorElAccount(event.currentTarget);
    } else {
      setAnchorElAccount(null);
    }
  };

  const [helpShow, setHelpShow] = useState(false);
  const handleOnHelpShow = () => setHelpShow(!helpShow);

  const [isCustomFieldsAvailable, setIsCustomFieldsAvailable] = useState(false);
  const [isOnboardingZipAvailable, setIsOnboardingZipAvailable] = useState(false);


  useEffect(() => {
    setIsCustomFieldsAvailable(
      isFeatureAvailable(features, "Habilitar Campos Personalizados")
    );
    setIsOnboardingZipAvailable(
      isFeatureAvailable(features, "Habilitar Descarga Onboarding en Zip")
    );
  }, [features]);

  const redirectTo = isOnboardingZipAvailable ? "/dashboard/tax" : "/dashboard";

  return (
    <>
      {!isOnboardingZipAvailable && <AlertSystemStatus />}
      <ModalLogout show={showLogout} onShow={handleShowLogout} />
      <Box
        className={classNames({
          drawer: true,
          opened: collapsedSidebar,
          rounded: isNewMode,
        })}
        sx={{ bgcolor: "background.primary" }}
      >
        <Box
          className={`collapsible-box ${collapsedSidebar ? "collapsed" : "expanded"
            }`}
          sx={{
            bgcolor: "background.primary",
            color: "background.arrowMenu",
          }}
          onClick={handleOnCollapsedSidebar}
        >
          {!collapsedSidebar ? (
            <KeyboardDoubleArrowRightIcon fontSize="small" color="text.muted" />
          ) : (
            <KeyboardDoubleArrowLeftIcon fontSize="small" color="text.muted" />
          )}
        </Box>
        <Box className="content">
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to={redirectTo}
            sx={{
              mx: "auto",
              display: "flex",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
            gutterBottom
          >
            <motion.div
              className={
                collapsedSidebar ? "motion-div-big" : "motion-div-small"
              }
            >
              <Avatar
                variant="square"
                alt="Fapro SpA. Logo"
                src={
                  isNewMode
                    ? collapsedSidebar
                      ? LogoHorizontal
                      : LogoFapro
                    : LogoHorizontalColor
                }
                className={
                  isNewMode && !collapsedSidebar
                    ? "motion-div-small-new-theme"
                    : ""
                }
                sx={{
                  mx: "auto",
                  bgcolor: "transparent",
                  width: "100%",
                  maxWidth: 125,
                  minWidth: 125 / 2,
                }}
                imgProps={{
                  style: {
                    objectFit: "contain",
                  },
                }}
              />
            </motion.div>
          </Typography>
          <motion.div
            className={`motion-avatar ${collapsedSidebar ? "motion-avatar-big" : "motion-avatar-small"
              }`}
            onClick={!collapsedSidebar ? handleClick : null}
          >
            {!collapsedSidebar && (
              <ArrowRightIcon
                sx={{
                  position: "absolute",
                  right: -10,
                  bottom: -10,
                  transform: "rotate(45deg)",
                }}
                fontSize="small"
                onClick={handleClick}
              />
            )}
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              badgeContent={<Notification />}
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Avatar
                alt="Fapro SpA"
                src={isNewMode ? MenuUser : AvatarProfile}
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </Badge>
          </motion.div>
          <Box
            sx={{
              bgcolor: "transparent",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 1,
            }}
          >
            <Typography
              variant="body2"
              color="text.faproPrimary"
              component={motion.div}
              className={classNames("motion-name-div", {
                "motion-name-big": collapsedSidebar,
                "motion-name-small": !collapsedSidebar,
              })}
            >
              <Button
                endIcon={
                  collapsedSidebar ? (
                    <>
                      {!open ? (
                        <ArrowRightIcon sx={{ fontSize: ".55rem" }} />
                      ) : (
                        <ArrowDropDownIcon sx={{ fontSize: ".55rem" }} />
                      )}
                    </>
                  ) : (
                    <>{!collapsedSidebar && null}</>
                  )
                }
                className="button-perfil-styles"
                sx={{
                  color: "text.perfil",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: 600,
                  textTransform: "capitalize",
                  lineHeight: 1.3,
                  fontSize: 14,
                  "& .MuiButton-endIcon": {
                    marginLeft: 0,
                  },
                }}
                onClick={collapsedSidebar ? handleClick : null}
              >
                {`${first_name} ${last_name}`}
              </Button>
            </Typography>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{
                width: "100%",
                color: "text.muted",
                fontSize: "14px",
              }}
            >
              <Link to="/dashboard/profile" key="profile">
                <MenuItem
                  sx={{
                    color: "text.muted",
                    fontSize: "14px",
                  }}
                >
                  Editar Perfil
                </MenuItem>
              </Link>
              <Link to="/dashboard/change-password" key="change-password">
                <MenuItem
                  sx={{
                    color: "text.muted",
                    fontSize: "14px",
                  }}
                >
                  Cambiar Contraseña
                </MenuItem>
              </Link>
              <MenuItem
                key="intro"
                sx={{
                  color: "text.muted",
                  fontSize: "14px",
                }}
                onClick={() => {
                  dispatch(
                    onboardingSliceSetShowModal({
                      show: true,
                    })
                  );
                }}
              >
                Introducción
              </MenuItem>
              <MenuItem
                key="logout"
                sx={{
                  color: "text.muted",
                  fontSize: "14px",
                }}
                onClick={handleShowLogout}
              >
                Cerrar Sesión
              </MenuItem>
            </Menu>
          </Box>
        </Box>

        <MenuList
          sx={{
            width: "100%",
            overflow: "auto",
            height: "100%",
            overflowX: "hidden",
          }}
        >
          {REACT_APP_REPORT === "1" &&
            permissions.filter((data) => data.codename === "view_faproreport")
              .length === 1 ? (
            <CustomMenuItem
              to="/dashboard"
              icon={<SpeedOutlinedIcon fontSize="small" />}
              title="Reporte fapro"
              collapsedSidebar={collapsedSidebar}
            >
              Reporte fapro
            </CustomMenuItem>
          ) : null}

          {(REACT_APP_LINK_RUT === "1" || REACT_APP_LINK_CERTIFICATE === "1") &&
            (permissions.filter((data) => data.codename === "add_companypassword")
              .length === 1 ||
              permissions.filter(
                (data) => data.codename === "add_companycertificate"
              ).length === 1) ? (
            <Box key="link-company-wrapper">
              <MenuItem
                key="link-company"
                sx={{
                  px: 3,
                  py: 1.25,
                  display: "flex",
                  alignItems: "center",
                  "&:hover": { bgcolor: "background.hover" },
                }}
                onClick={collapsedSidebar ? handleClickLink : handleClickElLink}
              >
                <Tooltip
                  TransitionComponent={Zoom}
                  title="Vincular Empresa"
                  placement="right"
                >
                  <ListItemIcon
                    sx={{
                      color: (theme) => theme.palette.text.faproPrimary,
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                      justifyContent: collapsedSidebar
                        ? "flex-start"
                        : "center",
                    }}
                  >
                    <LinkOutlinedIcon fontSize="small" />
                    {collapsedSidebar ? null : (
                      <ArrowRightIcon
                        sx={{
                          position: "absolute",
                          right: -6,
                          bottom: -7,
                          transform: "rotate(45deg)",
                        }}
                        fontSize="small"
                      />
                    )}
                  </ListItemIcon>
                </Tooltip>
                <motion.div
                  className={collapsedSidebar ? "motion-show" : "motion-hidden"}
                  animate={collapsedSidebar ? "show" : "hidden"}
                  transition={{
                    duration: 0.45,
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    opacity: collapsedSidebar ? 1 : 0,
                    width: "100%",
                  }}
                >
                  <Typography variant="body2" color="text.faproPrimary">
                    Vincular Empresa
                  </Typography>
                  <Box
                    sx={{
                      ml: "auto",
                      color: (theme) => theme.palette.text.faproPrimary,
                      mt: 0,
                    }}
                  >
                    {linkOpen ? (
                      <ArrowRightIcon fontSize="medium" />
                    ) : (
                      <ArrowDropDownIcon fontSize="medium" />
                    )}
                  </Box>
                </motion.div>
                {!collapsedSidebar && (
                  <Menu
                    anchorEl={anchorElLink}
                    open={openLink}
                    onClose={handleCloseElLink}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    sx={{
                      width: "100%",
                      color: "text.muted",
                    }}
                  >
                    <List component="div" disablePadding>
                      {REACT_APP_LINK_RUT === "1" &&
                        permissions.filter(
                          (data) => data.codename === "add_companypassword"
                        ).length === 1 ? (
                        <Link to="/dashboard/link" key="rut-key">
                          <MenuItem
                            sx={{
                              px: 3,
                              "&:hover": { bgcolor: "background.hover" },
                            }}
                          >
                            <Typography
                              variant="body2"
                              fontSize={12}
                              color="text.faproTertiary"
                            >
                              RUT y Clave
                            </Typography>
                          </MenuItem>
                        </Link>
                      ) : null}
                      {REACT_APP_LINK_CERTIFICATE === "1" &&
                        permissions.filter(
                          (data) => data.codename === "add_companycertificate"
                        ).length === 1 ? (
                        <Link
                          to="/dashboard/certificate"
                          key="digital-certificate"
                        >
                          <MenuItem
                            sx={{
                              px: 3,
                              "&:hover": { bgcolor: "background.hover" },
                            }}
                          >
                            <Typography
                              variant="body2"
                              fontSize={12}
                              color="text.faproTertiary"
                            >
                              Certificado Digital
                            </Typography>
                          </MenuItem>
                        </Link>
                      ) : null}
                    </List>
                  </Menu>
                )}
              </MenuItem>
              {collapsedSidebar && (
                <Collapse in={linkOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {REACT_APP_LINK_RUT === "1" &&
                      permissions.filter(
                        (data) => data.codename === "add_companypassword"
                      ).length === 1 ? (
                      <Link to="/dashboard/link" key="dashboard-link">
                        <MenuItem
                          sx={{
                            pr: 3,
                            ml: 3,
                            py: 1.25,
                            "&:hover": { bgcolor: "background.hover" },
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              color: (theme) =>
                                theme.palette.text.faproTertiary,
                            }}
                          >
                            <PasswordOutlinedIcon fontSize="small" />
                          </ListItemIcon>
                          <Typography
                            variant="body2"
                            fontSize={12}
                            color="text.faproTertiary"
                          >
                            RUT y Clave
                          </Typography>
                        </MenuItem>
                      </Link>
                    ) : null}
                    {REACT_APP_LINK_CERTIFICATE === "1" &&
                      permissions.filter(
                        (data) => data.codename === "add_companycertificate"
                      ).length === 1 ? (
                      <Link to="/dashboard/certificate" key="certificate">
                        <MenuItem
                          sx={{
                            pr: 3,
                            ml: 3,
                            py: 1.25,
                            "&:hover": { bgcolor: "background.hover" },
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              color: (theme) =>
                                theme.palette.text.faproTertiary,
                            }}
                          >
                            <CreditCardOutlinedIcon fontSize="small" />
                          </ListItemIcon>
                          <Typography
                            variant="body2"
                            fontSize={12}
                            color="text.faproTertiary"
                          >
                            Certificado Digital
                          </Typography>
                        </MenuItem>
                      </Link>
                    ) : null}
                  </List>
                </Collapse>
              )}
            </Box>
          ) : null}

          {REACT_APP_TAX_FOLDER === "1" &&
            permissions.filter((data) => data.codename === "view_taxfolder")
              .length === 1 ? (
            <CustomMenuItem
              to="/dashboard/tax"
              icon={<QueryStatsOutlinedIcon fontSize="small" />}
              title="Carpeta Tributaria"
              collapsedSidebar={collapsedSidebar}
            >
              Carpeta Tributaria
            </CustomMenuItem>
          ) : null}

          {REACT_APP_COMPANY_FILTER === "1" &&
            permissions.filter((data) => data.codename === "view_companyfilters")
              .length === 1 ? (
            <CustomMenuItem
              to="/dashboard/filter"
              icon={<FilterAltOutlinedIcon fontSize="small" />}
              title="Filtros de Empresas"
              collapsedSidebar={collapsedSidebar}
            >
              Filtros de Empresas
            </CustomMenuItem>
          ) : null}

          {REACT_APP_DEBTORS_LIST === "1" &&
            permissions.filter((data) => data.codename === "view_companyfilters")
              .length === 1 ? (
            <Box key="debtors-list-wrapper">
              <MenuItem
                key="company-filters"
                sx={{
                  px: 3,
                  py: 1.25,
                  display: "flex",
                  alignItems: "center",
                  "&:hover": { bgcolor: "background.hover" },
                }}
                onClick={collapsedSidebar ? handleClickComp : handleClickElComp}
              >
                <Tooltip
                  TransitionComponent={Zoom}
                  title="Gestión de Deudores"
                  placement="right"
                >
                  <ListItemIcon
                    sx={{
                      color: (theme) => theme.palette.text.faproPrimary,
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                      justifyContent: collapsedSidebar
                        ? "flex-start"
                        : "center",
                    }}
                  >
                    <ChecklistOutlinedIcon fontSize="small" />
                    {collapsedSidebar ? null : (
                      <ArrowRightIcon
                        sx={{
                          position: "absolute",
                          right: -6,
                          bottom: -7,
                          transform: "rotate(45deg)",
                        }}
                        fontSize="small"
                      />
                    )}
                  </ListItemIcon>
                </Tooltip>
                <motion.div
                  className={classNames("motion-div", {
                    "motion-show": collapsedSidebar,
                    "motion-hidden": !collapsedSidebar,
                  })}
                  animate={collapsedSidebar ? "show" : "hidden"}
                  style={{
                    opacity: collapsedSidebar ? 1 : 0,
                  }}
                >
                  <Typography variant="body2" color="text.faproPrimary">
                    Gestión de Deudores
                  </Typography>
                  <Box
                    sx={{
                      ml: "auto",
                      color: (theme) => theme.palette.text.faproPrimary,
                      mt: 0,
                    }}
                  >
                    {compOpen ? (
                      <ArrowRightIcon fontSize="medium" />
                    ) : (
                      <ArrowDropDownIcon fontSize="medium" />
                    )}
                  </Box>
                </motion.div>
                {!collapsedSidebar && (
                  <Menu
                    anchorEl={anchorElComp}
                    open={openComp}
                    onClose={handleCloseElComp}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    sx={{
                      width: "100%",
                      color: "text.muted",
                    }}
                  >
                    <List component="div" disablePadding>
                      {
                        <Link to="/dashboard/debtors/white" key="debtors-white">
                          <MenuItem
                            sx={{
                              px: 3,
                              "&:hover": { bgcolor: "background.hover" },
                            }}
                          >
                            <Typography
                              variant="body2"
                              fontSize={12}
                              color="text.faproTertiary"
                            >
                              Lista Blanca
                            </Typography>
                          </MenuItem>
                        </Link>
                      }
                      {
                        <Link to="/dashboard/debtors/black" key="debtors-black">
                          <MenuItem
                            sx={{
                              px: 3,
                              "&:hover": { bgcolor: "background.hover" },
                            }}
                          >
                            <Typography
                              variant="body2"
                              fontSize={12}
                              color="text.faproTertiary"
                            >
                              Lista Negra
                            </Typography>
                          </MenuItem>
                        </Link>
                      }
                    </List>
                  </Menu>
                )}
              </MenuItem>
              {collapsedSidebar && (
                <Collapse in={compOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {
                      <Link to="/dashboard/debtors/white" key="debtors-white-c">
                        <MenuItem
                          sx={{
                            pr: 3,
                            ml: 3,
                            py: 1.25,
                            "&:hover": { bgcolor: "background.hover" },
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              color: (theme) =>
                                theme.palette.text.faproTertiary,
                            }}
                          >
                            <GppGoodOutlinedIcon fontSize="small" />
                          </ListItemIcon>
                          <Typography
                            variant="body2"
                            fontSize={12}
                            color="text.faproTertiary"
                          >
                            Lista Blanca
                          </Typography>
                        </MenuItem>
                      </Link>
                    }
                    {
                      <Link to="/dashboard/debtors/black" key="debtors-black-c">
                        <MenuItem
                          sx={{
                            pr: 3,
                            ml: 3,
                            py: 1.25,
                            "&:hover": { bgcolor: "background.hover" },
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              color: (theme) =>
                                theme.palette.text.faproTertiary,
                            }}
                          >
                            <GppBadOutlinedIcon fontSize="small" />
                          </ListItemIcon>
                          <Typography
                            variant="body2"
                            fontSize={12}
                            color="text.faproTertiary"
                          >
                            Lista Negra
                          </Typography>
                        </MenuItem>
                      </Link>
                    }
                  </List>
                </Collapse>
              )}
            </Box>
          ) : null}

          {REACT_APP_IVA === "1" &&
            permissions.filter((data) => data.codename === "view_vaciadoriva")
              .length === 1 ? (
            <CustomMenuItem
              to="/dashboard/iva"
              icon={<InsertChartOutlinedIcon fontSize="small" />}
              title="Vaciador IVA"
              collapsedSidebar={collapsedSidebar}
            >
              Vaciador IVA
            </CustomMenuItem>
          ) : null}

          {REACT_APP_EMAIL === "1" &&
            permissions.filter((data) => data.codename === "manage_mails")
              .length === 1 ? (
            <CustomMenuItem
              to="/dashboard/emails"
              icon={<EmailOutlinedIcon fontSize="small" />}
              title="Gestión de Correos"
              collapsedSidebar={collapsedSidebar}
            >
              Gestión de Correos
            </CustomMenuItem>
          ) : null}

          {REACT_APP_DOWNLOAD === "1" &&
            permissions.filter((data) => data.codename === "manage_downloads")
              .length === 1 ? (
            <CustomMenuItem
              to="/dashboard/downloads"
              icon={<FileDownloadOutlinedIcon fontSize="small" />}
              title="Gestión de Descargas"
              collapsedSidebar={collapsedSidebar}
            >
              Gestión de Descargas
            </CustomMenuItem>
          ) : null}

          {REACT_APP_WEB_USAGE === "1" &&
            REACT_APP_CREATE_ACCOUNT === "1" &&
            REACT_APP_LIST_ACCOUNT === "1" &&
            (isAdmin === true ||
              permissions.filter((data) => data.codename === "add_accounts")
                .length === 1) ? (
            <Box key="add-accounts-wrapper">
              <MenuItem
                key="add-accounts"
                sx={{
                  px: 3,
                  py: 1.25,
                  "&:hover": { bgcolor: "background.hover" },
                }}
                onClick={
                  collapsedSidebar ? handleClickAccount : handleClickElAccount
                }
              >
                <Tooltip
                  TransitionComponent={Zoom}
                  title="Creacion de Cuentas"
                  placement="right"
                >
                  <ListItemIcon
                    sx={{
                      color: (theme) => theme.palette.text.faproPrimary,
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                      justifyContent: collapsedSidebar
                        ? "flex-start"
                        : "center",
                    }}
                  >
                    <ManageAccountsOutlinedIcon fontSize="small" />
                    {collapsedSidebar ? null : (
                      <ArrowRightIcon
                        sx={{
                          position: "absolute",
                          right: -6,
                          bottom: -7,
                          transform: "rotate(45deg)",
                        }}
                        fontSize="small"
                      />
                    )}
                  </ListItemIcon>
                </Tooltip>
                <motion.div
                  className={classNames("motion-div", {
                    "motion-show": collapsedSidebar,
                    "motion-hidden": !collapsedSidebar,
                  })}
                  animate={collapsedSidebar ? "show" : "hidden"}
                  transition={{
                    duration: 0.45,
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    opacity: collapsedSidebar ? 1 : 0,
                    width: "100%",
                  }}
                >
                  <Typography variant="body2" color="text.faproPrimary">
                    Creacion de Cuentas
                  </Typography>
                  <Box
                    sx={{
                      ml: "auto",
                      mt: 0,
                      color: (theme) => theme.palette.text.faproPrimary,
                    }}
                  >
                    {accountOpen ? (
                      <ArrowRightIcon fontSize="medium" />
                    ) : (
                      <ArrowDropDownIcon fontSize="medium" />
                    )}
                  </Box>
                </motion.div>
                {!collapsedSidebar && (
                  <Menu
                    anchorEl={anchorElAccount}
                    open={openAccount}
                    onClose={handleCloseElAccount}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    sx={{
                      width: "100%",
                      color: "text.muted",
                      textTransform: "capitalize !important",
                    }}
                  >
                    <List component="div" disablePadding>
                      {REACT_APP_CREATE_ACCOUNT === "1" ? (
                        <Link to="/dashboard/accounts" key="accounts">
                          <MenuItem
                            sx={{
                              px: 3,
                              textTransform: "capitalize !important",
                              "&:hover": { bgcolor: "background.hover" },
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                color: (theme) =>
                                  theme.palette.text.faproTertiary,
                              }}
                            >
                              <PersonAddOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography
                              variant="body2"
                              fontSize={12}
                              color="text.faproTertiary"
                              textTransform={"capitalize"}
                            >
                              Agregar Nuevo Usuario
                            </Typography>
                          </MenuItem>
                        </Link>
                      ) : null}

                      {REACT_APP_LIST_ACCOUNT === "1" ? (
                        <Link to="/dashboard/accounts-list" key="accounts-list">
                          <MenuItem
                            sx={{
                              px: 3,
                              "&:hover": { bgcolor: "background.hover" },
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                color: (theme) =>
                                  theme.palette.text.faproTertiary,
                              }}
                            >
                              <PeopleOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography
                              variant="body2"
                              fontSize={12}
                              color="text.faproTertiary"
                            >
                              Lista de Usuarios
                            </Typography>
                          </MenuItem>
                        </Link>
                      ) : null}
                    </List>
                  </Menu>
                )}
              </MenuItem>
              {collapsedSidebar && (
                <Collapse in={accountOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {REACT_APP_CREATE_ACCOUNT === "1" ? (
                      <Link to="/dashboard/accounts" key="accounts-c">
                        <MenuItem
                          sx={{
                            pr: 3,
                            ml: 3,
                            py: 1.25,
                            "&:hover": { bgcolor: "background.hover" },
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              color: (theme) =>
                                theme.palette.text.faproTertiary,
                            }}
                          >
                            <PersonAddOutlinedIcon fontSize="small" />
                          </ListItemIcon>
                          <Typography
                            variant="body2"
                            fontSize={12}
                            color="text.faproTertiary"
                          >
                            Agregar Nuevo Usuario
                          </Typography>
                        </MenuItem>
                      </Link>
                    ) : null}

                    {REACT_APP_LIST_ACCOUNT === "1" ? (
                      <Link to="/dashboard/accounts-list" key="accounts-list-c">
                        <MenuItem
                          sx={{
                            pr: 3,
                            ml: 3,
                            py: 1.25,
                            "&:hover": { bgcolor: "background.hover" },
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              color: (theme) =>
                                theme.palette.text.faproTertiary,
                            }}
                          >
                            <PeopleOutlinedIcon fontSize="small" />
                          </ListItemIcon>
                          <Typography
                            variant="body2"
                            fontSize={12}
                            color="text.faproTertiary"
                          >
                            Lista de Usuarios
                          </Typography>
                        </MenuItem>
                      </Link>
                    ) : null}
                  </List>
                </Collapse>
              )}
            </Box>
          ) : null}

          {REACT_APP_CONFIG === "1" &&
            isAdmin === true &&
            permissions.filter((data) => data.codename === "view_features")
              .length === 1 ? (
            <CustomMenuItem
              to="/dashboard/configs"
              icon={<ToggleOnIcon fontSize="small" />}
              title="Configuraciones"
              collapsedSidebar={collapsedSidebar}
            >
              Configuraciones
            </CustomMenuItem>
          ) : null}

          {(isCustomFieldsAvailable && isAdmin) ||
            (isCustomFieldsAvailable && permissions.filter((data) => data.codename === "view_custom_field").length === 1) ? (
            <CustomMenuItem
              to="/dashboard/custom_fields"
              icon={<LibraryAddOutlinedIcon fontSize="small" />}
              title="Campos Personalizados"
              collapsedSidebar={collapsedSidebar}
            >
              Campos Personalizados
            </CustomMenuItem>
          ) : null}
        </MenuList>

        <Box className="footer" px={collapsedSidebar ? 3 : 1}>
          <Box className="footer-inner" sx={{ gap: 0.5 }}>
            <Box
              className="footer-support"
              sx={{
                cursor: "pointer",
                color: (theme) => `${theme.palette.text.faproPrimary}aa`,
                "&:hover": {
                  color: (theme) => `${theme.palette.text.faproPrimary}ff`,
                },
                gap: collapsedSidebar ? 0.75 : 0.5,
              }}
              onClick={handleOnHelpShow}
            >
              <SupportAgentIcon
                sx={{
                  fontSize: collapsedSidebar ? 24 : 20,
                }}
              />
              <Typography
                sx={{
                  color: (theme) => `${theme.palette.text.faproPrimary}aa`,
                  fontSize: collapsedSidebar ? 12 : 10,
                }}
              >
                Soporte
              </Typography>
            </Box>
            <HelpModal show={helpShow} onShow={handleOnHelpShow} />
            <Typography
              sx={{
                color: (theme) => `${theme.palette.text.faproPrimary}aa`,
                fontSize: collapsedSidebar ? 12 : 10,
              }}
            >
              Powered by fapro.cl
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
