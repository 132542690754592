import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  authSelectorGetData,
  authSliceLogoutThunk,
} from "../../redux/slices/authSlice";
import {
  accountSelectorGetDataAccounts,
  accountSelectorGetDataAccountsStatus,
  accountSelectorGetMessageError,
  accountSliceClearMessageError,
  accountSliceClearUserEdit,
  accountSliceGetDataByIdAccountThunk,
  accountSliceGetDataPermissionsThunk,
  accountSliceGetDataUsersThunk,
} from "../../redux/slices/accountSlice";
import { Box, IconButton } from "@mui/material";
import { DataGridStyled } from "../../components/ui/DataGridStyled";
import { DataGridPaginationStyled } from "../../components/ui/DataGridPaginationStyled";
import { ModalEditAccount } from "../../components/accountList/ModalEditAccount";
import { ModalRemoveAccount } from "../../components/accountList/ModalRemoveAccount";
import { SnackbarStyled } from "../../components/ui/SnackbarStyled";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const Container = () => {
  const dispatch = useDispatch();

  const {
    user: { permissions: userPermissions, is_admin: isAdmin },
  } = useSelector(authSelectorGetData);

  const users = useSelector(accountSelectorGetDataAccounts);
  const usersStatus = useSelector(accountSelectorGetDataAccountsStatus);

  const messageError = useSelector(accountSelectorGetMessageError);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    dispatch(accountSliceClearMessageError());
  };

  let history = useHistory();

  const [showEdit, setShowEdit] = useState(false);
  const [userRemove, setUserRemove] = useState(false);
  const [showRemove, setShowRemove] = useState(false);

  const handleShowEdit = (data) => {
    if (!!data) {
      dispatch(accountSliceGetDataByIdAccountThunk(data))
        .unwrap()
        .catch((err) => {
          if (err?.code === 401) {
            setTimeout(() => {
              dispatch(authSliceLogoutThunk()).finally(() => {
                history.replace("/");
              });
            }, 3000);
          }
        });
    } else {
      dispatch(accountSliceClearUserEdit());
    }
    setShowEdit(!showEdit);
  };

  const handleShowRemove = (data) => {
    if (!!data) {
      setUserRemove(data);
    } else {
      setUserRemove(null);
    }
    setShowRemove(!showRemove);
  };

  useEffect(() => {
    if (
      isAdmin ||
      userPermissions.filter((data) => data.codename === "add_accounts")
        .length === 1
    ) {
    } else {
      history.push("/dashboard");
      message.error("No tienes permisos para entrar a este modulo", 4);
    }
  }, []);

  useEffect(() => {
    const accountsPromise = dispatch(accountSliceGetDataUsersThunk());
    const permissionsPromise = dispatch(accountSliceGetDataPermissionsThunk());

    accountsPromise.unwrap().catch((err) => {
      if (err?.code === 401) {
        setTimeout(() => {
          dispatch(authSliceLogoutThunk()).finally(() => {
            history.replace("/");
          });
        }, 3000);
      }
    });

    permissionsPromise.unwrap().catch((err) => {
      if (err?.code === 401) {
        setTimeout(() => {
          dispatch(authSliceLogoutThunk()).finally(() => {
            history.replace("/");
          });
        }, 3000);
      }
    });

    return () => {
      accountsPromise.abort();
      permissionsPromise.abort();
    };
  }, []);

  useEffect(() => {
    if (!!messageError && messageError.message) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [messageError]);

  useEffect(() => {
    dispatch(accountSliceClearMessageError());
    return () => {
      dispatch(accountSliceClearMessageError());
    };
  }, []);

  const columns = [
    {
      flex: 1,
      headerName: "Nombre(s)",
      field: "first_name",
    },
    {
      flex: 1,
      headerName: "Apellido(s)",
      field: "last_name",
    },
    {
      flex: 2,
      field: "email",
      headerName: "Correo electrónico",
    },
    {
      flex: 1,
      headerName: "Acciones",
      field: "id",
      renderCell: ({ row }) => (
        <Box
          width={"100%"}
          height={"100%"}
          display={"flex"}
          gap={1}
          alignItems={"center"}
          justifyContent={"flex-start"}
          flexWrap={"wrap"}
        >
          <IconButton
            color="secondary"
            size="small"
            onClick={() => handleShowEdit(row)}
          >
            <EditOutlinedIcon />
          </IconButton>
          <IconButton
            color="secondary"
            size="small"
            onClick={() => handleShowRemove(row)}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "94vh",
        width: "100%",
      }}
      bgcolor={"transparent"}
    >
      <DataGridStyled
        title={"Centro de Lista de Cuentas Creadas"}
        rows={users}
        columns={columns}
        slots={{
          pagination: DataGridPaginationStyled,
        }}
        loading={usersStatus === "loading"}
      />
      <SnackbarStyled
        message={messageError?.message || undefined}
        type={messageError?.type || undefined}
        key={JSON.stringify(messageError)}
        open={open}
        onClose={handleClose}
      />
      {showEdit && <ModalEditAccount show={showEdit} onShow={handleShowEdit} />}
      {showRemove && (
        <ModalRemoveAccount
          show={showRemove}
          onShow={handleShowRemove}
          data={userRemove}
        />
      )}
    </Box>
  );
};

export default Container;
