import { useSelector } from "react-redux";
import { authSelectorGetData } from "../../redux/slices/authSlice";

const IFrameWebUsage = () => {
  
  const {
    user: {
      environment_url
    }
  } = useSelector(authSelectorGetData)

  const parsedEnvironmentUrl = environment_url.toLowerCase().split(",")[0];

  // console.log(`https://datastudio.google.com/embed/reporting/3e32f612-d7a0-4527-8c28-be0471417d2a/page/p_ghzqqwrgyc?params=%7B%22df17%22%3A%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${parsedEnvironmentUrl}%22%7D`)

  return (
    <iframe style={{width: '100%', height: 735, overflowY: 'hidden'}} allowFullScreen src={`https://datastudio.google.com/embed/reporting/3e32f612-d7a0-4527-8c28-be0471417d2a/page/p_ghzqqwrgyc?params=%7B%22df17%22%3A%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${parsedEnvironmentUrl}%22%7D`}></iframe>
  );
};

export default IFrameWebUsage; 