import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  collapsedSidebar: true,
};

export const uiSlice = createSlice({
  name: "uiSlice",
  initialState,
  reducers: {
    uiSliceStartLoading: (state) => {
      state.loading = true;
    },
    uiSliceStopLoading: (state) => {
      state.loading = false;
    },
    uiSliceSetCollapsedSidebar: (state, action) => {
      state.collapsedSidebar = action.payload;
    },
  },
});

export const uiSelectorGetLoading = createSelector(
  ({ uiSlice }) => uiSlice.loading,
  (data) => data
);
export const uiSelectorGetCollapsedSidebar = createSelector(
  ({ uiSlice }) => uiSlice.collapsedSidebar,
  (data) => data
);

export const {
  uiSliceStartLoading,
  uiSliceStopLoading,
  uiSliceSetCollapsedSidebar,
} = uiSlice.actions;
