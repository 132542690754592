import axios from "axios";

const apiAuthUrl = process.env.REACT_APP_API_AUTH_URL;

export const debtorApiGetDataWhite = async ({ signal }) => {
  try {
    const res = await axios.post(
      `${apiAuthUrl}/api/v1.0/debtors/view`,
      {
        list: "Lista blanca",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("fapro_token")}`,
        },
        signal: signal,
      }
    );

    const data = await res.data;

    if (data.code === 1) {
      return {
        fapro: {
          success: true,
          code: data.code,
          message: data.message,
          status: data.status,
          data: data.data,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: data.code,
          message: data.message,
          status: data.status,
          data: null,
        },
      };
    }
  } catch (err) {
    if (err.response) {
      return {
        fapro: {
          success: false,
          code: err.response.data.code,
          message: err.response.data.message,
          status: err.response.data.status,
          data: null,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: 500,
          message: err.message || "Opps! Algo salió mal, intente más tarde.",
          status: "error",
          data: null,
        },
      };
    }
  }
};

export const debtorApiEnableWhite = async ({ data: whiteData }) => {
  try {
    const res = await axios.post(
      `${apiAuthUrl}/api/v1.0/debtors/add`,
      {
        debtors: whiteData,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("fapro_token")}`,
        },
      }
    );

    const data = await res.data;

    return {
      fapro: {
        success: true,
        code: 200,
        message: data.message,
        status: "success",
        data: data?.data || null,
      },
    };
  } catch (err) {
    if (err.response) {
      return {
        fapro: {
          success: false,
          code: err.response.data.code,
          status: err.response.data.status,
          data: err.response?.data?.data || null,
          message:
            err.response?.data?.data?.message ||
            "Error al habilitar las empresas seleccionadas",
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: 500,
          message: err.message || "Opps! Algo salió mal, intente más tarde.",
          status: "error",
          data: null,
        },
      };
    }
  }
};

export const debtorApiDisableWhite = async ({ data: whiteData }) => {
  try {
    const res = await axios.post(
      `${apiAuthUrl}/api/v1.0/debtors/remove`,
      {
        debtors: whiteData,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("fapro_token")}`,
        },
      }
    );

    const data = await res.data;
    return {
      fapro: {
        success: true,
        code: 200,
        message: data.message,
        status: "success",
        data,
      },
    };
  } catch (err) {
    if (err.response) {
      return {
        fapro: {
          success: false,
          code: err.response.data.code,
          message: err.response.data.message,
          status: err.response.data.status,
          data: null,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: 500,
          message: err.message || "Opps! Algo salió mal, intente más tarde.",
          status: "error",
          data: null,
        },
      };
    }
  }
};

export const debtorApiGetDataBlack = async ({ signal }) => {
  try {
    const res = await axios.post(
      `${apiAuthUrl}/api/v1.0/debtors/view`,
      {
        list: "Lista negra",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("fapro_token")}`,
        },
        signal: signal,
      }
    );

    const data = await res.data;

    if (data.code === 1) {
      return {
        fapro: {
          success: true,
          code: data.code,
          message: data.message,
          status: data.status,
          data: data.data,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: data.code,
          message: data.message,
          status: data.status,
          data: null,
        },
      };
    }
  } catch (err) {
    if (err.response) {
      return {
        fapro: {
          success: false,
          code: err.response.data.code,
          message: err.response.data.message,
          status: err.response.data.status,
          data: null,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: 500,
          message: err.message || "Opps! Algo salió mal, intente más tarde.",
          status: "error",
          data: null,
        },
      };
    }
  }
};

export const debtorApiEnableBlack = async ({ data: whiteData }) => {
  try {
    const res = await axios.post(
      `${apiAuthUrl}/api/v1.0/debtors/add`,
      {
        debtors: whiteData,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("fapro_token")}`,
        },
      }
    );

    const data = await res.data;

    return {
      fapro: {
        success: true,
        code: 200,
        message: data.message,
        status: "success",
        data: data?.data || null,
      },
    };
  } catch (err) {
    if (err.response) {
      return {
        fapro: {
          success: false,
          code: err.response.data.code,
          status: err.response.data.status,
          data: err.response?.data?.data || null,
          message:
            err.response?.data?.data?.message ||
            "Error al habilitar las empresas seleccionadas",
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: 500,
          message: err.message || "Opps! Algo salió mal, intente más tarde.",
          status: "error",
          data: null,
        },
      };
    }
  }
};

export const debtorApiDisableBlack = async ({ data: whiteData }) => {
  try {
    const res = await axios.post(
      `${apiAuthUrl}/api/v1.0/debtors/remove`,
      {
        debtors: whiteData,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("fapro_token")}`,
        },
      }
    );

    const data = await res.data;
    return {
      fapro: {
        success: true,
        code: 200,
        message: data.message,
        status: "success",
        data,
      },
    };
  } catch (err) {
    if (err.response) {
      return {
        fapro: {
          success: false,
          code: err.response.data.code,
          message: err.response.data.message,
          status: err.response.data.status,
          data: null,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: 500,
          message: err.message || "Opps! Algo salió mal, intente más tarde.",
          status: "error",
          data: null,
        },
      };
    }
  }
};
