import { Box, Button, Divider } from "@mui/material";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  accountSelectorGetDataPermissions,
  accountSlicePostDataCreateAccountThunk,
} from "../../redux/slices/accountSlice";
import { authSliceLogoutThunk } from "../../redux/slices/authSlice";
import { createAccountSchema } from "../../utils/schemas";
import { AutoCompleteStyled } from "../ui/AutoCompleteStyled";
import { CheckboxStyled } from "../ui/CheckboxStyled";
import { InputStyled } from "../ui/InputStyled";

const RegistrationForm = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const permissions = useSelector(accountSelectorGetDataPermissions);

  const handleOnSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    dispatch(
      accountSlicePostDataCreateAccountThunk({
        ...values,
        permissions: values.permissions
          .filter((prev) => prev.checked && prev.value !== "all")
          .map((prev) => prev.value),
        executive: values.executive.join(","),
        branch: values.branch.join(","),
        group: values.group.join(","),
      })
    )
      .unwrap()
      .then((res) => {
        if (typeof res === "string") {
          resetForm();
        }
      })
      .catch((err) => {
        if (err?.code === 401) {
          setTimeout(() => {
            dispatch(authSliceLogoutThunk()).finally(() => {
              history.replace("/");
            });
          }, 3000);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Formik
        key={JSON.stringify(permissions)}
        initialValues={{
          phone_number: "",
          name: "",
          lastName: "",
          email: "",
          password: "",
          permissions: [
            {
              label: "Todos los Permisos",
              value: "all",
              checked: false,
            },
            ...permissions
              .filter((prev) => prev.codename !== "view_comercialalerts")
              .map((prev) => ({
                value: prev.codename,
                checked: false,
                label:
                  prev.codename === "manage_downloads"
                    ? "Gestión de Descargas"
                    : prev.codename === "manage_mails"
                    ? "Gestión de Correos Electrónicos"
                    : prev.codename === "view_faproreport"
                    ? "Reporte fapro"
                    : prev.codename === "add_companycertificate"
                    ? "Vincular Empresas (Certificado Digital)"
                    : prev.codename === "add_companypassword"
                    ? "Vincular Empresas (RUT y Clave)"
                    : prev.name,
              })),
          ],
          executive: [],
          branch: [],
          group: [],
        }}
        onSubmit={handleOnSubmit}
        validationSchema={createAccountSchema}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              gap: 2,
              width: "100%",
              height: "100%",
            }}
            onSubmit={handleSubmit}
            component="form"
          >
            <Box
              display={"flex"}
              flexDirection={{
                xs: "column",
                md: "row",
              }}
              gap={2}
              width={"100%"}
            >
              <InputStyled
                autoComplete="off"
                fullWidth
                disabled={isSubmitting}
                name="name"
                label="Nombre(s)"
                placeholder=""
                inputProps={{
                  autoComplete: "off",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
              <InputStyled
                autoComplete="off"
                fullWidth
                disabled={isSubmitting}
                name="lastName"
                label="Apellido(s)"
                placeholder=""
                inputProps={{
                  autoComplete: "off",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Box>
            <InputStyled
              autoComplete="off"
              fullWidth
              disabled={isSubmitting}
              name="email"
              type="email"
              label="Correo electrónico"
              placeholder="ejemplo@fapro.app"
              inputProps={{
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },
              }}
            />
            <InputStyled
              autoComplete="off"
              fullWidth
              disabled={isSubmitting}
              name="phone_number"
              label="Teléfono"
              placeholder=""
              inputProps={{
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },
              }}
            />
            <InputStyled
              autoComplete="off"
              fullWidth
              disabled={isSubmitting}
              name="password"
              label="Contraseña"
              type="password"
              placeholder="********"
              isPassword={true}
              inputProps={{
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },
              }}
            />
            <Divider />
            <CheckboxStyled
              disabled={isSubmitting}
              name="permissions"
              label="Facultades de Cuenta"
              fullWidth
              sxFormLabel={{
                bgcolor: "background.oldClearBlue",
                width: "100%",
                textAlign: "center",
                py: 1,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                color: "text.primary",
              }}
              sxFormGroup={{
                borderWidth: 1,
                borderColor: "background.lilacLines",
                borderStyle: "solid",
                width: "100%",
                p: 3,
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
              }}
            />
            <Divider />
            <AutoCompleteStyled
              disabled={isSubmitting}
              fullWidth
              freeSolo
              name={"executive"}
              label="Filtro de ejecutivo (opcional)"
              placeholder="Presiona enter para agregar"
            />
            <AutoCompleteStyled
              disabled={isSubmitting}
              fullWidth
              freeSolo
              name={"branch"}
              label="Filtro de sucursal (opcional)"
              placeholder="Presiona enter para agregar"
            />
            <AutoCompleteStyled
              disabled={isSubmitting}
              fullWidth
              freeSolo
              name={"group"}
              label="Filtro de grupo (opcional)"
              placeholder="Presiona enter para agregar"
            />
            <Box width={"100%"} pt={1.25}>
              <Button
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit}
                m="auto"
              >
                Registrar
              </Button>
            </Box>
          </Box>
        )}
      </Formik>
    </Box>
  );
};

export default RegistrationForm;
