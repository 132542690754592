import { useEffect, useState } from 'react';
import Wrapper from '../../components/commons/PrivateLayoutStyled';
import { Table } from 'antd';
import Api from '../../helpers/api';
import { Box } from '@mui/material';


const Container = () => {
    const [dataSource, setDataSource] = useState([]);

    const getOnboardingData = async () => {
      try {
        const res = await Api.faproOnboarding();
        setDataSource(res.data);
      } catch (error) {
        console.log(error)
      }
    }
    
    useEffect(() => {
      getOnboardingData();
    }, [])

    const columns = [
        {
          title: 'Unidad',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title:'Tipo',
          dataIndex:'type',
          key:'type'
        },
        {
          title: 'Título',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Descripción',
          dataIndex: 'detail',
          key: 'detail',
        },
        {
            title: 'Recursos',
            dataIndex: 'link',
            key: 'link',
            render: text => <a href={text !== 'shorturl.at/eoBKV' ? text : 'http://'+text} target="_blank">{text}</a>,
          },
      ];

    return(
        <Wrapper
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              p: 2
            }}
          >
            <h3>Centro de onboarding de la plataforma</h3>
          </Box>
          <Table columns={columns} dataSource={dataSource}/>
        </Wrapper>
    );
};


export default Container;