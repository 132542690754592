import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ivaSelectorGetMessageError,
  ivaSliceClearData,
  ivaSliceClearMessageError,
  ivaSlicePostFileThunk,
} from "../../redux/slices/ivaSlice";
import { Formik } from "formik";
import { Box, Button } from "@mui/material";
import InputFileStyled from "../../components/ui/InputFileStyled";
import { ivaImportSchema } from "../../utils/schemas";
import { authSliceLogoutThunk } from "../../redux/slices/authSlice";
import { useHistory } from "react-router-dom";
import CardStyled from "../../components/ui/CardStyled";
import { SnackbarStyled } from "../../components/ui/SnackbarStyled";

const Container = () => {
  const dispatch = useDispatch();
  const messageError = useSelector(ivaSelectorGetMessageError);

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(!open);
  };

  const handleOnSubmit = async (values, { setSubmitting, resetForm }) => {
    setLoading(true);
    setSubmitting(true);
    dispatch(
      ivaSlicePostFileThunk({
        file: values.file[0].file,
      })
    )
      .unwrap()
      .then((result) => {
        if (!!result) {
          const link = document.createElement("a");
          link.href = result;
          const fileName = values.file[0].name.split(".");
          link.setAttribute("download", `vaciado_iva_${fileName[0]}.xlsx`);
          document.body.appendChild(link);
          link.click();
          resetForm();
          dispatch(ivaSliceClearData());
        }
      })
      .catch((err) => {
        if (err?.code === 401) {
          dispatch(authSliceLogoutThunk()).finally(() => {
            history.replace("/");
          });
        }
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (messageError && messageError?.message) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [messageError]);

  useEffect(() => {
    dispatch(ivaSliceClearData());
    return () => {
      dispatch(ivaSliceClearData());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(ivaSliceClearMessageError());
    return () => {
      dispatch(ivaSliceClearMessageError());
    };
  }, [dispatch]);

  return (
    <Box
      bgcolor={"transparent"}
      display={"flex"}
      justifyContent="center"
      alignItems="center"
      width="100%"
      height={"94vh"}
    >
      <CardStyled title={"Vaciador de IVA"} mx="auto" my="auto">
        <Formik
          initialValues={{
            file: [],
          }}
          onSubmit={handleOnSubmit}
          validationSchema={ivaImportSchema}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                gap: 2,
              }}
            >
              <InputFileStyled
                name="file"
                disabled={isSubmitting | loading}
                showButton={false}
                dragLabel="Click para cargar PDF"
                actionLabel="Click para cargar PDF"
                fullWidth
                messageError="Archivo no válido"
                accept={{
                  "application/pdf": [".pdf", ".PDF", ".Pdf", ".pDF"],
                }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isSubmitting || loading}
              >
                Generar Excel
              </Button>
            </Box>
          )}
        </Formik>
      </CardStyled>
      <SnackbarStyled
        message={messageError?.message || undefined}
        type={messageError?.type || undefined}
        key={JSON.stringify(messageError)}
        open={open}
        onClose={handleClose}
      />
    </Box>
  );
};

export default Container;
