import axios from 'axios';
const apiAuthUrl = process.env.REACT_APP_API_AUTH_URL || "https://api.fapro.app"
const onboardingUrl = process.env.REACT_APP_ONBOARDING_URL || "https://onboarding.fapro.app"
const taxFolderUrl = process.env.REACT_APP_TAX_FOLDER_URL|| "https://carpetatributaria.fapro.cl/api/cte_parser"

axios.defaults.headers.common['Authorization'] = localStorage.getItem('token_fapro');

const rejectLogin = () => {
    localStorage.clear();
    window.location.reload();
    console.log("error by fede");
};

const Api = {
    // Authentication Api Service
    // faproLogin: (email, password) => axios.post(`${apiAuthUrl}/api/login`, {email, password}).then((res) =>  res).catch(err => {rejectLogin(); rejectLogin(); throw new Error(err.response.data.message)}),
    faproRegister: (name, email, password, lastName, phone) => axios.post(`${apiAuthUrl}/api/user/create`, {name, email, password, lastname:lastName, phone_number:phone, repeat_password:password}).then((res) =>  res).catch(err => {rejectLogin(); rejectLogin(); throw new Error(JSON.stringify(err.response.data))}),
    // faproReset: (email) => axios.post(`${apiAuthUrl}/api/v1.0/reset-password`, {email}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(JSON.stringify(err.response.data.message))}),
    faproNewPass: (email, password, newPassword, token) => axios.patch(`${apiAuthUrl}/api/v1.0/reset-password`, {email, password, repeat_password: newPassword, token}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(JSON.stringify(err.response.data.message))}),
    faproPBI: () => axios({method:'post', url:`${apiAuthUrl}/api/pbi/data_embed`, headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproUserPassword: ( password, new_password, current_password) => axios({method:'post', url:`${apiAuthUrl}/api/user/password_update`, data:{password, repeat_password: new_password, current_password}, headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproShow: () => axios({method:'get', url:`${apiAuthUrl}/api/user/show`, headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    
    // Fapro Api Service
    faproLink: (name, rut, password, factoring) => axios({method:'post', url:`${apiAuthUrl}/api/v1.0/company/create_from_password`, data:{rz: name, rut, password, factoring}, headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproCertificate: ({name, rut, password, certificate, factoring}) => axios({method:'post', url:`${apiAuthUrl}/api/v1.0/company/create_from_certificate`, data: {rz: name, rut, password, certificate, factoring}, headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) => res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproUser: (email, name, lastName, phone) => axios({method:'post', url:`${apiAuthUrl}/api/user/update`, data:{first_name: name, email, phone_number:phone, last_name: lastName}, headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproAmbient: (data) => axios({method:'post', url:`${apiAuthUrl}/api/v1.0/configuration/create`, data:{...data}, headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproAmbientEdit: (data) => axios({method:'post', url:`${apiAuthUrl}/api/v1.0/configuration/update`, data:{...data}, headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproAmbientShow: () => axios({method:'get', url:`${apiAuthUrl}/api/v1.0/configuration/show`, headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproFilter: () => axios({method:'get', url:`${apiAuthUrl}/api/v1.0/companies/filters`, headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproFilterEdit: (data, rut) => axios({method:'post', url:`${apiAuthUrl}/api/v1.1/companies/update/`+ rut, data:{...data},headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => err),
    faproFilterExport: () => axios({method:'get', url:`${apiAuthUrl}/api/v1.0/companies/filters/export`, responseType: 'blob', headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproFilterImport: (formData) => axios({method:'post', url:`${apiAuthUrl}/api/v1.0/companies/filters/import`, data: formData, headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => err),
    faproTax: () => axios({method:'get', url:`${apiAuthUrl}/api/v1.0/tax/folder`, headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproRegisteruser: (name, email, password, lastName, phone) => axios({method:'post', url:`${apiAuthUrl}/api/register`, data:{name, email, password, lastname:lastName, phone_number:phone, repeat_password:password}, headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproEnable: (rut)  => axios({method:'post', url:`${apiAuthUrl}/api/v1.0/company/enable`, data:{rut} , headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproDisable: (rut)  => axios({method:'post', url:`${apiAuthUrl}/api/v1.0/company/disable`, data:{rut} , headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),

    faproAccountList: () => axios({method:'get', url:`${apiAuthUrl}/api/admin/user`, headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproAccountUser: (id) => axios({method:'get', url:`${apiAuthUrl}/api/admin/user/${id}`, headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproAccountDelete: (id) => axios({method:'delete', url:`${apiAuthUrl}/api/admin/user/${id}`, headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproAccountUpdate: (id, form) => axios({method:'put', url:`${apiAuthUrl}/api/admin/user/${id}`, data: {name: form.first_name, lastname: form.last_name, phone_number: form.phone_number} ,headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproAccountCreate: (name, email, password, lastName, phone) => axios({method:'post', url:`${apiAuthUrl}/api/admin/user`, data:{name, email, password, lastname:lastName, phone_number:phone, repeat_password:password}, headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproGetPermission: () => axios({method:'get', url:`${apiAuthUrl}/api/permission`, headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproAddPermissionUser: (permission) => axios({method:'post', url:`${apiAuthUrl}/api/admin/add-permission`, data:{email: permission.email, permission: permission.permission} , headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproUpdatePermissionUser: (id, permission) => axios({method:'put', url:`${apiAuthUrl}/api/v1.0/admin/user/${id}/permission`, data: permission , headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),

    // Iva CTE Parser Service
    faproIvaCteParser: async (formData) => { return axios.post(taxFolderUrl, formData,{ responseType: 'blob', headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => res).catch((err) => { rejectLogin(); throw new Error(err.response.data.message); }) },

    faproOnboarding: () => axios({method:'get', baseURL: onboardingUrl, url:'/onboarding/'}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),

    // Download XML
    faproListAllCompanies: () => axios({method:'get', url:`${apiAuthUrl}/api/v1.0/company`, headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res)=> res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproDownloadXml: (type, rut, folio)  => axios({method:'get', url:`${apiAuthUrl}/api/v1.0/dte/xml?rut=${rut}&folio=${folio}&type=${type}`, headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res) =>  res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),

    //Emails 
    faproEmailList: () => axios({method:'get', url:`${apiAuthUrl}/api/v1.0/mail-notifications`, headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res)=> res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproEmailAdd: (email) => axios({method:'post', url:`${apiAuthUrl}/api/v1.0/mail-notifications`, data:{email: email}, headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res)=> res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproEmailInfo: (id) => axios({method:'get', url:`${apiAuthUrl}/api/v1.0/mail-notifications/${id}`, headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res)=> res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproEmailInfoEdit: (id, email) => axios({method:'put', url:`${apiAuthUrl}/api/v1.0/mail-notifications/${id}`, data:{email: email} ,headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res)=> res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
    faproEmailDelete: (id) => axios({method:'delete', url:`${apiAuthUrl}/api/v1.0/mail-notifications/${id}`, headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res)=> res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),

    //Permissions
    faproFilterGroup: (id, filter) => axios({method:'put', url:`${apiAuthUrl}/api/v1.0/admin/user/${id}/filters`, data:{branch: filter.branch, group: filter.group, executive: filter.executive}, headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}}).then((res)=> res).catch(err => {rejectLogin(); throw new Error(err.response.data.message)}),
}

export default Api;