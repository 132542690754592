import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { notificationApiGetDataStatus } from '../api/notificationApi';

const initialState = {
    status: 'idle',
    data: [],
    messageError : null
}

export const notificationSliceGetDataStatusThunk = createAsyncThunk(
    'notification/getDataStatus',
    async (
        _,
        {
            rejectWithValue,
            signal,
        }
    ) => {
        try {

            const {
                fapro
            } = await notificationApiGetDataStatus({
                signal
            })

            if(fapro.success) {
                return fapro.data
            } else {
                return rejectWithValue(fapro)
            }
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)


export const notificationSlice = createSlice({
    name: 'notificationSlice',
    initialState,
    reducers: {
        notificationSliceClearMessageError: (state, action) => {
            state.messageError = null
        },
        notificationSliceClearData: (state, action) => {
            state.data = []
        }
    },
    extraReducers: {
        [notificationSliceGetDataStatusThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = 'success'
        },
        [notificationSliceGetDataStatusThunk.rejected]: (state, action) => {
            state.data= []
            state.status = 'error'
            if(action.payload?.code === 401) {
                state.messageError = {
                    type: 'warning',
                    message: "Su sesión ha expirado, por favor vuelva a iniciar sesión."
                }
            } else {
                state.messageError = {
                    type: 'error',
                    message: action.payload.message
                }
            }

        },
        [notificationSliceGetDataStatusThunk.pending]: (state, action) => {
            state.data= []
            state.status = 'loading'
            state.messageError = null
        }
    }
  }
)


export const notificationSelectorGetDataStatus = createSelector(
    ({
        notificationSlice
    }) => notificationSlice.data,
    (data) => data 
)

export const notificationSelectorGetStatus = createSelector(
    ({
        notificationSlice
    }) => notificationSlice.status,
    (data) => data 
)


export const notificationSelectorGetMessageError = createSelector(
    ({
        notificationSlice
    }) => notificationSlice.messageError,
    (data) => data 
)

export const {
    notificationSliceClearMessageError,
    notificationSliceClearData
} = notificationSlice.actions