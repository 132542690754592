import { Box, CircularProgress } from "@mui/material";
import React from "react";

export const DataGridLoadingStyled = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        backgroundColor: (theme) => `${theme.palette.background.terciary}`,
        color: (theme) => `${theme.palette.terciary.main}`,
      }}
    >
      <CircularProgress />
    </Box>
  );
};
