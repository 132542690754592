import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { configApiGetFeatures, configApiToggleFeature } from '../api/configApi';
import { updateFeatures } from './authSlice';

const initialState = {
    features: [],
    status: 'idle',
    messageError: null,
}

export const getConfigFeaturesThunk = createAsyncThunk(
    'config/getFeatures',
    async (_, { rejectWithValue, signal }) => {
        try {
            const response = await configApiGetFeatures({ signal });
            if (response.success) {
                return response.data;
            } else {
                return rejectWithValue(response);
            }
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const toggleFeatureThunk = createAsyncThunk(
    'config/toggleFeature',
    async (featureId, { dispatch, getState, rejectWithValue, signal }) => {
        try {
            const response = await configApiToggleFeature({ codename: featureId, signal });
            if (response.success) {
                const isAvailable = response.message.includes("actualizada a True");

                const currentState = getState().authSlice.data;
                const updatedFeatures = isAvailable
                    ? [...currentState.user.features, { codename: featureId, available: true }]
                    : currentState.user.features.filter(feature => feature.codename !== featureId);
                
                dispatch(updateFeatures({ features: updatedFeatures })); 

                return { codename: featureId, available: isAvailable };
            } else {
                return rejectWithValue(response);
            }
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const configSlice = createSlice({
    name: 'configSlice',
    initialState,
    reducers: {
        clearMessageError: (state) => {
            state.messageError = null;
        },
    },
    extraReducers: {
        [getConfigFeaturesThunk.fulfilled]: (state, action) => {
            state.features = action.payload;
            state.status = 'succeeded';
        },
        [getConfigFeaturesThunk.rejected]: (state, action) => {
            state.status = 'failed';
            state.messageError = action.payload;
        },
        [getConfigFeaturesThunk.pending]: (state) => {
            state.status = 'loading';
        },
        [toggleFeatureThunk.fulfilled]: (state, action) => {
            const { codename, available } = action.payload;
            const index = state.features.findIndex(feature => feature.codename === codename);
            if (index !== -1) {
                state.features[index].available = available;
                state.messageError = { message: "Característica actualizada correctamente.", type: "success" };
            }
        },
        [toggleFeatureThunk.rejected]: (state, action) => {
            state.messageError = action.payload;
        },
    }
});

export const selectFeatures = createSelector(
    (state) => state.configSlice.features,
    (features) => features
);


export const selectFeatureStatus = createSelector(
    (state) => state.configSlice.status,
    (status) => status
);

export const selectMessageError = createSelector(
    (state) => state.configSlice.messageError,
    (messageError) => messageError
);

export const { clearMessageError } = configSlice.actions;

