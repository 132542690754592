
const shadows = [
    "none",
    "0px 1px 3px rgba(0,0,0,0.2), 0px 1px 1px rgba(0,0,0,0.14), 0px 2px 1px rgba(0,0,0,0.12)", // 1
    "0px 1px 5px rgba(0,0,0,0.2), 0px 2px 2px rgba(0,0,0,0.14), 0px 3px 1px rgba(0,0,0,0.12)", // 2
    "0px 1px 8px rgba(0,0,0,0.2), 0px 3px 4px rgba(0,0,0,0.14), 0px 3px 3px rgba(0,0,0,0.12)", // 3
    "0px 2px 4px rgba(0,0,0,0.2), 0px 3px 4px rgba(0,0,0,0.14), 0px 3px 3px rgba(0,0,0,0.12)", // 4
    "0px 2px 6px rgba(0,0,0,0.2), 0px 3px 5px rgba(0,0,0,0.14), 0px 3px 4px rgba(0,0,0,0.12)", // 5
    "0px 3px 5px rgba(0,0,0,0.2), 0px 3px 6px rgba(0,0,0,0.14), 0px 4px 5px rgba(0,0,0,0.12)", // 6
    "0px 3px 7px rgba(0,0,0,0.2), 0px 4px 6px rgba(0,0,0,0.14), 0px 4px 5px rgba(0,0,0,0.12)", // 7
    "0px 5px 15px rgba(0,0,0,0.3)", // 8
    "0px 4px 5px rgba(0,0,0,0.2), 0px 1px 10px rgba(0,0,0,0.14), 0px 2px 4px rgba(0,0,0,0.12)", // 9
    "0px 6px 10px rgba(0,0,0,0.2), 0px 1px 18px rgba(0,0,0,0.14), 0px 3px 5px rgba(0,0,0,0.12)", // 10
    "0px 6px 10px rgba(0,0,0,0.2), 0px 1px 18px rgba(0,0,0,0.14), 0px 3px 5px rgba(0,0,0,0.12)", // 11
    "0px 7px 8px rgba(0,0,0,0.2), 0px 2px 16px rgba(0,0,0,0.14), 0px 4px 6px rgba(0,0,0,0.12)", // 12
    "0px 7px 8px rgba(0,0,0,0.2), 0px 2px 16px rgba(0,0,0,0.14), 0px 4px 6px rgba(0,0,0,0.12)", // 13
    "0px 7px 8px rgba(0,0,0,0.2), 0px 2px 16px rgba(0,0,0,0.14), 0px 4px 6px rgba(0,0,0,0.12)", // 14
    "0px 8px 10px rgba(0,0,0,0.2), 0px 3px 14px rgba(0,0,0,0.14), 0px 5px 6px rgba(0,0,0,0.12)", // 15
    "0px 8px 10px rgba(0,0,0,0.2), 0px 3px 14px rgba(0,0,0,0.14), 0px 5px 6px rgba(0,0,0,0.12)", // 16
    "0px 8px 10px rgba(0,0,0,0.2), 0px 3px 14px rgba(0,0,0,0.14), 0px 5px 6px rgba(0,0,0,0.12)", // 17
    "0px 9px 12px rgba(0,0,0,0.2), 0px 3px 16px rgba(0,0,0,0.14), 0px 5px 7px rgba(0,0,0,0.12)", // 18
    "0px 9px 12px rgba(0,0,0,0.2), 0px 3px 16px rgba(0,0,0,0.14), 0px 5px 7px rgba(0,0,0,0.12)", // 19
    "0px 10px 14px rgba(0,0,0,0.2), 0px 4px 18px rgba(0,0,0,0.14), 0px 6px 8px rgba(0,0,0,0.12)", // 20
    "0px 10px 14px rgba(0,0,0,0.2), 0px 4px 18px rgba(0,0,0,0.14), 0px 6px 8px rgba(0,0,0,0.12)", // 21
    "0px 10px 14px rgba(0,0,0,0.2), 0px 4px 18px rgba(0,0,0,0.14), 0px 6px 8px rgba(0,0,0,0.12)", // 22
    "0px 11px 15px rgba(0,0,0,0.2), 0px 4px 20px rgba(0,0,0,0.14), 0px 6px 9px rgba(0,0,0,0.12)", // 23
    "0px 11px 15px rgba(0,0,0,0.2), 0px 4px 20px rgba(0,0,0,0.14), 0px 6px 9px rgba(0,0,0,0.12)", // 24
  ];
  
  export default shadows;
  