import axios from "axios";

const apiAuthUrl = process.env.REACT_APP_API_AUTH_URL;

export const linkCompaniesApiPostDataRutKey = async ({
  name,
  rut,
  password,
  factoring,
  executive,
  branch,
  group,
}) => {
  try {
    const rut_upper = rut.toString().toUpperCase();

    const res = await axios({
      method: "post",
      url: `${apiAuthUrl}/api/v1.1/company/create_from_password`,
      data: {
        rz: name,
        rut: rut_upper,
        password,
        factoring,
        executive,
        branch,
        group,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("fapro_token")}`,
      },
    });

    const data = await res.data;

    if (data.code === 1) {
      return {
        fapro: {
          success: true,
          code: data.code,
          message: data.message,
          status: data.status,
          data: data.data,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: data.code,
          message: data.message,
          status: data.status,
          data: null,
        },
      };
    }
  } catch (err) {
    if (err.response) {
      return {
        fapro: {
          success: false,
          code: err.response.data.code,
          message: err.response.data.message,
          status: err.response.data.status,
          data: null,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: 500,
          message: err.message || "Opps! Algo salió mal, intente más tarde.",
          status: "error",
          data: null,
        },
      };
    }
  }
};

export const linkCompaniesApiPostDataDigital = async ({
  name,
  rut,
  password,
  factoring,
  certificate,
  executive,
  branch,
  group,
}) => {
  try {
    const rut_upper = rut.toString().toUpperCase();
    const res = await axios({
      method: "post",
      url: `${apiAuthUrl}/api/v1.1/company/create_from_certificate`,
      data: {
        rz: name,
        rut: rut_upper,
        password,
        certificate,
        factoring,
        executive,
        branch,
        group,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("fapro_token")}`,
      },
    });

    const data = await res.data;

    if (data.code === 1) {
      return {
        fapro: {
          success: true,
          code: data.code,
          message: data.message,
          status: data.status,
          data: data.data,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: data.code,
          message: data.message,
          status: data.status,
          data: null,
        },
      };
    }
  } catch (err) {
    if (err.response) {
      return {
        fapro: {
          success: false,
          code: err.response.data.code,
          message: err.response.data.message,
          status: err.response.data.status,
          data: null,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: 500,
          message: err.message || "Opps! Algo salió mal, intente más tarde.",
          status: "error",
          data: null,
        },
      };
    }
  }
};
