import axios from "axios"

const taxFolderUrl = process.env.REACT_APP_TAX_FOLDER_URL|| "https://carpetatributaria.fapro.cl/api/cte_parser"

export const ivaApiPostFile = async ({
    file
}) => {

    try {


        let formData = new FormData();
        formData.append('document', file, 'file.pdf');
        
        const res = await axios.post(
            taxFolderUrl, 
            formData,
            { 
                responseType: 'blob', 
                headers: {
                    'Content-Type': 'multipart/form-data',
                } 
            }
        )

        const data = await res.data

        let url = window.URL.createObjectURL(new Blob([data]));

        return {
            fapro: {
                success: true,
                code: data.code,
                message: data.message,
                status: data.status,
                data: url
            }
        }
      

    } catch (err) {
        if (err.response) {
            return {
                fapro: {
                    success: false,
                    code: err.response.data?.code || 500,
                    message: err.response.data?.message || "Opps! Algo salió mal, intente más tarde.",
                    status: err.response.data.status || 'error',
                    data: null
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: 500,
                    message: err.message || 'Opps! Algo salió mal, intente más tarde.',
                    status: 'error',
                    data: null
                }
            }
        }
    }
}
