import React from "react";
import { ModalStyled } from "../ui/ModalStyled";
import {
  Box,
  Button,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Formik } from "formik";
import { InputStyled } from "../ui/InputStyled";
import { createCustomFieldSchema } from "../../utils/schemas";
import { authSliceLogoutThunk } from "../../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  patchCustomFieldsThunk,
  getCustomFieldsThunk,
} from "../../redux/slices/customFieldsSlice";
import { AutoCompleteStyled } from "../ui/AutoCompleteStyled";

export const CustomFieldsModalEdit = ({ show, onShow, data }) => {
  const [field_type, setFieldType] = React.useState(data.field_type);
  const [entity, setEntity] = React.useState(data.entity);
  const [required, setRequired] = React.useState(data.required);

  const handleChange = (event) => {
    setFieldType(event.target.value);
  };

  const handleChangeEntity = (event) => {
    setEntity(event.target.value);
  };

  const handleChangeRequired = (event) => {
    setRequired(event.target.value);
  };

  const initial_values = {
    custom_field_id: data.id,
    entity: data.entity,
    required: data.required,
    field_name: data.field_name,
    field_type: data.field_type,
  };

  if (data.field_type_list_values) {
    initial_values.field_type_list_values =
      data.field_type_list_values.split(",");
  }

  const dispatch = useDispatch();

  const history = useHistory();

  const handleOnSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    const data_ = {
      custom_field_id: values.custom_field_id,
      field_name: values.field_name,
      entity,
      field_type,
      required,
      order: 0,
    };

    if (field_type === "list") {
      data_.field_type_list_values = values.field_type_list_values.join(",");
    }

    dispatch(patchCustomFieldsThunk(data_))
      .unwrap()
      .then(() => {
        resetForm();
        onShow(null);
        dispatch(getCustomFieldsThunk());
      })
      .catch((err) => {
        if (err?.code === 401) {
          setTimeout(() => {
            dispatch(authSliceLogoutThunk()).finally(() => {
              history.replace("/");
            });
          }, 3000);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <ModalStyled
      show={show}
      onShow={onShow}
      title={"Modificar campo personalizado"}
    >
      <Box className="form-box-container md-scroll">
        <Formik
          initialValues={initial_values}
          onSubmit={handleOnSubmit}
          validationSchema={createCustomFieldSchema}
        >
          {({ isSubmitting, handleSubmit, errors, touched }) => (
            <>
              <Box
                component={"form"}
                onSubmit={handleSubmit}
                className="form-container"
              >
                <InputStyled
                  fullWidth
                  disabled={isSubmitting}
                  name="field_name"
                  label="Nombre del campo"
                  placeholder="Nombre del campo"
                  type="text"
                  inputProps={{
                    autoComplete: "off",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />

                <FormControl fullWidth>
                  <InputLabel id="tipo-de-cambio-label">
                    Tipo de Campo
                  </InputLabel>
                  <Select
                    name="field_type"
                    labelId="tipo-de-cambio-label"
                    id="tipo-campo-id"
                    value={field_type}
                    label="Tipo de Campo"
                    onChange={handleChange}
                    fullWidth
                  >
                    <MenuItem value={"text"}>Texto</MenuItem>
                    <MenuItem value={"number"}>Numérico</MenuItem>
                    <MenuItem value={"list"}>Lista (opciones)</MenuItem>
                    <MenuItem value={"checkbox"}>
                      Seleccion (Checkbox)
                    </MenuItem>
                  </Select>
                  {!field_type && errors.field_type && touched.field_type && (
                    <FormHelperText>{errors.field_type}</FormHelperText>
                  )}
                </FormControl>

                {field_type === "list" ? (
                  <AutoCompleteStyled
                    disabled={isSubmitting}
                    fullWidth
                    freeSolo
                    name={"field_type_list_values"}
                    label="Lista de opciones"
                    placeholder="Presiona enter para agregar"
                  />
                ) : null}

                <FormControl fullWidth>
                  <InputLabel id="tipo-de-entidad-label">
                    Tipo de Entidad
                  </InputLabel>
                  <Select
                    name="entity"
                    labelId="tipo-de-entidad-label"
                    id="tipo-de-entidad-id"
                    value={entity}
                    label="Tipo de Entidad"
                    onChange={handleChangeEntity}
                    fullWidth
                  >
                    <MenuItem value={"empresa"}>Empresa</MenuItem>
                    {/* <MenuItem value={"persona_natural"}>Persona Natural</MenuItem> */}
                  </Select>
                  {!entity && errors.entity && touched.entity && (
                    <FormHelperText>{errors.entity}</FormHelperText>
                  )}
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="tipo-de-entidad-label">
                    ¿El campo es requerido?
                  </InputLabel>
                  <Select
                    name="required"
                    labelId="tipo-de-entidad-label"
                    id="campo-requerido-id"
                    value={required}
                    label="¿El campo es requerido?"
                    onChange={handleChangeRequired}
                    fullWidth
                  >
                    <MenuItem value={1}>Si</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                  {!required && errors.required && touched.required && (
                    <FormHelperText>{errors.required}</FormHelperText>
                  )}
                </FormControl>

                <Box className="button-confirm-cancel-modal">
                  <Button
                    disabled={isSubmitting}
                    color="error"
                    type="button"
                    onClick={() => onShow(null)}
                  >
                    Cancelar
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Modificar
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Formik>
      </Box>
    </ModalStyled>
  );
};
