import React, { useEffect, useState, useCallback } from "react";
import PowerBi from "../../components/dashbaord/powerbi";
import { useDispatch, useSelector } from "react-redux";
import {
  pwbSelectorGetData,
  pwbSelectorGetMessageError,
  pwbSliceClearData,
  pwbSliceClearMessageError,
  pwbSliceGetDataThunk,
} from "../../redux/slices/pwbSlice";
import { useHistory, useLocation } from "react-router-dom";
import { authSliceLogoutThunk } from "../../redux/slices/authSlice";
import { SnackbarStyled } from "../../components/ui/SnackbarStyled";

const Container = () => {
  const dispatch = useDispatch();
  const data = useSelector(pwbSelectorGetData);
  const messageError = useSelector(pwbSelectorGetMessageError);
  const { pathname } = useLocation();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
    dispatch(pwbSliceClearMessageError());
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const promise = dispatch(pwbSliceGetDataThunk());
        await promise.unwrap();
      } catch (err) {
        if (err?.code === 401) {
          setTimeout(() => {
            dispatch(authSliceLogoutThunk()).finally(() => {
              history.replace("/");
            });
          }, 3000);
        }
      }
    };

    fetchData();

    return () => {
      dispatch(pwbSliceClearData());
    };
  }, [dispatch, history, pathname]);

  useEffect(() => {
    if (messageError && messageError.message) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [messageError]);

  useEffect(() => {
    return () => {
      dispatch(pwbSliceClearMessageError());
    };
  }, [dispatch]);

  return (
    <>
      {data?.embed_url && <PowerBi embedData={data} />}
      <SnackbarStyled
        message={messageError?.message || undefined}
        type={messageError?.type || undefined}
        key={JSON.stringify(messageError)}
        open={open}
        onClose={handleClose}
      />
    </>
  );
};

export default Container;
