import { Formik } from "formik";
import { InputStyled } from "../ui/InputStyled";
import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  linkCompaniesSliceClearMessageLinkPostRutKey,
  linkCompaniesSlicePostDataRutKey,
} from "../../redux/slices/linkCompaniesSlices";
import { linkRutPasswordSchema } from "../../utils/schemas";
import {
  authSelectorGetData,
  authSliceLogoutThunk,
} from "../../redux/slices/authSlice";
import { useHistory } from "react-router-dom";

const LoginForm = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { user } = useSelector(authSelectorGetData);

  const handleOnSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    dispatch(linkCompaniesSliceClearMessageLinkPostRutKey());
    dispatch(linkCompaniesSlicePostDataRutKey(values))
      .unwrap()
      .then((payload) => {
        if (typeof payload === "string") {
        } else if (typeof payload === "object") {
          resetForm();
        }
      })
      .catch((err) => {
        if (err?.code === 401) {
          setTimeout(() => {
            dispatch(authSliceLogoutThunk()).finally(() => {
              history.replace("/");
            });
          }, 3000);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <Formik
        initialValues={{
          rut: "",
          password: "",
          name: "",
          executive: user?.executive || "",
          branch: user?.branch || "",
          group: user?.group || "",
        }}
        onSubmit={handleOnSubmit}
        validationSchema={linkRutPasswordSchema}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
            component="form"
            onSubmit={handleSubmit}
          >
            <InputStyled
              fullWidth
              type="text"
              placeholder="Fapro"
              name="name"
              disabled={isSubmitting}
              label="Nombre de empresa"
              inputProps={{
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },
              }}
            />
            <InputStyled
              fullWidth
              type="text"
              placeholder="12345678-9"
              label={"RUT del SII"}
              name="rut"
              disabled={isSubmitting}
              inputProps={{
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },
              }}
            />
            <InputStyled
              fullWidth
              type="password"
              placeholder="********"
              label={"Clave SII"}
              name="password"
              disabled={isSubmitting}
              isPassword
              inputProps={{
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },
              }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={handleSubmit}
              className="submit-button"
              sx={{ width: '35%' }}
            >
              Vincular Empresa
            </Button>
          </Box>
        )}
      </Formik>
    </>
  );
};

export default LoginForm;
