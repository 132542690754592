import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadSelectorGetData,
  downloadSelectorGetDataStatus,
  downloadSelectorGetEnableData,
  downloadSelectorGetMessageError,
  downloadSelectorGetRowSelected,
  downloadSliceClearEnableData,
  downloadSliceClearMessageError,
  downloadSliceGetDataThunk,
  downloadSliceResetRowSelected,
  downloadSliceSetRowSelected,
} from "../../redux/slices/downloadSlice";
import {
  Box,
  CircularProgress,
  Divider,
  Typography,
  Tooltip,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { authSliceLogoutThunk } from "../../redux/slices/authSlice";
import { DataGridStyled } from "../../components/ui/DataGridStyled";
import moment from "moment";
import { DownloadDataGridCustomPagination } from "../../components/downloads/DownloadDataGridCustomPagination";
import { SnackbarStyled } from "../../components/ui/SnackbarStyled";
import { DataGridEmptyStyled } from "../../components/ui/DataGridEmptyStyled";
import { ChipStyled } from "../../components/ui/ChipStyled";
import { useTheme } from "../../context/ThemeContext";

const Container = () => {
  const dispatch = useDispatch();
  const { isNewMode } = useTheme();
  const { companies } = useSelector(downloadSelectorGetData);

  const dataStatus = useSelector(downloadSelectorGetDataStatus);
  const rowSelected = useSelector(downloadSelectorGetRowSelected);
  const enableData = useSelector(downloadSelectorGetEnableData);
  const messageError = useSelector(downloadSelectorGetMessageError);

  const history = useHistory();

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const promise = dispatch(downloadSliceGetDataThunk());

    promise.unwrap().catch((err) => {
      if (err?.code === 401) {
        setTimeout(() => {
          dispatch(authSliceLogoutThunk()).finally(() => {
            history.replace("/");
          });
        }, 3000);
      }
    });

    return () => {
      promise.abort();
    };
  }, [dispatch, history]);

  useEffect(() => {
    if (messageError && messageError.message && messageError.type) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [messageError]);

  useEffect(() => {
    dispatch(downloadSliceResetRowSelected());
    dispatch(downloadSliceClearMessageError());
    dispatch(downloadSliceClearEnableData());

    return () => {
      dispatch(downloadSliceResetRowSelected());
      dispatch(downloadSliceClearMessageError());
      dispatch(downloadSliceClearEnableData());
    };
  }, [dispatch]);

  const columns = [
    {
      headerName: "Nombre",
      field: "business_name",
      key: "business_name",
      flex: 1,
    },
    {
      headerName: "Rut",
      field: "rut",
      key: "rut",
      flex: 1,
    },
    {
      headerName: "Estado",
      field: "connected",
      key: "connected",
      flex: 1,
      valueGetter: ({ row }) => {
        let startDate = moment().startOf("hours");
        let disableDate = moment(row.disable_date);
        let dateToBeDisableLate = disableDate.diff(startDate, "hours");
        let tokenDate = moment(row.token_date);
        let currentDateMinus16Hours = moment().subtract(16, "hours");
        let disableDateMinus16Hours = disableDate.subtract(16, "hours");

        if (
          (row.connected === "1" && !row.disable_date && row.error === "0") ||
          (tokenDate >= currentDateMinus16Hours && !row.disable_date)
        ) {
          return "Conectada";
        } else if (
          (row.connected === "0" && row.error === "1" && !row.disable_date) ||
          (tokenDate < currentDateMinus16Hours &&
            (tokenDate < disableDateMinus16Hours || row.disable_date == null))
        ) {
          return "Desconectada";
        } else if (dateToBeDisableLate > 0) {
          return "Programada";
        } else if (
          row.disable_date ||
          (tokenDate < currentDateMinus16Hours &&
            tokenDate >= disableDateMinus16Hours) ||
          row.token_date == null
        ) {
          return "Deshabilitada";
        } else {
          return "Desconectada";
        }
      },
      renderCell: ({ row }) => {
        let tokenDate = moment(row.token_date);
        let currentDateMinus16Hours = moment().subtract(16, "hours");
        let disableDate = moment(row.disable_date);
        let disableDateMinus16Hours = disableDate.subtract(16, "hours");

        let label = "";
        let color = "";
        let bgcolor = "";
        let tooltip = "";
        let border = "";

        if (
          (row.connected === "1" && !row.disable_date && row.error === "0") ||
          (tokenDate >= currentDateMinus16Hours && !row.disable_date)
        ) {
          label = "Conectada";
          tooltip = "Habilitado";
          color = (theme) => theme.palette.success.main;
          bgcolor = (theme) => theme.palette.success.lightFapro;
          border = isNewMode ? `1.5px solid inherit` : "none";
        } else if (
          (row.connected === "0" && row.error === "1" && !row.disable_date) ||
          (tokenDate < currentDateMinus16Hours &&
            (tokenDate < disableDateMinus16Hours || row.disable_date == null))
        ) {
          label = "Desconectada";
          tooltip = "Desconectada";
          color = (theme) => theme.palette.warning.main;
          bgcolor = (theme) => theme.palette.warning.lightFapro;
          border = isNewMode ? `1.5px solid #b9a7ff` : "none";
        } else if (disableDate.diff(moment().startOf("hours"), "hours") > 0) {
          label = "Programada";
          tooltip = "Programado para deshabilitar";
          color = (theme) => theme.palette.schedule.main;
          bgcolor = (theme) => theme.palette.schedule.lightFapro;
          border = isNewMode ? `1.5px solid inheri` : "none";
        } else if (
          row.disable_date ||
          (tokenDate < currentDateMinus16Hours &&
            tokenDate >= disableDateMinus16Hours) ||
          row.token_date == null
        ) {
          label = "Deshabilitada";
          tooltip = "Deshabilitada";
          color = (theme) => theme.palette.error.main;
          bgcolor = (theme) => theme.palette.error.lightFapro;
          border = isNewMode ? `1.5px solid inherit` : "none";
        } else {
          label = "Desconectada";
          tooltip = "Desconectada";
          color = (theme) => theme.palette.warning.main;
          bgcolor = (theme) => theme.palette.warning.lightFapro;
          border = isNewMode ? `1.5px solid #b9a7ff` : "none";
        }

        return (
          <Tooltip arrow title={tooltip} enterDelay={500} leaveDelay={200}>
            <span>
              <ChipStyled
                label={label}
                size="small"
                sx={{
                  borderRadius: isNewMode ? 24 : 1,
                  color: color,
                  bgcolor: bgcolor,
                  py: 2,
                  px: 1,
                  width: isNewMode ? 128 : "auto",
                  border: border,
                }}
              />
            </span>
          </Tooltip>
        );
      },
    },
    {
      headerName: "Fecha de Deshabilitación",
      field: "disable_date",
      key: "disable_date",
      flex: 2,
      type: "string",
      valueGetter: ({ row }) => {
        if (row?.disable_date) {
          return `${moment(row.disable_date).format("DD/MM/YYYY hh:mm A")}`;
        } else {
          return "-  -  -";
        }
      },
    },
    {
      headerName: "Fecha de Creación",
      field: "created_at",
      key: "created_at",
      flex: 2,
      type: "string",
      valueGetter: ({ row }) => {
        if (row?.created_at) {
          return `${moment(row.created_at).format("DD/MM/YYYY hh:mm A")}`;
        } else {
          return "-  -  -";
        }
      },
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        gap: 2,
      }}
      bgcolor={"transparent"}
    >
      <Box p={0} width="100%" bgcolor={"transparent"}>
        <DataGridStyled
          sx={{
            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
              {
                display: "none",
              },
            "& .MuiDataGrid-columnHeaderCheckbox": {
              outline: "none !important",
            },
          }}
          showExportOptions={true}
          title={"Centro de Gestión de Descargas"}
          columns={columns}
          rows={companies}
          checkboxSelection
          disableRowSelectionOnClick
          loading={dataStatus === "loading"}
          isRowSelectable={(params) => {
            return true;
          }}
          autoHeight={true}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            dispatch(downloadSliceSetRowSelected(newRowSelectionModel));
          }}
          hideFooterSelectedRowCount={true}
          rowSelectionModel={rowSelected}
          slots={{
            pagination: DownloadDataGridCustomPagination,
            noRowsOverlay: DataGridEmptyStyled,
          }}
          exportOptionsParsed={(data) => {
            return {
              Nombre: data.business_name,
              RUT: data.rut,
              Estado: data.connected,
              "Fecha de Deshabilitación": data.disable_date,
              "Fecha de Creación": data.created_at,
            };
          }}
          exportOptionsName={`Centro de Gestión de Descargas - ${moment().format(
            "DD-MM-YYYY"
          )}`}
        />
      </Box>
      <Divider />
      <Box
        bgcolor={"background.terciary"}
        p={2}
        borderRadius={2}
        width={"100%"}
        height={300}
        minHeight={300}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
      >
        <Box
          sx={{
            border: 1.5,
            borderColor: "info.secondary",
            height: "100%",
            bgcolor: "transparent",
            width: "100%",
          }}
          maxHeight={270}
          borderRadius={2}
          py={1}
          px={2}
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          overflow={"hidden"}
        >
          <Box
            width={"100%"}
            sx={{
              borderBottom: 1.25,
              borderColor: "info.secondary",
            }}
            pb={0.25}
            mb={1}
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              variant="body2"
              component="p"
              textAlign={isNewMode ? "center" : "left"}
              sx={
                isNewMode
                  ? {
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: (theme) => theme.palette.text.purple,
                    }
                  : { color: "info.main", fontWeight: 600, fontSize: ".95rem" }
              }
            >
              Detalles de la Habilitación/Deshabilitación
            </Typography>
          </Box>
          <Box
            width={"100%"}
            height={"100%"}
            overflow="auto"
            className="warning-scroll"
            pl={1}
            my={0.5}
            position={"relative"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            {dataStatus === "loading" && (
              <Box
                position={"absolute"}
                top={0}
                left={0}
                width={"100%"}
                height={"100%"}
                display="flex"
                justifyContent="center"
                alignItems="center"
                zIndex={10}
              >
                <CircularProgress color="info" size={30} />
              </Box>
            )}
            {!(dataStatus === "loading") &&
              (enableData && enableData?.data ? (
                enableData.data?.error_credentials_ruts?.map((err, index) => (
                  <Typography
                    key={`${err}-${index}`}
                    variant="body2"
                    component="p"
                    sx={{
                      color: "text.muted",
                      fontWeight: 500,
                      fontSize: ".8rem",
                      "&:not(:last-of-type)": {
                        marginBottom: ".5rem",
                      },
                    }}
                  >
                    &#x2022;{" "}
                    {`La empresa con rut ${err} cambio sus credenciales de accesos`}
                  </Typography>
                ))
              ) : (
                <Box
                  position={"absolute"}
                  top={0}
                  left={0}
                  width={"100%"}
                  height={"100%"}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  zIndex={10}
                >
                  <Typography variant="body2" component="p" color="text.muted">
                    No hay errores
                  </Typography>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
      <SnackbarStyled
        message={messageError?.message || undefined}
        type={messageError?.type || undefined}
        key={JSON.stringify(messageError)}
        open={open}
        onClose={handleClose}
      />
    </Box>
  );
};

export default Container;
