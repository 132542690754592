import React from "react";
import { Box } from "@mui/material";
import Lottie from "lottie-react"; 
import gif from "../../components/assets/LoadedFapro.json";

const Loader = () => {
  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor="background.terciary"
    >
      <Lottie
        animationData={gif}
        loop={true}
        style={{ width: 100, height: 100 }}
      />
    </Box>
  );
};

export default Loader;
