import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  debtorApiDisableBlack,
  debtorApiDisableWhite,
  debtorApiEnableBlack,
  debtorApiEnableWhite,
  debtorApiGetDataBlack,
  debtorApiGetDataWhite,
} from "../api/debtorApi";

const initialState = {
  whiteData: [],
  blackData: [],
  messageError: null,
  enableData: null,
  disableData: null,
  rowSelected: [],
  status: "idle",
  loadingMassive: false,
};

export const debtorWhiteSliceGetDataThunk = createAsyncThunk(
  "debtor/getDataWhite",
  async (_, { rejectWithValue, signal }) => {
    try {
      const { fapro } = await debtorApiGetDataWhite({
        signal,
      });

      if (fapro.success) {
        return fapro.data;
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const debtorWhiteSliceEnableThunk = createAsyncThunk(
  "debtor/enableDataWhite",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { fapro } = await debtorApiEnableWhite(payload);

      if (fapro.success) {
        return {
          ...fapro,
          message:
            payload.data.length > 1
              ? "Se agregaron a la lista blanca de Empresas"
              : "Se agrego a la lista blanca de Empresas",
        };
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const debtorWhiteSliceDisableThunk = createAsyncThunk(
  "debtor/disableDataWhite",
  async (payload, { rejectWithValue }) => {
    try {
      const { fapro } = await debtorApiDisableWhite(payload);

      if (fapro.success) {
        return payload.length > 1
          ? "Se eliminaron de la lista blanca de Empresas"
          : "Se elimini de la lista blanca de Empresas";
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const debtorBlackSliceGetDataThunk = createAsyncThunk(
  "debtor/getDataBlack",
  async (_, { rejectWithValue, signal }) => {
    try {
      const { fapro } = await debtorApiGetDataBlack({
        signal,
      });

      if (fapro.success) {
        return fapro.data;
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const debtorBlackSliceEnableThunk = createAsyncThunk(
  "debtor/enableDataBlack",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { fapro } = await debtorApiEnableBlack(payload);

      if (fapro.success) {
        return {
          ...fapro,
          message:
            payload.data.length > 1
              ? "Se agregaron a la lista negra de Empresas"
              : "Se agrego a la lista negra de Empresas",
        };
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const debtorBlackSliceDisableThunk = createAsyncThunk(
  "debtor/disableDataBlack",
  async (payload, { rejectWithValue }) => {
    try {
      const { fapro } = await debtorApiDisableBlack(payload);

      if (fapro.success) {
        return payload.length > 1
          ? "Se eliminaron de la lista negra de Empresas"
          : "Se elimino de la lista negra de Empresas";
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const debtorSlice = createSlice({
  name: "debtorSlice",
  initialState,
  reducers: {
    debtorSliceClearMessageError: (state, action) => {
      state.messageError = null;
    },
    debtorSliceResetRowSelected: (state, action) => {
      state.rowSelected = [];
    },
    debtorSliceSetRowSelected: (state, action) => {
      state.rowSelected = action.payload;
    },
    debtorSliceClearEnableData: (state, action) => {
      state.enableData = null;
    },
  },
  extraReducers: {
    [debtorWhiteSliceGetDataThunk.fulfilled]: (state, action) => {
      state.whiteData = action.payload;
      state.status = "success";
    },
    [debtorWhiteSliceGetDataThunk.rejected]: (state, action) => {
      state.whiteData = [];
      state.status = "error";
      if (action.payload?.code === 401) {
        state.messageError = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.messageError = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [debtorWhiteSliceGetDataThunk.pending]: (state, action) => {
      state.whiteData = [];
      state.status = "loading";
    },
    [debtorWhiteSliceEnableThunk.fulfilled]: (state, action) => {
      state.enableData = action.payload.data;
      state.messageError = {
        type: "success",
        message: action.payload.message,
      };
      state.loadingMassive = false;
    },
    [debtorWhiteSliceEnableThunk.rejected]: (state, action) => {
      state.loadingMassive = false;

      if (action.payload?.code === 401) {
        state.messageError = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.enableData = action?.payload || null;
        state.messageError = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [debtorWhiteSliceEnableThunk.pending]: (state, action) => {
      state.loadingMassive = true;

      state.enableData = null;
      state.messageError = null;
    },

    [debtorWhiteSliceDisableThunk.fulfilled]: (state, action) => {
      state.loadingMassive = false;

      state.disableData = action.payload;
      state.messageError = {
        type: "success",
        message: action.payload,
      };
    },
    [debtorWhiteSliceDisableThunk.rejected]: (state, action) => {
      state.loadingMassive = false;

      state.disableData = null;
      if (action.payload?.code === 401) {
        state.messageError = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.messageError = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [debtorWhiteSliceDisableThunk.pending]: (state, action) => {
      state.loadingMassive = true;

      state.enableData = null;
      state.messageError = null;
    },

    [debtorBlackSliceGetDataThunk.fulfilled]: (state, action) => {
      state.blackData = action.payload;
      state.status = "success";
    },
    [debtorBlackSliceGetDataThunk.rejected]: (state, action) => {
      state.blackData = [];
      state.status = "error";
      if (action.payload?.code === 401) {
        state.messageError = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.messageError = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [debtorBlackSliceGetDataThunk.pending]: (state, action) => {
      state.blackData = [];
      state.status = "loading";
    },
    [debtorBlackSliceEnableThunk.fulfilled]: (state, action) => {
      state.loadingMassive = false;
      state.enableData = action.payload.data;
      state.messageError = {
        type: "success",
        message: action.payload.message,
      };
    },
    [debtorBlackSliceEnableThunk.rejected]: (state, action) => {
      state.loadingMassive = false;

      if (action.payload?.code === 401) {
        state.messageError = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.enableData = action?.payload || null;
        state.messageError = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [debtorBlackSliceEnableThunk.pending]: (state, action) => {
      state.loadingMassive = true;
      state.enableData = null;
      state.messageError = null;
    },

    [debtorBlackSliceDisableThunk.fulfilled]: (state, action) => {
      state.loadingMassive = false;
      state.enableData = action.payload.data;
      state.messageError = {
        type: "success",
        message: action.payload,
      };
    },
    [debtorBlackSliceDisableThunk.rejected]: (state, action) => {
      state.loadingMassive = false;
      if (action.payload?.code === 401) {
        state.messageError = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.messageError = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [debtorBlackSliceDisableThunk.pending]: (state, action) => {
      state.loadingMassive = true;
      state.enableData = null;
      state.messageError = null;
    },
  },
});

export const debtorSelectorGetDataWhite = createSelector(
  ({ debtorSlice }) => debtorSlice.whiteData,
  (data) => data
);

export const debtorSelectorGetLoadingMassive = createSelector(
  ({ debtorSlice }) => debtorSlice.loadingMassive,
  (data) => data
);

export const debtorSelectorGetDataBlack = createSelector(
  ({ debtorSlice }) => debtorSlice.blackData,
  (data) => data
);

export const debtorSelectorGetDataStatus = createSelector(
  ({ debtorSlice }) => debtorSlice.status,
  (data) => data
);

export const debtorSelectorGetMessageError = createSelector(
  ({ debtorSlice }) => debtorSlice.messageError,
  (data) => data
);

export const debtorSelectorGetRowSelected = createSelector(
  ({ debtorSlice }) => debtorSlice.rowSelected,
  (data) => data
);

export const debtorSelectorGetEnableData = createSelector(
  ({ debtorSlice }) => debtorSlice.enableData,
  (data) => data
);

export const {
  debtorSliceClearMessageError,
  debtorSliceResetRowSelected,
  debtorSliceSetRowSelected,
  debtorSliceClearEnableData,
} = debtorSlice.actions;