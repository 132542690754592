import { useEffect } from "react";
import IFrameWebUsage from "../../components/webUsage/IFrameWebUsage";
import Layout from "../../components/commons/PrivateLayoutStyled";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import { authSelectorGetData } from "../../redux/slices/authSlice";
import { useSelector } from "react-redux";

const Container = () => {
  const {
    user: { permissions: userPermissions, is_admin: isAdmin },
  } = useSelector(authSelectorGetData);

  let history = useHistory();

  useEffect(() => {
    const hasAddAccountsPermission = userPermissions.some(
      (data) => data.codename === "add_accounts"
    );

    if (!isAdmin && !hasAddAccountsPermission) {
      history.push("/dashboard");
      message.error("No tienes permisos para entrar a este módulo", 4);
    }
  }, [history, isAdmin, userPermissions]);

  return (
    <>
      <Layout>
        <IFrameWebUsage />
      </Layout>
    </>
  );
};

export default Container;
