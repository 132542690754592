import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CardStyled from '../../components/ui/CardStyled'
import { ProfileUserForm } from '../../components/profile/ProfileUserForm'
import { useDispatch, useSelector } from 'react-redux';
import { authSelectorGetMessageErrorUser, authSliceClearMessageUserError } from '../../redux/slices/authSlice';
import { SnackbarStyled } from '../../components/ui/SnackbarStyled';

export default function ProfileUser() {

    const dispatch = useDispatch();

    const messageErrorUser = useSelector(authSelectorGetMessageErrorUser);

    const [open, setOpen] = useState(false)
    const handleClose = () => {
        setOpen(false)
        dispatch(authSliceClearMessageUserError())
    }
    
    useEffect(() => {
        dispatch(authSliceClearMessageUserError())
      return () => {
        dispatch(authSliceClearMessageUserError())
      }
    }, [])

    useEffect(() => {
        if(messageErrorUser && messageErrorUser?.message) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [messageErrorUser])
    


  return (
    <Box
        bgcolor={"transparent"}
        display={"flex"}
        justifyContent="center"
        alignItems="center"
        width="100%"
        // mt={{
        //     xs: 1,
        //     sm: 2,
        //     md: 3,
        //     lg: 4,
        // }}
        height={"94vh"}
    >
        <CardStyled
            title="Centro de Edición de Usuario"
            mx="auto"
        >
            <ProfileUserForm/>
        </CardStyled>
        <SnackbarStyled
            message={messageErrorUser?.message||undefined}
            type={messageErrorUser?.type||undefined}
            key={JSON.stringify(messageErrorUser)}
            open={open}
            onClose={handleClose}
        />
    </Box>
  )
}
