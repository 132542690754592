import axios from "axios"

const apiAuthUrl = process.env.REACT_APP_API_AUTH_URL || "https://api.fapro.app"

// const localStorage.getItem('fapro_token') = localStorage.getItem('fapro_token')

export const taxApiGetData = async ({
    signal
}) => {

    try {

        const res = await axios(
            {
                method:'get', 
                url:`${apiAuthUrl}/api/v1.0/tax_folder/company/list`, 
                headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`},
                signal
            }
        )

        const data = await res.data

        if (data.code === 1) {
            return {
                fapro: {
                    success: true,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: data.data
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: null
                }
            }
        }

    } catch (err) {
        if (err.response) {
            return {
                fapro: {
                    success: false,
                    code: err.response.data.code,
                    message: err.response.data.message,
                    status: err.response.data.status,
                    data: null
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: 500,
                    message: err.message || 'Opps! Algo salió mal, intente más tarde.',
                    status: 'error',
                    data: null
                }
            }
        }
    }
}


export const taxApiGetDataFolderEmbedUrl = async ({
    company_id,
    signal
}) => {

    try {

        const res = await axios(
            {
                method:'get', 
                url:`${apiAuthUrl}/api/taxfolder/url_embed/${company_id}`, 
                headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`},
                signal
            }
        )

        const data = await res.data

        if (data.code === 200) {
            return {
                fapro: {
                    success: true,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: data.data
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: null
                }
            }
        }

    } catch (err) {
        if (err.response) {
            return {
                fapro: {
                    success: false,
                    code: err.response.data.code,
                    message: err.response.data.message,
                    status: err.response.data.status,
                    data: null
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: 500,
                    message: err.message || 'Opps! Algo salió mal, intente más tarde.',
                    status: 'error',
                    data: null
                }
            }
        }
    }
}

