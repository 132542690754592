import axios from "axios"

const apiAuthUrl = process.env.REACT_APP_API_AUTH_URL || "https://api.fapro.app"

// const localStorage.getItem('fapro_token') = localStorage.getItem('fapro_token')

export const downloadApiGetData = async ({
    signal
}) => {

    try {
        const res = await axios(
            {
                method:'get', 
                url:`${apiAuthUrl}/api/v1.0/companies/filters`, 
                headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`},
                signal
            }
        )

        const data = await res.data

        if (data.code === 1) {
            return {
                fapro: {
                    success: true,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: data.data
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: null
                }
            }
        }

    } catch (err) {
        if (err.response) {
            return {
                fapro: {
                    success: false,
                    code: err.response.data.code,
                    message: err.response.data.message,
                    status: err.response.data.status,
                    data: null
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: 500,
                    message: err.message || 'Opps! Algo salió mal, intente más tarde.',
                    status: 'error',
                    data: null
                }
            }
        }
    }
}


export const downloadApiEnable = async (payload) => {

    try {

        const body = payload.map(({rut}) => (rut))

        const res = await axios({
                    method:'post',
                    url:`${apiAuthUrl}/api/v1.0/company/enable`,
                    data: {
                        ruts: body
                    },
                    headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}
                })
        const data = await res.data

        return {
            fapro: {
                success: true,
                code: 200,
                message: data.message,
                status: "success",
                data: data?.data || null
            }
        }
    

    } catch (err) {
        if (err.response) {
            return {
                fapro: {
                    success: false,
                    code: err.response.data.code,
                    status: err.response.data.status,
                    data: err.response?.data?.data || null,
                    message:  err.response?.data?.data?.message || "Error al habilitar las empresas seleccionadas"
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: 500,
                    message: err.message || 'Opps! Algo salió mal, intente más tarde.',
                    status: 'error',
                    data: null
                }
            }
        }
    }
}

export const downloadApiDisable = async (payload) => {

    try {

        const body = payload.map(({rut}) => (rut))

        const res = await axios({
                    method:'post',
                    url:`${apiAuthUrl}/api/v1.0/company/disable`,
                    data: {
                        ruts: body
                    },
                    headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}
                })

        const data = await res.data
        return {
            fapro: {
                success: true,
                code: 200,
                message: data.message,
                status: "success",
                data
            }
        }


    } catch (err) {
        if (err.response) {
            return {
                fapro: {
                    success: false,
                    code: err.response.data.code,
                    message: err.response.data.message,
                    status: err.response.data.status,
                    data: null
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: 500,
                    message: err.message || 'Opps! Algo salió mal, intente más tarde.',
                    status: 'error',
                    data: null
                }
            }
        }
    }
}
