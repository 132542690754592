import React from "react";
import {
  Box,
  Grid,
  Card,
  CardActionArea,
  Skeleton,
  Tooltip,
  IconButton,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import MovieIcon from "@mui/icons-material/Movie";

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const OldStyleDialogContent = ({ status, data, tabs, handleChangeTabs }) => (
  <Box className="sidenav-scroll" bgcolor={"transparent"}>
    <Box
      bgcolor={"transparent"}
      width={"100%"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"row"}
    >
      <Tabs
        value={tabs}
        onChange={handleChangeTabs}
        aria-label="Fapro onboarding tabs"
      >
        <Tab label="Manuales en PDF" {...a11yProps(0)} />
        <Tab label="Manuales en video" {...a11yProps(1)} />
      </Tabs>
    </Box>
    <TabPanel value={tabs} index={0}>
      <Grid
        container
        sx={{
          mt: 2,
        }}
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1" component={"div"} gutterBottom>
            Descarga nuestros{" "}
            <Typography component={"span"} fontWeight={600}>
              manuales en PDF
            </Typography>{" "}
            para tener acceso a información detallada sobre nuestras
            funcionalidades.
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sx={{
            mt: 1,
          }}
        >
          {status === "loading" || status === "idle" ? (
            <>
              {[...Array(4)].map((_, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  height={100}
                  p={0.5}
                >
                  <Card
                    sx={{
                      height: "100%",
                      width: "100%",
                      borderRadius: 3,
                    }}
                  >
                    <CardActionArea
                      sx={{
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="100%"
                      />
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </>
          ) : (
            <>
              {data.filter((i) => i.type === "Pdf").length === 0 ? (
                <Grid item xs={12} height={100}>
                  <Card
                    sx={{
                      height: "100%",
                      width: "100%",
                      borderRadius: 3,
                      boxShadow: 0,
                      bgcolor: (theme) =>
                        `${theme.palette.background.secondary}55`,
                    }}
                  >
                    <CardActionArea
                      sx={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        component={"div"}
                        color={"text.muted"}
                      >
                        No hay manuales disponibles.
                      </Typography>
                    </CardActionArea>
                  </Card>
                </Grid>
              ) : (
                <>
                  {data
                    .filter((i) => i.type === "Pdf")
                    .map((item, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        height={{
                          xs: 100,
                        }}
                        p={0.5}
                      >
                        <Tooltip title={item.name}>
                          <Card
                            sx={{
                              height: "100%",
                              width: "100%",
                              borderRadius: 3,
                              boxShadow: 0,
                              bgcolor: (theme) =>
                                `${theme.palette.background.secondary}55`,
                            }}
                            onClick={() => {
                              window.open(item.link, "_blank");
                            }}
                          >
                            <CardActionArea
                              sx={{
                                height: "100%",
                                width: "100%",
                              }}
                            >
                              <Box
                                width={"100%"}
                                height={"100%"}
                                display={"flex"}
                                flexDirection={"row"}
                                alignItems={"center"}
                                justifyContent={"flex-start"}
                                gap={1.5}
                                pl={1.5}
                                pr={1}
                              >
                                <IconButton
                                  color="primary"
                                  sx={{
                                    background: (theme) =>
                                      theme.palette.gradient.main,
                                  }}
                                >
                                  <MovieIcon
                                    sx={{
                                      color: (theme) =>
                                        theme.palette.common.white,
                                    }}
                                  />
                                </IconButton>
                                <Box
                                  display={"flex"}
                                  flexDirection={"column"}
                                  alignItems={"flex-start"}
                                  justifyContent={"center"}
                                  gap={0.07}
                                >
                                  <Typography
                                    variant="caption"
                                    component="div"
                                    color={"text.muted"}
                                    className="text-limit-1line"
                                    fontSize={10}
                                  >
                                    {item.type}
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    component="div"
                                    className="text-limit-1line"
                                    fontSize={14}
                                  >
                                    {item.name}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    component="div"
                                    className="text-limit-1line"
                                    fontSize={12}
                                  >
                                    {item.detail}
                                  </Typography>
                                </Box>
                              </Box>
                            </CardActionArea>
                          </Card>
                        </Tooltip>
                      </Grid>
                    ))}
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </TabPanel>
    <TabPanel value={tabs} index={1}>
      <Grid
        container
        sx={{
          mt: 2,
        }}
      >
        <Grid
          container
          item
          xs={12}
          sx={{
            mt: 1,
          }}
        >
          {status === "loading" || status === "idle" ? (
            <>
              {[...Array(4)].map((_, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  height={100}
                  p={0.5}
                >
                  <Card
                    sx={{
                      height: "100%",
                      width: "100%",
                      borderRadius: 3,
                      boxShadow: 0,
                      bgcolor: (theme) =>
                        `${theme.palette.background.secondary}55`,
                    }}
                  >
                    <CardActionArea
                      sx={{
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="100%"
                      />
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </>
          ) : (
            <>
              {data.filter((i) => i.type === "Video").length === 0 ? (
                <Grid item xs={12} height={100}>
                  <Card
                    sx={{
                      height: "100%",
                      width: "100%",
                      borderRadius: 3,
                    }}
                  >
                    <CardActionArea
                      sx={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        component={"div"}
                        color={"text.muted"}
                      >
                        No hay manuales disponibles.
                      </Typography>
                    </CardActionArea>
                  </Card>
                </Grid>
              ) : (
                <>
                  {data
                    .filter((i) => i.type === "Video")
                    .map((item, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        height={{
                          xs: 100,
                        }}
                        p={0.5}
                      >
                        <Tooltip title={item.name}>
                          <Card
                            sx={{
                              height: "100%",
                              width: "100%",
                              borderRadius: 3,
                              boxShadow: 0,
                              bgcolor: (theme) =>
                                `${theme.palette.background.secondary}55`,
                            }}
                            onClick={() => {
                              window.open(item.link, "_blank");
                            }}
                          >
                            <CardActionArea
                              sx={{
                                height: "100%",
                                width: "100%",
                              }}
                            >
                              <Box
                                width={"100%"}
                                height={"100%"}
                                display={"flex"}
                                flexDirection={"row"}
                                alignItems={"center"}
                                justifyContent={"flex-start"}
                                gap={1.5}
                                pl={1.5}
                                pr={1}
                              >
                                <IconButton
                                  color="primary"
                                  sx={{
                                    background: (theme) =>
                                      theme.palette.gradient.main,
                                  }}
                                >
                                  <MovieIcon
                                    sx={{
                                      color: (theme) =>
                                        theme.palette.common.white,
                                    }}
                                  />
                                </IconButton>
                                <Box
                                  display={"flex"}
                                  flexDirection={"column"}
                                  alignItems={"flex-start"}
                                  justifyContent={"center"}
                                  gap={0.07}
                                >
                                  <Typography
                                    variant="caption"
                                    component="div"
                                    color={"text.muted"}
                                    className="text-limit-1line"
                                    fontSize={10}
                                  >
                                    {item.type}
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    component="div"
                                    className="text-limit-1line"
                                    fontSize={14}
                                  >
                                    {item.name}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    component="div"
                                    className="text-limit-1line"
                                    fontSize={12}
                                  >
                                    {item.detail}
                                  </Typography>
                                </Box>
                              </Box>
                            </CardActionArea>
                          </Card>
                        </Tooltip>
                      </Grid>
                    ))}
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </TabPanel>
  </Box>
);

const NewStyleDialogContent = ({ status, data, tabs, handleChangeTabs }) => (
  <Box className="sidenav-scroll" bgcolor={"transparent"}>
    <Grid item xs={12}>
      <Typography variant="h3" textAlign="center">
        Manuales
      </Typography>
    </Grid>
    <Box
      bgcolor={"transparent"}
      width={"100%"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"row"}
    >
      <Tabs
        value={tabs}
        onChange={handleChangeTabs}
        aria-label="Fapro onboarding tabs"
        indicatorColor="secondary"
        sx={{
          "& .MuiTab-root": {
            minWidth: 400,
            fontSize: "14pt",
            color: "text.perfil",
            padding: "24px 24px 10px 10px",
            fontWeight: 600,
          },
        }}
      >
        <Tab label="PDF" {...a11yProps(0)} />
        <Tab label="Video" {...a11yProps(1)} />
      </Tabs>
    </Box>
    <TabPanel value={tabs} index={0}>
      <Grid container sx={{ mt: 2 }}>
        <Grid container item xs={12} sx={{ mt: 1 }}>
          {status === "loading" || status === "idle" ? (
            <>
              {[...Array(4)].map((_, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  height={100}
                  p={0.5}
                >
                  <Card sx={{ height: "100%", width: "100%", borderRadius: 3 }}>
                    <CardActionArea sx={{ height: "100%", width: "100%" }}>
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="100%"
                      />
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </>
          ) : (
            <>
              {data.filter((i) => i.type === "Pdf").length === 0 ? (
                <Grid item xs={12} height={100}>
                  <Card
                    sx={{
                      height: "100%",
                      width: "100%",
                      borderRadius: 3,
                      boxShadow: 0,
                      bgcolor: (theme) =>
                        `${theme.palette.background.secondary}55`,
                    }}
                  >
                    <CardActionArea
                      sx={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        component={"div"}
                        color={"text.muted"}
                      >
                        No hay manuales disponibles.
                      </Typography>
                    </CardActionArea>
                  </Card>
                </Grid>
              ) : (
                <>
                  {data
                    .filter((i) => i.type === "Pdf")
                    .map((item, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        height={100}
                        p={0.5}
                      >
                        <Tooltip title={item.name}>
                          <Card
                            sx={{
                              height: "100%",
                              width: "100%",
                              borderRadius: 3,
                              boxShadow: 0,
                              bgcolor: (theme) =>
                                `${theme.palette.background.secondary}55`,
                            }}
                            onClick={() => {
                              window.open(item.link, "_blank");
                            }}
                          >
                            <CardActionArea
                              sx={{ height: "100%", width: "100%" }}
                            >
                              <Box
                                width={"100%"}
                                height={"100%"}
                                display={"flex"}
                                flexDirection={"row"}
                                alignItems={"center"}
                                justifyContent={"flex-start"}
                                gap={1.5}
                                pl={1.5}
                                pr={1}
                              >
                                <IconButton
                                  color="primary"
                                  sx={{
                                    background: (theme) =>
                                      theme.palette.gradient.main,
                                  }}
                                >
                                  <MovieIcon
                                    sx={{
                                      color: (theme) =>
                                        theme.palette.common.white,
                                    }}
                                  />
                                </IconButton>
                                <Box
                                  display={"flex"}
                                  flexDirection={"column"}
                                  alignItems={"flex-start"}
                                  justifyContent={"center"}
                                  gap={0.07}
                                >
                                  <Typography
                                    variant="caption"
                                    component="div"
                                    color={"text.muted"}
                                    className="text-limit-1line"
                                    fontSize={10}
                                  >
                                    {item.type}
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    component="div"
                                    className="text-limit-1line"
                                    fontSize={14}
                                  >
                                    {item.name}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    component="div"
                                    className="text-limit-1line"
                                    fontSize={12}
                                  >
                                    {item.detail}
                                  </Typography>
                                </Box>
                              </Box>
                            </CardActionArea>
                          </Card>
                        </Tooltip>
                      </Grid>
                    ))}
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </TabPanel>
    <TabPanel value={tabs} index={1}>
      <Grid container sx={{ mt: 2 }}>
        <Grid container item xs={12} sx={{ mt: 1 }}>
          {status === "loading" || status === "idle" ? (
            <>
              {[...Array(4)].map((_, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  height={100}
                  p={0.5}
                >
                  <Card
                    sx={{
                      height: "100%",
                      width: "100%",
                      borderRadius: 3,
                      boxShadow: 0,
                      bgcolor: (theme) =>
                        `${theme.palette.background.secondary}55`,
                    }}
                  >
                    <CardActionArea sx={{ height: "100%", width: "100%" }}>
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="100%"
                      />
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </>
          ) : (
            <>
              {data.filter((i) => i.type === "Video").length === 0 ? (
                <Grid item xs={12} height={100}>
                  <Card
                    sx={{
                      height: "100%",
                      width: "100%",
                      borderRadius: 3,
                    }}
                  >
                    <CardActionArea
                      sx={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        component={"div"}
                        color={"text.muted"}
                      >
                        No hay manuales disponibles.
                      </Typography>
                    </CardActionArea>
                  </Card>
                </Grid>
              ) : (
                <>
                  {data
                    .filter((i) => i.type === "Video")
                    .map((item, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        height={100}
                        p={0.5}
                      >
                        <Tooltip title={item.name}>
                          <Card
                            sx={{
                              height: "100%",
                              width: "100%",
                              borderRadius: 3,
                              boxShadow: 0,
                              bgcolor: (theme) =>
                                `${theme.palette.background.secondary}55`,
                            }}
                            onClick={() => {
                              window.open(item.link, "_blank");
                            }}
                          >
                            <CardActionArea
                              sx={{ height: "100%", width: "100%" }}
                            >
                              <Box
                                width={"100%"}
                                height={"100%"}
                                display={"flex"}
                                flexDirection={"row"}
                                alignItems={"center"}
                                justifyContent={"flex-start"}
                                gap={1.5}
                                pl={1.5}
                                pr={1}
                              >
                                <IconButton
                                  color="primary"
                                  sx={{
                                    background: (theme) =>
                                      theme.palette.gradient.main,
                                  }}
                                >
                                  <MovieIcon
                                    sx={{
                                      color: (theme) =>
                                        theme.palette.common.white,
                                    }}
                                  />
                                </IconButton>
                                <Box
                                  display={"flex"}
                                  flexDirection={"column"}
                                  alignItems={"flex-start"}
                                  justifyContent={"center"}
                                  gap={0.07}
                                >
                                  <Typography
                                    variant="caption"
                                    component="div"
                                    color={"text.muted"}
                                    className="text-limit-1line"
                                    fontSize={10}
                                  >
                                    {item.type}
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    component="div"
                                    className="text-limit-1line"
                                    fontSize={14}
                                  >
                                    {item.name}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    component="div"
                                    className="text-limit-1line"
                                    fontSize={12}
                                  >
                                    {item.detail}
                                  </Typography>
                                </Box>
                              </Box>
                            </CardActionArea>
                          </Card>
                        </Tooltip>
                      </Grid>
                    ))}
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </TabPanel>
  </Box>
);

export { OldStyleDialogContent, NewStyleDialogContent };
