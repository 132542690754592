import { Box, Button, Card } from '@mui/material'
import { Formik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { authSelectorGetData, authSliceLogoutThunk, authSliceUpdateUserInfoThunk } from '../../redux/slices/authSlice'
import { editUserSchema } from '../../utils/schemas'
import { InputStyled } from '../ui/InputStyled'

export const ProfileUserForm = () => {

    const history = useHistory()

    const dispatch = useDispatch()
    const {
        user
    } = useSelector(authSelectorGetData)

    const handleOnSubmit = (
        values,
        {
            setSubmitting,
            resetForm,
            setStatus,
            setErrors
        }
    ) => {

        setSubmitting(true)
        dispatch(authSliceUpdateUserInfoThunk(values))
            .unwrap()
            .catch((err) => {
                if(
                    err?.code === 401 
                ) {
                    setTimeout(() => {
                        dispatch(authSliceLogoutThunk())
                            .finally(() => {
                                history.replace('/')
                            })
                    }, 3000);
                }
            })
            .finally(() => {
                setSubmitting(false)
            })

    }

  return (
    <Formik
        initialValues={{
            name: user?.first_name||"",
            lastName: user?.last_name||"",
            phone: user?.phone_number||"",
        }}
        onSubmit={handleOnSubmit}
        validationSchema={editUserSchema}
    >
        {
            ({
                isSubmitting,
                handleSubmit,
            }) => (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 2,
                    }}
                    component={"form"}
                    onSubmit={handleSubmit}
                >
                    <InputStyled
                        fullWidth
                        disabled={isSubmitting}
                        name='name'
                        placeholder=""
                        label="Nombre(s)"
                        type='text'
                        inputProps={{
                            autoComplete: 'off',
                            form: {
                              autoComplete: 'off',
                            },
                          }}
                    />
                    <InputStyled
                        fullWidth
                        disabled={isSubmitting}
                        name='lastName'
                        placeholder=""
                        label="Apellido(s)"
                        type='text'
                        inputProps={{
                            autoComplete: 'off',
                            form: {
                              autoComplete: 'off',
                            },
                          }}
                    />
                    <InputStyled
                        disabled={isSubmitting}
                        name='phone'
                        placeholder=""
                        label="Teléfono"
                        type='text'
                        fullWidth
                        inputProps={{
                            autoComplete: 'off',
                            form: {
                              autoComplete: 'off',
                            },
                          }}
                    />
                    <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        color='primary'
                        sx={{
                            // background: (theme) => theme.palette.gradient.main
                        }}
                        onClick={handleSubmit}
                    >
                        Guardar nuevos datos
                    </Button>
                </Box>
            )
        }
    </Formik>
  )
}
