import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  linkCompaniesApiPostDataDigital,
  linkCompaniesApiPostDataRutKey,
} from "../api/linkCompaniesApi";

const initialState = {
  data: null,
  messageLinkPostDataRutKey: {
    active: false,
    message: null,
    type: null,
  },
  messageLinkPostDataDigital: {
    active: false,
    message: null,
    type: null,
  },
};

export const linkCompaniesSlicePostDataRutKey = createAsyncThunk(
  "linkCompanies/postDataRutKey",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { fapro } = await linkCompaniesApiPostDataRutKey(payload);

      if (fapro.success) {
        return fapro.data;
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const linkCompaniesSlicePostDataDigital = createAsyncThunk(
  "linkCompanies/postDataDigital",
  async (payload, { rejectWithValue, signal }) => {
    try {
      const { fapro } = await linkCompaniesApiPostDataDigital(payload);

      if (fapro.success) {
        return fapro.data;
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const linkCompaniesSlice = createSlice({
  name: "linkCompaniesSlice",
  initialState,
  reducers: {
    linkCompaniesSliceClearMessageLinkPostRutKey: (state, action) => {
      state.messageLinkPostDataRutKey = {
        active: false,
        message: null,
        type: null,
      };
    },
    linkCompaniesSliceClearMessageLinkPostDigital: (state, action) => {
      state.messageLinkPostDataDigital = {
        active: false,
        message: null,
        type: null,
      };
    },
  },
  extraReducers: {
    [linkCompaniesSlicePostDataRutKey.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.messageLinkPostDataRutKey = {
        active: true,
        message: "¡Se ha registrado la empresa correctamente!",
        type: "success",
      };
    },
    [linkCompaniesSlicePostDataRutKey.rejected]: (state, action) => {
      state.data = null;
      if (action.payload?.code === 401) {
        state.messageLinkPostDataRutKey = {
          active: true,
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
          type: "warning",
        };
      } else {
        state.messageLinkPostDataRutKey = {
          active: true,
          message: action.payload.message,
          type: "error",
        };
      }
    },
    [linkCompaniesSlicePostDataRutKey.pending]: (state, action) => {
      state.data = null;
      state.messageLinkPostDataRutKey = {
        active: false,
        message: null,
        type: null,
      };
    },

    [linkCompaniesSlicePostDataDigital.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.messageLinkPostDataDigital = {
        active: true,
        message: "¡Se ha registrado la empresa correctamente!",
        type: "success",
      };
    },
    [linkCompaniesSlicePostDataDigital.rejected]: (state, action) => {
      state.data = null;
      if (action.payload?.code === 401) {
        state.messageLinkPostDataDigital = {
          active: true,
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
          type: "warning",
        };
      } else {
        state.messageLinkPostDataDigital = {
          active: true,
          message: action.payload.message,
          type: "error",
        };
      }
    },
    [linkCompaniesSlicePostDataDigital.pending]: (state, action) => {
      state.data = null;
      state.messageLinkPostDataDigital = {
        active: false,
        message: null,
        type: null,
      };
    },
  },
});

export const linkCompaniesSelectorGetMessageRutKey = createSelector(
  ({ linkCompaniesSlice }) => linkCompaniesSlice.messageLinkPostDataRutKey,
  (data) => data
);

export const linkCompaniesSelectorGetMessageDigital = createSelector(
  ({ linkCompaniesSlice }) => linkCompaniesSlice.messageLinkPostDataDigital,
  (data) => data
);

export const {
  linkCompaniesSliceClearMessageLinkPostRutKey,
  linkCompaniesSliceClearMessageLinkPostDigital,
} = linkCompaniesSlice.actions;
