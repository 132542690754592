import axios from "axios"

const apiAuthUrl = process.env.REACT_APP_API_AUTH_URL || "https://api.fapro.app"

export const accountApiGetPermissions = async ({
    signal
}) => {

    try {
        
        const res = await axios(
            {
                method:'get', 
                url:`${apiAuthUrl}/api/permission`, 
                headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`},
                signal
            }
        )

        const data = await res.data

        if (data.code === 200) {
            return {
                fapro: {
                    success: true,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: data.data
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: null
                }
            }
        }

    } catch (err) {
        if (err.response) {
            return {
                fapro: {
                    success: false,
                    code: err.response.data.code,
                    message: err.response.data.message,
                    status: err.response.data.status,
                    data: null
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: 500,
                    message: err.message || 'Opps! Algo salió mal, intente más tarde.',
                    status: 'error',
                    data: null
                }
            }
        }
    }
}




export const accountApiAccountCreate = async ({
    name,
    email,
    password,
    lastName,
    phone_number,
    permissions,
    branch,
    group,
    executive
}) => {

    try {
        
        const res = await axios(
            {
                method:'post', 
                url:`${apiAuthUrl}/api/admin/user`, 
                data: {
                    name,
                    email,
                    password,
                    lastname:lastName,
                    phone_number,
                    repeat_password:password
                },
                headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}
            }
        )

        const data = await res.data

        if (data.code === 201) {


            const [
                resPermissions,
                resFilters
            ] = await Promise.all([
                axios({
                    method:'put',
                    url:`${apiAuthUrl}/api/v1.0/admin/user/${data.data.id}/permission`,
                    data: {
                        permissions
                    },
                    headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}
                }),
                axios({
                    method:'put',
                    url:`${apiAuthUrl}/api/v1.0/admin/user/${data.data.id}/filters`,
                    data: {
                        branch,
                        group,
                        executive,
                    },
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`
                    }
                })
            ])

            const [
                dataPermissions,
                dataFilters
            ] = await Promise.all([
                resPermissions.data,
                resFilters.data
            ])

            if(dataPermissions.code === 200 && dataFilters.code === 200) {
                return {
                    fapro: {
                        success: true,
                        code: 201,
                        message: "Usuario creado correctamente",
                        status: "success",
                        data: data.data
                    }
                }
            } else {

                if(dataPermissions.code !== 200) {
                    return {
                        fapro: {
                            success: false,
                            code: dataPermissions.code,
                            message: dataPermissions.message,
                            status: dataPermissions.status,
                            data: null
                        }
                    }
                } else if (dataFilters.code !== 200) {
                    return {
                        fapro: {
                            success: false,
                            code: dataFilters.code,
                            message: dataFilters.message,
                            status: dataFilters.status,
                            data: null
                        }
                    }
                }
            }
           
        } else {
            return {
                fapro: {
                    success: false,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: null
                }
            }
        }

    } catch (err) {
        if (err.response) {
            return {
                fapro: {
                    success: false,
                    code: err.response.data.code,
                    message: err.response.data.message,
                    status: err.response.data.status,
                    data: null
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: 500,
                    message: err.message || 'Opps! Algo salió mal, intente más tarde.',
                    status: 'error',
                    data: null
                }
            }
        }
    }
}

export const accountApiGetUsers = async ({
    signal
}) => {

    try {
        
        const res = await axios(
            {
                method:'get', 
                url:`${apiAuthUrl}/api/admin/user`, 
                headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`},
                signal
            }
        )

        const data = await res.data

        if (data.code === 200) {
            return {
                fapro: {
                    success: true,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: data.data
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: null
                }
            }
        }

    } catch (err) {
        if (err.response) {
            return {
                fapro: {
                    success: false,
                    code: err.response.data.code,
                    message: err.response.data.message,
                    status: err.response.data.status,
                    data: null
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: 500,
                    message: err.message || 'Opps! Algo salió mal, intente más tarde.',
                    status: 'error',
                    data: null
                }
            }
        }
    }
}

export const accountApiAccountUpdate = async ({
    name,
    phone_number,
    id,
    lastName: lastname,
    permissions,
    branch,
    group,
    executive,
}) => {

    try {
        
        const res = await axios(
            {
                method:'put', 
                url: `${apiAuthUrl}/api/admin/user/${id}`, 
                data: {
                    name,
                    lastname,
                    phone_number,
                },
                headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}
            }
        )

        const data = await res.data

        if (data.code === 200) {

            const [
                resPermissions,
                resFilters
            ] = await Promise.all([
                axios({
                    method:'put',
                    url:`${apiAuthUrl}/api/v1.0/admin/user/${data.data.id}/permission`,
                    data: {
                        permissions
                    },
                    headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}
                }),
                axios({
                    method:'put',
                    url:`${apiAuthUrl}/api/v1.0/admin/user/${data.data.id}/filters`,
                    data: {
                        branch,
                        group,
                        executive,
                    },
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`
                    }
                })
            ])

            const [
                dataPermissions,
                dataFilters
            ] = await Promise.all([
                resPermissions.data,
                resFilters.data
            ])

            if(dataPermissions.code === 200 && dataFilters.code === 200) {
                return {
                    fapro: {
                        success: true,
                        code: 200,
                        message: data.message,
                        status: "success",
                        data: data.data
                    }
                }
            } else {

                if(dataPermissions.code !== 200) {
                    return {
                        fapro: {
                            success: false,
                            code: dataPermissions.code,
                            message: dataPermissions.message,
                            status: dataPermissions.status,
                            data: null
                        }
                    }
                } else if (dataFilters.code !== 200) {
                    return {
                        fapro: {
                            success: false,
                            code: dataFilters.code,
                            message: dataFilters.message,
                            status: dataFilters.status,
                            data: null
                        }
                    }
                }
            }
           
        } else {
            return {
                fapro: {
                    success: false,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: null
                }
            }
        }

    } catch (err) {
        if (err.response) {
            return {
                fapro: {
                    success: false,
                    code: err.response.data.code,
                    message: err.response.data.message,
                    status: err.response.data.status,
                    data: null
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: 500,
                    message: err.message || 'Opps! Algo salió mal, intente más tarde.',
                    status: 'error',
                    data: null
                }
            }
        }
    }
}

export const accountApiAccountDelete = async ({
    id
}) => {

    try {
        
        const res = await axios(
            {
                method:'delete', 
                url: `${apiAuthUrl}/api/admin/user/${id}`, 
                headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}
            }
        )

        const data = await res.data

        if (data.code === 200) {
            return {
                fapro: {
                    success: true,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: id
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: null
                }
            }
        }

    } catch (err) {
        if (err.response) {
            return {
                fapro: {
                    success: false,
                    code: err.response.data.code,
                    message: err.response.data.message,
                    status: err.response.data.status,
                    data: null
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: 500,
                    message: err.message || 'Opps! Algo salió mal, intente más tarde.',
                    status: 'error',
                    data: null
                }
            }
        }
    }
}

export const accountApiAccountGetDataById = async ({
    id
}) => {

    try {
        
        const res = await axios(
            {
                method:'get', 
                url: `${apiAuthUrl}/api/admin/user/${id}`, 
                headers: {'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`}
            }
        )

        const data = await res.data

        if (data.code === 200) {
            return {
                fapro: {
                    success: true,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: data.data
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: data.code,
                    message: data.message,
                    status: data.status,
                    data: null
                }
            }
        }

    } catch (err) {
        if (err.response) {
            return {
                fapro: {
                    success: false,
                    code: err.response.data.code,
                    message: err.response.data.message,
                    status: err.response.data.status,
                    data: null
                }
            }
        } else {
            return {
                fapro: {
                    success: false,
                    code: 500,
                    message: err.message || 'Opps! Algo salió mal, intente más tarde.',
                    status: 'error',
                    data: null
                }
            }
        }
    }
}

