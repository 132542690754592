import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  authApiForgotPassword,
  authApiLogin,
  authApiNewPasswordUSer,
  authApiRenewToken,
  authApiUpdateUserInfo,
  authApiUpdateUserPassword,
  authApiLogout,
} from "../api/authApi";
import { accountSliceInitialData } from "./accountSlice";
import { uiSliceStartLoading, uiSliceStopLoading } from "./uiSlice";

const initialState = {
  data: null,
  error: null,
  messageForgotPassword: {
    active: false,
    message: null,
    type: null,
  },
  messageErrorUser: null,
  features: [],
};

export const authSliceLoginThunk = createAsyncThunk(
  "auth/login",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { email, password } = payload;
      const { fapro } = await authApiLogin(email, password);

      if (fapro.success) {
        localStorage.setItem("fapro_token", fapro.data.accessToken);

        dispatch(setFeatures(fapro.data.user.features));
        return fapro.data;
      } else {
        return rejectWithValue(fapro.message);
      }
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const authSliceLogoutThunk = createAsyncThunk(
  "auth/logout",
  async (payload, { dispatch }) => {
    dispatch(authSliceInitialData());
    dispatch(accountSliceInitialData());
    const token = localStorage.getItem("fapro_token");
    await authApiLogout(token);
    localStorage.removeItem("fapro_token");
    window.location.reload();
    return null;
  }
);

export const authSliceRenewTokenThunk = createAsyncThunk(
  "auth/renewToken",
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(uiSliceStartLoading());
    try {
      const token = localStorage.getItem("fapro_token");

      if (!token) {
        dispatch(uiSliceStopLoading());
        return rejectWithValue(null);
      }

      const { fapro } = await authApiRenewToken(token);

      dispatch(uiSliceStopLoading());

      if (fapro.success) {
        dispatch(setFeatures(fapro.data.features));
        return {
          user: fapro.data,
          accessToken: token,
        };
      } else {
        localStorage.removeItem("fapro_token");
        return rejectWithValue(null);
      }
    } catch (err) {
      dispatch(uiSliceStopLoading());
      localStorage.removeItem("fapro_token");
      return rejectWithValue(null);
    }
  }
);

export const authSliceForgotPasswordThunk = createAsyncThunk(
  "auth/forgotPassword",
  async (payload, { rejectWithValue }) => {
    try {
      const { email } = payload;

      const { fapro } = await authApiForgotPassword(email);

      if (fapro.success) {
        return fapro.message;
      } else {
        return rejectWithValue(fapro.message);
      }
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const authSliceUpdateUserInfoThunk = createAsyncThunk(
  "auth/updateUserInfo",
  async (payload, { rejectWithValue }) => {
    try {
      const { fapro } = await authApiUpdateUserInfo(payload);

      if (fapro.success) {
        return fapro.data;
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const authSliceUpdatePasswordUserThunk = createAsyncThunk(
  "auth/updateUserPassword",
  async (payload, { rejectWithValue }) => {
    try {
      const { fapro } = await authApiUpdateUserPassword(payload);

      if (fapro.success) {
        return fapro.message;
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const authSliceNewPasswordUSerThunk = createAsyncThunk(
  "auth/new-password",
  async (payload, { rejectWithValue }) => {
    try {
      const { fapro } = await authApiNewPasswordUSer(payload);

      if (fapro.success) {
        return fapro.message;
      } else {
        return rejectWithValue(fapro.message);
      }
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    authSliceClearMessageForgotPassword: (state, action) => {
      state.messageForgotPassword = {
        active: false,
        message: null,
        type: null,
      };
    },
    authSliceClearMessageUserError: (state, action) => {
      state.messageErrorUser = null;
    },
    authSliceClearError: (state, action) => {
      state.error = null;
    },
    authSliceInitialData: (state, action) => {
      state.data = null;
    },
    updateFeatures: (state, action) => {
      state.data.user.features = action.payload.features;
    },
    setFeatures: (state, action) => {
        state.features = action.payload;
    },
  },
  extraReducers: {
    [authSliceLoginThunk.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
    [authSliceLoginThunk.rejected]: (state, action) => {
      state.error = {
        type: "error",
        message: action.payload,
      };
    },
    [authSliceLoginThunk.pending]: (state, action) => {
      state.error = null;
    },

    [authSliceLogoutThunk.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
    [authSliceForgotPasswordThunk.rejected]: (state, action) => {
      state.messageForgotPassword = {
        active: true,
        message: action.payload,
        type: "error",
      };
    },
    [authSliceForgotPasswordThunk.fulfilled]: (state, action) => {
      state.messageForgotPassword = {
        active: true,
        message: action.payload,
        type: "success",
      };
    },
    [authSliceForgotPasswordThunk.pending]: (state, action) => {
      state.messageForgotPassword = {
        active: false,
        message: null,
        type: null,
      };
    },
    [authSliceRenewTokenThunk.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
    [authSliceRenewTokenThunk.rejected]: (state, action) => {
      state.data = null;
    },
    [authSliceRenewTokenThunk.pending]: (state, action) => {
      state.data = null;
    },
    [authSliceUpdateUserInfoThunk.fulfilled]: (state, action) => {
      state.data.user = {
        ...state.data.user,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        phone_number: action.payload.phone_number,
      };
      state.messageErrorUser = {
        type: "success",
        message: "Información actualizada correctamente",
      };
    },
    [authSliceUpdateUserInfoThunk.rejected]: (state, action) => {
      if (action.payload?.code === 401) {
        state.messageErrorUser = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.messageErrorUser = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [authSliceUpdateUserInfoThunk.pending]: (state, action) => {
      state.messageErrorUser = null;
    },
    [authSliceUpdatePasswordUserThunk.fulfilled]: (state, action) => {
      state.messageErrorUser = {
        type: "success",
        message: action.payload,
      };
    },
    [authSliceUpdatePasswordUserThunk.rejected]: (state, action) => {
      if (action.payload?.code === 401) {
        state.messageErrorUser = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.messageErrorUser = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [authSliceUpdatePasswordUserThunk.pending]: (state, action) => {
      state.messageErrorUser = null;
    },

    [authSliceNewPasswordUSerThunk.fulfilled]: (state, action) => {
      state.error = {
        type: "success",
        message: "Contraseña actualizada correctamente",
      };
    },
    [authSliceNewPasswordUSerThunk.rejected]: (state, action) => {
      state.error = {
        type: "error",
        message: action.payload,
      };
    },
    [authSliceNewPasswordUSerThunk.pending]: (state, action) => {
      state.error = null;
    },
  },
});

export const authSelectorGetData = createSelector(
  ({ authSlice }) => authSlice.data,
  (data) => data
);

export const authSelectorGetError = createSelector(
  ({ authSlice }) => authSlice.error,
  (data) => data
);

export const authSelectorGetMessageForgotPassword = createSelector(
  ({ authSlice }) => authSlice.messageForgotPassword,
  (data) => data
);

export const authSelectorGetMessageErrorUser = createSelector(
  ({ authSlice }) => authSlice.messageErrorUser,
  (data) => data
);

export const { updateFeatures } = authSlice.actions;

export const authSelectorGetFeatures = createSelector(
    (state) => state.authSlice.features,
    (features) => features
);

export const {
  authSliceClearMessageForgotPassword,
  authSliceClearMessageUserError,
  authSliceClearError,
  authSliceInitialData,
  setFeatures
} = authSlice.actions;
