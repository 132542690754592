import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  emailApiGetData,
  emailApiGetDataEmailMailchimp,
  emailApiNewEmail,
  emailApiRemoveEmail,
  emailApiRemoveEmailMailchimp,
  emailApiRemoveEmailMassiveMailchimp,
  emailApiUpdateEmail,
  emailApiUpdateEmailMailchimp,
} from "../api/emailApi";

export const emailAdapter = createEntityAdapter({
  selectId: (item) => item.id,
  sortComparer: (a, b) => new Date(a.created_at) - new Date(b.created_at),
});

const emailSelectors = emailAdapter.getSelectors(
  ({ emailSlice }) => emailSlice.data
);
const {
  selectAll: selectAllEmails,
} = emailSelectors;

export const emailMailchimpAdapter = createEntityAdapter({
  selectId: (item) => item.subscriber_id,
  sortComparer: (a, b) => a.email.localeCompare(b.email),
});

const emailMailchimpSelectors = emailAdapter.getSelectors(
  ({ emailSlice }) => emailSlice.dataMailchimp
);
const {
  selectAll: selectAllEmailsMailchimp,
} = emailMailchimpSelectors;

export const emailSliceGetDataThunk = createAsyncThunk(
  "email/getData",
  async (_, { rejectWithValue, signal }) => {
    try {
      const { fapro } = await emailApiGetData({
        signal,
      });

      if (fapro.success) {
        return fapro.data;
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const emailSliceUpdateDataThunk = createAsyncThunk(
  "email/updateData",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { fapro } = await emailApiUpdateEmail(payload);

      if (fapro.success) {
        return fapro.data;
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const emailSliceRemoveDataThunk = createAsyncThunk(
  "email/removeData",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { fapro } = await emailApiRemoveEmail(payload);

      if (fapro.success) {
        return fapro.data;
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const emailSliceNewDataThunk = createAsyncThunk(
  "email/newData",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { fapro } = await emailApiNewEmail(payload);

      if (fapro.success) {
        return fapro.data;
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const emailSliceGetDataEmailMailchimpThunk = createAsyncThunk(
  "email/getDataEmailMailchimp",
  async (_, { rejectWithValue, signal }) => {
    try {
      const { fapro } = await emailApiGetDataEmailMailchimp({
        signal,
      });

      if (fapro.success) {
        return fapro.data.map((val) => ({
          ...val,
          subscriber_id: val.email,
        }));
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const emailSliceUpdateDataEmailMailchimpThunk = createAsyncThunk(
  "email/updateDataEmailMailchimp",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { fapro } = await emailApiUpdateEmailMailchimp(payload);

      if (fapro.success) {
        return fapro.data;
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const emailSliceRemoveDataEmailMailchimpThunk = createAsyncThunk(
  "email/removeDataEmailMailchimp",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { fapro } = await emailApiRemoveEmailMailchimp(payload);

      if (fapro.success) {
        return fapro.data;
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const emailSliceRemoveDataEmailMassiveMailchimpThunk = createAsyncThunk(
  "email/removeDataEmailMassiveMailchimp",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { fapro } = await emailApiRemoveEmailMassiveMailchimp(payload);

      if (fapro.success) {
        return fapro.data;
      } else {
        return rejectWithValue(fapro);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const emailSlice = createSlice({
  name: "emailSlice",
  initialState: {
    data: emailAdapter.getInitialState(),
    status: "idle",
    messageError: null,
    selectedRowsMailchimp: [],
    dataMailchimp: emailMailchimpAdapter.getInitialState(),
  },
  reducers: {
    emailSliceClearMessageError: (state, action) => {
      state.messageError = null;
    },
    emailSliceSetRowSelectedMailchimp: (state, action) => {
      state.selectedRowsMailchimp = action.payload;
    },
    emailSliceResetRowSelectedMailchimp: (state, action) => {
      state.selectedRowsMailchimp = [];
    },
  },
  extraReducers: {
    [emailSliceGetDataThunk.fulfilled]: (state, action) => {
      state.status = "success";
      emailAdapter.setAll(state.data, action.payload);
    },
    [emailSliceGetDataThunk.rejected]: (state, action) => {
      state.status = "error";
      if (action.payload?.code === 401) {
        state.messageError = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.messageError = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [emailSliceGetDataThunk.pending]: (state, action) => {
      emailAdapter.removeAll(state.data);
      state.status = "loading";
      state.messageError = null;
    },

    [emailSliceUpdateDataThunk.fulfilled]: (state, action) => {
      emailAdapter.upsertOne(state.data, action.payload);
      state.messageError = {
        type: "success",
        message: "Email actualizado correctamente",
      };
    },
    [emailSliceUpdateDataThunk.rejected]: (state, action) => {
      if (action.payload?.code === 401) {
        state.messageError = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.messageError = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [emailSliceUpdateDataThunk.pending]: (state, action) => {
      state.messageError = null;
    },

    [emailSliceRemoveDataThunk.fulfilled]: (state, action) => {
      emailAdapter.removeOne(state.data, action.payload);
      state.messageError = {
        type: "success",
        message: "Email eliminado correctamente",
      };
    },
    [emailSliceRemoveDataThunk.rejected]: (state, action) => {
      if (action.payload?.code === 401) {
        state.messageError = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.messageError = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [emailSliceRemoveDataThunk.pending]: (state, action) => {
      state.messageError = null;
    },

    [emailSliceNewDataThunk.fulfilled]: (state, action) => {
      emailAdapter.addOne(state.data, action.payload);
      state.messageError = {
        type: "success",
        message: "Email agregado correctamente",
      };
    },
    [emailSliceNewDataThunk.rejected]: (state, action) => {
      if (action.payload?.code === 401) {
        state.messageError = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.messageError = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [emailSliceNewDataThunk.pending]: (state, action) => {
      state.messageError = null;
    },

    [emailSliceGetDataEmailMailchimpThunk.fulfilled]: (state, action) => {
      state.status = "success";
      emailMailchimpAdapter.setAll(state.dataMailchimp, action.payload);
    },
    [emailSliceGetDataEmailMailchimpThunk.rejected]: (state, action) => {
      state.status = "error";
      if (action.payload?.code === 401) {
        state.messageError = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.messageError = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [emailSliceGetDataEmailMailchimpThunk.pending]: (state, action) => {
      emailMailchimpAdapter.removeAll(state.dataMailchimp);
      state.status = "loading";
      state.messageError = null;
    },

    [emailSliceUpdateDataEmailMailchimpThunk.fulfilled]: (state, action) => {
      emailMailchimpAdapter.upsertOne(state.dataMailchimp, action.payload);
      state.messageError = {
        type: "success",
        message: "Email actualizado correctamente",
      };
    },
    [emailSliceUpdateDataEmailMailchimpThunk.rejected]: (state, action) => {
      if (action.payload?.code === 401) {
        state.messageError = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.messageError = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [emailSliceUpdateDataEmailMailchimpThunk.pending]: (state, action) => {
      state.messageError = null;
    },

    [emailSliceRemoveDataEmailMailchimpThunk.fulfilled]: (state, action) => {
      emailMailchimpAdapter.removeOne(state.dataMailchimp, action.payload);
      state.messageError = {
        type: "success",
        message: "Correo eliminado correctamente",
      };
    },
    [emailSliceRemoveDataEmailMailchimpThunk.rejected]: (state, action) => {
      if (action.payload?.code === 401) {
        state.messageError = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.messageError = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [emailSliceRemoveDataEmailMailchimpThunk.pending]: (state, action) => {
      state.messageError = null;
    },

    [emailSliceRemoveDataEmailMassiveMailchimpThunk.fulfilled]: (
      state,
      action
    ) => {
      emailMailchimpAdapter.removeMany(
        state.dataMailchimp,
        action.payload.map((prev) => prev.subscriber_id)
      );
      state.messageError = {
        type: "success",
        message: "Correos eliminados correctamente",
      };
    },
    [emailSliceRemoveDataEmailMassiveMailchimpThunk.rejected]: (
      state,
      action
    ) => {
      if (action.payload?.code === 401) {
        state.messageError = {
          type: "warning",
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
        };
      } else {
        state.messageError = {
          type: "error",
          message: action.payload.message,
        };
      }
    },
    [emailSliceRemoveDataEmailMassiveMailchimpThunk.pending]: (
      state,
      action
    ) => {
      state.messageError = null;
    },
  },
});

export const emailSelectorGetData = createSelector(
  selectAllEmails,
  (data) => data
);

export const emailSelectorGetDataStatus = createSelector(
  ({ emailSlice }) => emailSlice.status,
  (data) => data
);

export const emailSelectorGetMessageError = createSelector(
  ({ emailSlice }) => emailSlice.messageError,
  (data) => data
);

export const emailSelectorGetRowSelectedMailchimp = createSelector(
  ({ emailSlice }) => emailSlice.selectedRowsMailchimp,
  (data) => data
);

export const emailSelectorGetDataMailchimp = createSelector(
  selectAllEmailsMailchimp,
  (data) =>
    data.map((item) => ({
      ...item,
      id: item.subscriber_id,
    }))
);

export const {
  emailSliceClearMessageError,
  emailSliceSetRowSelectedMailchimp,
  emailSliceResetRowSelectedMailchimp,
} = emailSlice.actions;
